import { Modal, PreLoader } from '@sydney-broker-ui/ios';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getCMSStaticContent, getCMSdynamicContent } from '../../../api/cms/cmsUtil';
import { AEM_PATHS, AGENT_ROLES, LANGUAGE_CODES, PAGE_BLOCKS, PAGE_IDS, SCREEN_NAMES } from '../../../shared/globalConstants';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import Content from './GlobalFooter.json';
import './GlobalFooter.scss';
import { CHINESE, KOREAN, LANGUAGE_NAME, SPANISH, TAGALOG, VIETNAMESE } from './GlobalFooterConstants';
import { getlink } from './GlobalFooterUtils';
import axios from 'axios';
const {
  REACT_APP_FOOTER,
  REACT_APP_AEM_URL,
  REACT_APP_ENV
} = process.env;

function GlobalFooter() {
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>('');
  const [popupContent, setPopupContent] = useState<LanguageContent>();
  const [showLanPop, setShowLanPop] = useState<boolean>(false);
  const [pageContent, setContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [lanPopupContent, setLanPopupContent] = useState<LanguagePopup>();
  const [links, setLinks] = useState<any>(null);
  const [footerContent, setFooterContent] = useState<any>(null);


  const { zipcode, zipCodeRes, validState, county, agentZipCodeRes, selectedYear, agentSSOInfo, agentBrand, agentYear } = useGlobalStore((store) => store);
  const { isAtk } = agentSSOInfo;
  const { isWellpoint, role } = useUserStore((store) => store);
  const location = useLocation();
  const brand = useGlobalStore.getState().brand;

  let ZipcodeData;
  const countyData = zipCodeRes?.countyList.county;
  if (county) {
    const countyValue = countyData.find((val) => val.countyName === county);
    ZipcodeData = countyValue ? countyValue : countyData[0];

  } else {
    ZipcodeData = countyData[0];
  }
  const getContent = useCallback(async (): Promise<boolean> => {
    let linksContent;
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.GLOBAL_FOOTER);
      let cmsResponse = response.data;
      setContent(cmsResponse.data.iospagecontentList.items[0].pageContent.globalfooter);
      linksContent = cmsResponse.data.iospagecontentList.items[0].pageContent.globalfooter?.FOOTER_APPLICATION_LINKS;
    } catch (error) {
      setContent(Content.data.iospagecontentList.items[0].pageContent.globalfooter);
      linksContent = Content.data.iospagecontentList.items[0].pageContent.globalfooter?.FOOTER_APPLICATION_LINKS;
      return false;
    } finally {
      setLinks(getlink(language, linksContent));
      setContentLoaded(true);
    }
    return true;
  }, [zipCodeRes.stateCode]);

  const initiate = async () => {
    await getContent();
  };

  useEffect(() => {
    initiate();
  }, [validState, agentZipCodeRes.stateCode]);

  const getFooterContent = async (brandInfo: string, stateCode: string, roleInfo: string, selectedYearInfo: any) => {
    const locale = window.location.href.includes('/es/') ? LANGUAGE_CODES.SPANISH : LANGUAGE_CODES.ENGLISH;
    let pageid = "";
    if (location?.pathname?.includes('login-page')) {
      pageid = PAGE_IDS.GLOBAL_FOOTER_SHOPPER_LOGIN;
    } else if (location?.pathname?.includes('mac-login')) {
      pageid = PAGE_IDS.GLOBAL_FOOTER_MAC_LOGIN;
    } else {
      pageid = PAGE_IDS.GLOBAL_FOOTER_ALL;
    }
    const STATIC_URL = (REACT_APP_ENV !== 'local') ? REACT_APP_AEM_URL + AEM_PATHS.FOOTER_CONTENT_URI : REACT_APP_FOOTER || AEM_PATHS.AUTHOR_FOOTER_CONTENT_URI;
    const url = ';brand=' + brandInfo + ';state=' + stateCode + ';locale=' + locale + ';pageid=' + pageid + ';role=' + roleInfo?.toLowerCase() + ';year=' + selectedYearInfo;
    const encodedURL = STATIC_URL + encodeURIComponent(url);

    await axios.get(encodedURL)
      .then((response) => {
        setFooterContent(response.data?.data.iosfootercontentList?.items[0]);
      })
      .catch((error) => {
        setFooterContent('');
      });

  };

  useEffect(() => {
    if (brand && zipCodeRes.stateCode && selectedYear && !isAtk) {
      getFooterContent(brand, zipCodeRes.stateCode, role, selectedYear);
    } else if (isAtk) {
      getFooterContent(agentBrand, agentZipCodeRes.stateCode, AGENT_ROLES.BROKER.toLowerCase(), agentYear);
    }
  }, [brand, zipCodeRes.zipCode, selectedYear, agentBrand, agentZipCodeRes.stateCode, agentYear])

  useEffect(() => {
    if (showPopup && language != '') {
      switch (language) {
        case CHINESE:
          setPopupContent(pageContent?.chinese);
          break;
        case VIETNAMESE:
          setPopupContent(pageContent?.vietnamese);
          break;
        case KOREAN:
          setPopupContent(pageContent?.korean);
          break;
        case TAGALOG:
          setPopupContent(pageContent?.tagalog);
          break;
        case SPANISH:
          setPopupContent(pageContent?.spanish);
          break;
        default:
        // setPopupContent("");
      }
    }
  }, [showPopup, language]);


  useEffect(() => {
    pageContent?.FOOTER_APPLICATION_LINKS && setLinks(getlink(language, pageContent?.FOOTER_APPLICATION_LINKS));
  }, [selectedYear, showPopup, language])

  const onSelectLanguage = (e: any) => {
    setLanPopupContent(pageContent?.popupcontent?.[e.target.value]);
    if (e.target.value !== '') {
      setShowLanPop(true);
    }
  };
  const footerYear = selectedYear ? selectedYear : pageContent?.DEFAULT_SELECTED_YEAR;

  return (
    <div>
      {!contentLoaded ? (
        <PreLoader id={'login-modal-loader'} />
      ) : (
        <div className="wrapper">
          {isAtk && <div className='atk-additional-footer'>
            <div className='fwc-row atk-footer-container'>
              <a
                href={isWellpoint ? pageContent?.ATK_FOOTER?.TERMS_OF_USE_LINK_WELLPOINT : pageContent?.ATK_FOOTER?.TERMS_OF_USE_LINK_ANTHEM}
                className="right-xs fwc-flex a-center"
                id={'contact-us'}
                target="_blank"
                rel="noopener noreferrer"
              >{pageContent?.ATK_FOOTER?.TERMS_OF_USE}</a>
              <div className='atk-tech-support-container'>
                <p className='technical-support-text bold-label'>{pageContent?.ATK_FOOTER?.FOR_TECHNICAL_SUPPORT}</p>
                <p className='tech-support-key-value'><p className='bold-label'>{pageContent?.ATK_FOOTER?.EMAIL + ' :'}</p> <a href={`mailto:${isWellpoint ? pageContent?.ATK_FOOTER?.EMAIL_VALUE_WELLPOINT : pageContent?.ATK_FOOTER?.EMAIL_VALUE_ANTHEM}`}>
                  {isWellpoint ? pageContent?.ATK_FOOTER?.EMAIL_VALUE_WELLPOINT : pageContent?.ATK_FOOTER?.EMAIL_VALUE_ANTHEM}
                </a></p>
                <p className='tech-support-key-value'><p className='bold-label'>{pageContent?.ATK_FOOTER?.PHONE + ' :'}</p>{isWellpoint ? pageContent?.ATK_FOOTER?.PHONE_NUMBER_WELLPOINT : pageContent?.ATK_FOOTER?.PHONE_PREFIX + ' (' + pageContent?.ATK_FOOTER?.PHONE_NUMBER_ANTHEM + ')'}</p>
              </div>
            </div>
          </div>
          }
          <section className="fwc-container fwc-footer-main">
            <div className="fwc-row">
              <div className="fwc-col-12 md-12 sm-12 fwc-reverse-column-mobile">
                {<div dangerouslySetInnerHTML={{ __html: footerContent?.footercontent?.html }} id="global-footer"></div>}
                {/* {"jsoncontent: " + footerContent?.jsoncontent} */}
              </div>
            </div>
            <div className="fwc-row">
              {ZipcodeData && ZipcodeData.stateCode === 'CA' && !useUserStore.getState().isWellpoint && (
                <div className="language-links">
                  <p>{pageContent?.languages?.LANGUAGE_TITLE}</p>
                  <a
                    href="javascript:void(0)"
                    id={'footer-link-chinese'}
                    onClick={() => {
                      setShowPopup(true);
                      setLanguage('chinese');
                    }}
                  >
                    {pageContent?.languages?.CHINESE}
                  </a>
                  <a
                    href="javascript:void(0)"
                    id={'footer-link-Vietnamese'}
                    onClick={() => {
                      setShowPopup(true);
                      setLanguage('spanish');
                    }}
                  >
                    {pageContent?.languages?.SPANISH}
                  </a>
                  <a
                    href="javascript:void(0)"
                    id={'footer-link-Korean'}
                    onClick={() => {
                      setShowPopup(true);
                      setLanguage('tagalog');
                    }}
                  >
                    {pageContent?.languages?.TAGALOG}
                  </a>
                  <a
                    href="javascript:void(0)"
                    id={'footer-link-Tagalog'}
                    onClick={() => {
                      setShowPopup(true);
                      setLanguage('korean');
                    }}
                  >
                    {pageContent?.languages?.KOREAN}
                  </a>
                  <a
                    href="javascript:void(0)"
                    id={'footer-link-Spanish'}
                    onClick={() => {
                      setShowPopup(true);
                      setLanguage('vietnamese');
                    }}
                  >
                    {pageContent?.languages?.VIETNAMESE}
                  </a>
                </div>
              )}
            </div>
            <div className="fwc-row fwc-row-bm">
              <div className="fwc-col-9 sm-12">
                <div className="fwc-footer-links">
                  <a data-analytics="AboutUs" href={pageContent?.FOOTER_LINK_URL.ABOUT_US} target="_blank" rel="noopener noreferrer" className="footer-link" id={'footer-about-us'}>
                    {pageContent?.FOOTER_LINKS_TEXT?.ABOUT_US}
                  </a>

                  <a data-analytics="Legal" href={pageContent?.FOOTER_LINK_URL.LEGAL} target="_blank" rel="noopener noreferrer" className="footer-link" id={'footer-legal'}>
                    {pageContent?.FOOTER_LINKS_TEXT?.LEGAL}
                  </a>

                  <a data-analytics="Privacy" href={pageContent?.FOOTER_LINK_URL.PRIVACY} target="_blank" rel="noopener noreferrer" className="footer-link" id={'footer-privacy'}>
                    {pageContent?.FOOTER_LINKS_TEXT?.PRIVACY}
                  </a>
                  <a data-analytics="PressRoom" href={pageContent?.FOOTER_LINK_URL.PRESS_ROOM} target="_blank" rel="noopener noreferrer" className="footer-link" id={'footer-press-room'}>
                    {pageContent?.FOOTER_LINKS_TEXT?.PRESS_ROOM}
                  </a>
                  <a data-analytics="Careers" href={pageContent?.FOOTER_LINK_URL.CAREERS} target="_blank" rel="noopener noreferrer" className="footer-link" id={'footer-careers'}>
                    {pageContent?.FOOTER_LINKS_TEXT?.CAREERS}
                  </a>
                </div>
              </div>
              <div className="fwc-col-3 md-12 sm-12 fwc-flex a-center lang-cont">
                <div className="footer-wcag" aria-label="WCAG">
                  <a href="https://www.w3.org/WAI/standards-guidelines/wcag/" target="_blank" className="fwc-link-icon" title="WCAG Standard Guidelines">
                    <span className="fwc-icon fwc-icon-double-tick"></span>
                  </a>
                </div>
                <div className="fwc-col-11 footer-ln-selector fwc-select-list dark">
                  <label htmlFor="ln-selector" className="sr-only">
                    Select a Language{' '}
                  </label>
                  <select
                    name="footer-language-selector"
                    aria-label="Select a Language "
                    id="ln-selector"
                    data-analytics="selectLanguageFooter"
                    onChange={(e) => {
                      onSelectLanguage(e);
                    }}
                  >
                    <option value="">Select a Language</option>

                    <option data-analytics="selLangSpanishFooter" value="SPANISH">
                      {LANGUAGE_NAME.SPANISH}
                    </option>

                    <option data-analytics="selLangChineseFooter" value="CHINESE">
                      {LANGUAGE_NAME.CHINESE}
                    </option>

                    <option data-analytics="selLangKoreanFooter" value="KOREAN">
                      {LANGUAGE_NAME.KOREAN}
                    </option>

                    <option data-analytics="selLangVietnameseFooter" value="VIETNAMESE">
                      {LANGUAGE_NAME.VIETNAMESE}
                    </option>

                    <option data-analytics="selLangTagalongFooter" value="TAGALONG">
                      {LANGUAGE_NAME.TAGALONG}
                    </option>

                    <option data-analytics="selLangAlbanianFooter" value="ALBANIAN">
                      {LANGUAGE_NAME.ALBANIAN}
                    </option>

                    <option data-analytics="selLangArmenianFooter" value="ARMENIAN">
                      {LANGUAGE_NAME.ARMENIAN}
                    </option>

                    <option data-analytics="selLangAmharicFooter" value="AMHARIC">
                      {LANGUAGE_NAME.AMHARIC}
                    </option>

                    <option value="PYCCK">{LANGUAGE_NAME.PYCCK}</option>

                    <option data-analytics="selLangDutchFooter" value="DUTCH">
                      {LANGUAGE_NAME.DUTCH}
                    </option>

                    <option data-analytics="selLangIgboFooter" value="IGBO">
                      {LANGUAGE_NAME.IGBO}
                    </option>

                    <option data-analytics="selLangIndonesianFooter" value="INDONESIA">
                      {LANGUAGE_NAME.INDONESIA}
                    </option>

                    <option data-analytics="selLangKirundiFooter" value="KIRUNDI">
                      {LANGUAGE_NAME.KIRUNDI}
                    </option>

                    <option data-analytics="selLangOromoFooter" value="OROMO">
                      {LANGUAGE_NAME.OROMO}
                    </option>

                    <option data-analytics="selLangPaDutchFooter" value="PADUTCH">
                      {LANGUAGE_NAME.PADUTCH}
                    </option>

                    <option data-analytics="selLangThaiFooter" value="THAI">
                      {LANGUAGE_NAME.THAI}
                    </option>

                    <option data-analytics="selLangUkranianFooter" value="UKRANIAN">
                      {LANGUAGE_NAME.UKRANIAN}
                    </option>

                    <option data-analytics="selLangBassaFooter" value="BASSA">
                      {LANGUAGE_NAME.BASSA}
                    </option>

                    <option data-analytics="selLangBengaliFooter" value="BENGALI">
                      {LANGUAGE_NAME.BENGALI}
                    </option>

                    <option data-analytics="selLangDinkaFooter" value="DINKA">
                      {LANGUAGE_NAME.DINKA}
                    </option>

                    <option data-analytics="selLangFrenchFooter" value="FRENCH">
                      {LANGUAGE_NAME.FRENCH}
                    </option>

                    <option data-analytics="selLangGermanFooter" value="GERMAN">
                      {LANGUAGE_NAME.GERMAN}
                    </option>

                    <option data-analytics="selLangGujaratiFooter" value="GUJARATI">
                      {LANGUAGE_NAME.GUJARATI}
                    </option>

                    <option data-analytics="selLangHaitianFooter" value="HAITIAN">
                      {LANGUAGE_NAME.HAITIAN}
                    </option>

                    <option data-analytics="selLangHindiFooter" value="HINDI">
                      {LANGUAGE_NAME.HINDI}
                    </option>

                    <option data-analytics="selLangHmongFooter" value="HMONG">
                      {LANGUAGE_NAME.HMONG}
                    </option>

                    <option data-analytics="selLangIlokanoFooter" value="ILOKANO">
                      {LANGUAGE_NAME.ILOKANO}
                    </option>

                    <option data-analytics="selLangItalianFooter" value="ITALIAN">
                      {LANGUAGE_NAME.ITALIAN}
                    </option>

                    <option data-analytics="selLangJapaneseFooter" value="JAPANESE">
                      {LANGUAGE_NAME.JAPANESE}
                    </option>

                    <option data-analytics="selLangKhmerFooter" value="KHMER">
                      {LANGUAGE_NAME.KHMER}
                    </option>

                    <option data-analytics="selLangLaoFooter" value="LANGLAO">
                      {LANGUAGE_NAME.LANGLAO}
                    </option>

                    <option data-analytics="selLangNepaliFooter" value="NEPALI">
                      {LANGUAGE_NAME.NEPALI}
                    </option>

                    <option data-analytics="selLangPolishFooter" value="POLISH">
                      {LANGUAGE_NAME.POLISH}
                    </option>
                    <option data-analytics="selLangPortugueseFooter" value="PORTUGUESE">
                      {LANGUAGE_NAME.PORTUGUESE}
                    </option>
                    <option data-analytics="selLangPunjabiFooter" value="PANJABI">
                      {LANGUAGE_NAME.PANJABI}
                    </option>
                    <option data-analytics="selLangRomanianFooter" value="ROMANIAN">
                      {LANGUAGE_NAME.ROMANIAN}
                    </option>
                    <option data-analytics="selLangSamoanFooter" value="SAMOAN">
                      {LANGUAGE_NAME.SAMOAN}
                    </option>
                    <option data-analytics="selLangSerbianFooter" value="SERBIAN">
                      {LANGUAGE_NAME.SERBIAN}
                    </option>
                    <option data-analytics="selLangYourubaFooter" value="YOURUBA">
                      {LANGUAGE_NAME.YOURUBA}
                    </option>
                    <option data-analytics="selLangYiddishFooter" value="YIDDISH">
                      {LANGUAGE_NAME.YIDDISH}
                    </option>
                    <option data-analytics="selLangUrduFooter" value="URDU">
                      {LANGUAGE_NAME.URDU}
                    </option>
                    <option data-analytics="selLangFarsiFooter" value="FARSI">
                      {LANGUAGE_NAME.FARSI}
                    </option>
                    <option data-analytics="selLangArabicFooter" value="ARABIC">
                      {LANGUAGE_NAME.ARABIC}
                    </option>
                    <option data-analytics="selLangGreekFooter" value="GREEK">
                      {LANGUAGE_NAME.GREEK}
                    </option>
                    <option data-analytics="selLangNavajoFooter" value="NAVAJO">
                      {LANGUAGE_NAME.NAVAJO}
                    </option>
                    <option data-analytics="selLangBurmeseFooter" value="BURMESE">
                      {LANGUAGE_NAME.BURMESE}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div className='fwc-row'>
              <div className="fwc-col-12 md-12 sm-12">
                {<div dangerouslySetInnerHTML={{ __html: footerContent?.copyrightcontent?.html }}></div>}
              </div>
            </div>
          </section>
          <div>
            <Modal open={showPopup} onClose={() => setShowPopup(false)} title={popupContent?.TITLE}>
              <Modal.Body className="language-popup-body">
                <div id="popup-model" className="popup-content">
                  <h4>{popupContent?.SUB_TITLE}</h4>
                  <p>{popupContent?.DESCRIPTION}</p>
                  <h4>{popupContent?.BROCHURE_TITLE}</h4>
                  <p>{popupContent?.BROCHURE_DESCRIPTION}</p>
                  <div className="county-container">
                    <div className="county-subtitle">
                      <h6>{popupContent?.MEDICAL_TITLE}</h6>
                      <div className="county-sub-title">
                        <p>{popupContent?.MEDICAL_COUNTY}</p>
                        <p>{popupContent?.MEDICAL_COUNTY_SUB}</p>
                      </div>
                    </div>
                    <div className="pdf-container">
                      <span className="sae-icon sae-icon-pdf pdf-icon"></span>
                      <a title="PDF" href={links?.APPLICATION?.MEDICAL} target="_blank">
                        {popupContent?.MEDICAL_PDF_NAME}
                      </a>
                    </div>
                    <div className="brochure-container">
                      <span className="sae-icon sae-icon-pdf pdf-icon"></span>
                      <a title="PDF" href={links?.BROCHURE?.MEDICAL} target="_blank">
                        {popupContent?.MEDICL_BROCHURE_NAME}
                      </a>
                    </div>
                  </div>
                  <div className="county-container">
                    <div className="county-subtitle">
                      <h6>{popupContent?.DENTAL_TITLE}</h6>
                      <div className="county-sub-title">
                        <p>{popupContent?.DENTAL_COUNTY}</p>
                        <p>{popupContent?.DENTAL_COUNTY_SUB}</p>
                      </div>
                    </div>
                    <div className="pdf-container">
                      <span className="sae-icon sae-icon-pdf pdf-icon"></span>
                      <a title="PDF" href={links?.APPLICATION?.DENTAL} target="_blank">
                        {popupContent?.DENTAL_PDF_NAME}
                      </a>
                    </div>
                    <div className="brochure-container">
                      <span className="sae-icon sae-icon-pdf pdf-icon"></span>
                      <a title="PDF" href={links?.BROCHURE?.DENTAL} target="_blank">
                        {popupContent?.DENTAL_BROCHURE_NAME}
                      </a>
                    </div>
                  </div>
                  <div className="languages-buttons" id="popup-buttons-wrapper">
                    <button
                      className="fwc-btn-primary"
                      onClick={() => {
                        setShowPopup(false);
                        setLanguage('');
                      }}
                    >
                      {popupContent?.BUTTON}
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </Modal>

            <Modal open={showLanPop} onClose={() => setShowLanPop(false)} title={lanPopupContent?.TITLE}>
              <Modal.Body>
                <div className="select-popup-body" id="select-popup-body">
                  <p id="lanpopup-content">{lanPopupContent?.CONTENT}</p>
                </div>
                <div className="languages-buttons" id="lanpopup-buttons-wrapper">
                  <button
                    id="lan-button"
                    className="fwc-btn-primary"
                    onClick={() => {
                      setShowLanPop(false);
                    }}
                  >
                    {lanPopupContent?.BUTTON}
                  </button>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </div>
      )}
    </div>
  );
}
export default GlobalFooter;
