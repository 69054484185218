import { Card, RadioGroup } from '@sydney-broker-ui/ios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCMSStaticContent } from '../../../api/cms/cmsUtil';
import { LANGUAGE_CODES, NAV_PATHS, SCREEN_NAMES } from '../../../shared/globalConstants';
import { useGlobalStore } from '../../../store/globalStore';
import FullPageLoader from '../full-page-loader/FullPageLoader';
import './HelpMeChoose.scss';
import { default as Content } from './helpMeChoose.json';

function HelpMeChoose() {
  const [pageContent, setPageContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [helpMeChoose, setHelpMeChoose] = useState(Content.data.iospagecontentList.items[0].pageContent.HELPMECHOOSE?.QUESTION_SELECTED_ANSWERS);
  const { zipCodeRes, brand } = useGlobalStore((store) => store);

  const getContent = useCallback(async (): Promise<boolean> => {
    let content: any;
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.HELP_ME_CHOOSE);
      let cmsResponse = response.data;
      content = cmsResponse.data.iospagecontentList.items[0].pageContent?.HELPMECHOOSE;
    } catch (error) {
      content = Content.data.iospagecontentList.items[0].pageContent.HELPMECHOOSE;;
      return false;
    } finally {
      setPageContent(content);
      setContentLoaded(true);
      setHelpMeChoose(content?.QUESTION_SELECTED_ANSWERS);
    }
    return true;
  }, [zipCodeRes.stateCode]);

  const initiate = async () => {
    await getContent();
  };
  useEffect(() => {
    initiate();
  }, []);
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState<boolean>(false);
  const { updateHmcFilter, resetMedicalPlanStatesOtherThanTab, resetHmcFilter, resetMedicalPlanStates } = useGlobalStore((store) => store);
  const onRadioChange = (e) => {
    const { name, value } = e.target;
    const newHelpMeChoose = helpMeChoose.map((question) => (question.code === name ? { code: question.code, value: value } : question));
    setHelpMeChoose([...newHelpMeChoose]);
    updateHmcFilter({ questionaires: newHelpMeChoose });
  };

  const onViewMyPlanClick = (e) => {
    const isValueEmpty = helpMeChoose.some((question) => !question.value);
    setErrorMessage(isValueEmpty);
    if (!isValueEmpty) {
      resetMedicalPlanStatesOtherThanTab()
      navigate(NAV_PATHS.MEDICAL_LISTING);
    }
  };

  const commonRadioGroup = (label: string, name: string, value: string | undefined, option: object[]) => {
    return <RadioGroup className="radio-button-styles" label={label} name={name} onChange={(e) => onRadioChange(e)} options={option} value={value} />;
  };

  const handleSkipThisStep = () => {
    resetHmcFilter();
    resetMedicalPlanStates();
    navigate(NAV_PATHS.MEDICAL_LISTING);
  }

  return (!contentLoaded ? <FullPageLoader /> :

    <div className="fwc-row">
      <div className="fwc-col-12">
        <div className="pageHeader-content" id="heading-wraper">
          <h2 id="helpme-choose-heading">{pageContent?.HELPME_CHOOSE_HEADING}</h2>
          <p id="helpmechoose-headertext">{pageContent?.HELPCHOOSE_HEADERTEXT}</p>
          <p id="elpmechoose-header-content">
            {pageContent?.HELPCHOOSE_HEADER_CONTENT_}
            <a data-analytics={"skipPageLinkHelpChooseIos"} id="skippage-link" onClick={handleSkipThisStep}>{pageContent?.HELPCHOOSE_SKIPPAGE_LINK}</a> {pageContent?.HELPCHOOSE_HEADER_CONTENT2}
          </p>
        </div>
        <Card>
          <Card.Body className="card-container">
            <div className="radioGroup-container" id="radioGroup-container1">
              {commonRadioGroup(
                pageContent?.HELPMECHOOSE_QUESTIONS.QUESTION_1.QUESTION,
                'Q1',
                helpMeChoose.find((question) => question.code === 'Q1')?.value,
                pageContent?.HELPMECHOOSE_QUESTIONS.QUESTION_1.OPTIONS
              )}
              {errorMessage && helpMeChoose[0].value === '' && <span id="error-message1">{pageContent?.ERROR_MESSAGE}</span>}
            </div>
            <div className="radioGroup-container" id="radioGroup-container2">
              {commonRadioGroup(
                pageContent?.HELPMECHOOSE_QUESTIONS.QUESTION_2.QUESTION,
                'Q2',
                helpMeChoose.find((question) => question.code === 'Q2')?.value,
                pageContent?.HELPMECHOOSE_QUESTIONS.QUESTION_2.OPTIONS
              )}
              {errorMessage && helpMeChoose[1].value === '' && <span id="error-message2">{pageContent?.ERROR_MESSAGE}</span>}
            </div>
            <div className="radioGroup-container" id="radioGroup-container3">
              {commonRadioGroup(
                pageContent?.HELPMECHOOSE_QUESTIONS.QUESTION_3.QUESTION,
                'Q3',
                helpMeChoose.find((question) => question.code === 'Q3')?.value,
                pageContent?.HELPMECHOOSE_QUESTIONS.QUESTION_3.OPTIONS
              )}
              {errorMessage && helpMeChoose[2].value === '' && <span id="error-message3">{pageContent?.ERROR_MESSAGE}</span>}
            </div>
            <div className="radioGroup-container4" id="radioGroup-container4">
              {commonRadioGroup(
                pageContent?.HELPMECHOOSE_QUESTIONS.QUESTION_4.QUESTION,
                'Q4',
                helpMeChoose.find((question) => question.code === 'Q4')?.value,
                pageContent?.HELPMECHOOSE_QUESTIONS.QUESTION_4.OPTIONS
              )}
              {errorMessage && helpMeChoose[3].value === '' && <span id="error-message4">{pageContent?.ERROR_MESSAGE}</span>}
            </div>
          </Card.Body>
        </Card>
        <div className="helpmechoose-buttons-wrapper" id="buttons-wrapper">
          <button data-analytics="backBtnHelpChooseIos" className={'fwc-btn fwc-btn-secondary'} id="back-button" onClick={() => navigate(-1)}>
            {pageContent?.BACK_BUTTON}
          </button>
          <div>
            <button data-analytics="skipStepBtnHelpChooseIos" className={'fwc-btn fwc-btn-secondary'} id="skip-step" onClick={() => handleSkipThisStep()}>
              {pageContent?.SKIP_THIS_STEP}
            </button>
            <button data-analytics="viewMyPlanBtnHelpChooseIos" className={'fwc-btn fwc-btn-primary'} onClick={(e) => onViewMyPlanClick(e)} id="view-my-plane">
              {pageContent?.VIEW_MY_PLAN}
            </button>
          </div>
        </div>
        <div className="page-content">
          <p id="helpme-footer-content">{pageContent?.HELPMECHOOSEFOOTERTEXT}</p>
          <div dangerouslySetInnerHTML={{ __html: pageContent?.PAGEHTML }}></div>
        </div>
      </div>
    </div>
  );
}
export default HelpMeChoose;