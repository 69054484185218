import { LANGUAGE_CODES, NO, PLAN_TYPES_NAMES, SET_APPLICATION_CONSTANTS, USER_DOMAINS, YES, YES_NO_VALUES } from '../../../../shared/globalConstants';
import { formatDateToYYYYMMDD } from '../../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../../store/globalStore';
import { PLAN_TIERS_ALL } from '../../medical-plans/constants';
import { getApplicableMetalTiers, getFormattedApplicantDetails, setDomesticPartnerAsSpouse } from '../../medical-plans/medicalPlansUtils';
import { loadPlans } from '../login-modal/loginUtils';

const getPinAndDobByQuotePayload = (pin: string, quoteId: string, dateOfBirth: string): PinAndDobByQuotePayload => {
  return { pin: parseInt(pin), quoteId, dateOfBirth: formatDateToYYYYMMDD(dateOfBirth) };
};

const getProposedQuoteDetails = (proposedQuote: GetAgentQuoteResponse): ProposedQuoteDetails => {
  return {
    county: proposedQuote?.demographicInfo?.county ?? '',
    countyCode: proposedQuote?.demographicInfo?.countyCode ?? '',
    zipcode: proposedQuote?.demographicInfo?.zipCode ?? '',
    state: proposedQuote?.demographicInfo?.state ?? '',
    brand: proposedQuote?.demographicInfo?.brand ?? '',
    coverageType: proposedQuote?.demographicInfo?.coverageType ?? ''
  };
};

function getUniqueApplicants(quoteLineItems: QuoteLineItem[]): Applicant[] {
  const allApplicants = quoteLineItems.flatMap((item) => item.applicant);
  let uniqueApplicants: Applicant[] = [];

  allApplicants.forEach((applicant) => {
    if (!uniqueApplicants.some((uniqueApplicant) => uniqueApplicant.applicantId === applicant.applicantId)) {
      uniqueApplicants.push(applicant);
    }
  });

  return uniqueApplicants;
}

const getProposedQuoteDemographicInfo = (proposedQuote: GetAgentQuoteResponse): DemographicInfo => {
  return {
    ...proposedQuote?.demographicInfo,
    applicant: getUniqueApplicants(proposedQuote.quote.quoteLineItem)
  };
};

const getDemographicInfoFromEvenderInfo = (evenderInfo: EvenderInfo, zipCodeResponse: ZipCodeClass): DemographicInfo => {
  return {
    applicant: [
      {
        applicantType: SET_APPLICATION_CONSTANTS.APPLICANT,
        firstName: evenderInfo.firstName ?? '',
        lastName: evenderInfo.lastName ?? '',
        gender: evenderInfo.appGender ?? '',
        dateOfBirth: evenderInfo?.appDOB ?? '',
        isTabaccoUser: evenderInfo.tobaccoUser === YES_NO_VALUES.YES || evenderInfo.tobaccoUser === YES ? YES : NO
      }
    ],
    coverageEffectiveDate: evenderInfo.reqEffDt,
    brand: evenderInfo.brand,
    zipCode: evenderInfo.appZipcode,
    county: evenderInfo.appCounty,
    countyCode:
      zipCodeResponse.countyList.county.find((val) => {
        return evenderInfo.appCounty === val.countyName;
      })?.countyCode ?? zipCodeResponse.countyList.county[0].countyCode,
    state: zipCodeResponse.stateCode
  };
};

const getAgentInfoFroEvenderInfo = (evenderInfo: EvenderInfo): AgentOfRecord => {
  return {
    agentFirstName: evenderInfo.agentfirstName,
    agentLastName: evenderInfo.agentlastName,
    licenseNumber: evenderInfo.licenseNO,
    ffmUserId: evenderInfo.ffmUserId,
    certificateId: evenderInfo.certificationNO,
    paidTin: evenderInfo.paidTIN,
    agentId: evenderInfo.agentTin,
    writingTIN: evenderInfo.agentTin,
    agentType: '',
    agency: evenderInfo.agency,
    phone: evenderInfo.phone ?? '',
    email: evenderInfo.email ?? '',
    lobState: evenderInfo.LOBState,
    licenseNO: evenderInfo.licenseNO,
    npn: evenderInfo.npn ?? '',
    agentCode: evenderInfo.agentCode,
    address: {
      addressLine1: evenderInfo.address1 ?? '',
      city: evenderInfo.city ?? '',
      state: evenderInfo.state ?? ''
    },
    brand: evenderInfo.brand,
    parentTin: evenderInfo.parentTin,
    agencyName: evenderInfo.agency,
    firstName: evenderInfo.firstName,
    lastName: evenderInfo.lastName,
    phoneExtention: evenderInfo.phoneext,
    fax: evenderInfo.fax,
    faxExt: evenderInfo.faxext,
    certificateID: evenderInfo.certificationNO,
    campaign: {
      cid: evenderInfo.cid,
      cidPhone: evenderInfo.cidPhone,
      quoteSource: evenderInfo.quoteSrc
    },
    ffmuserId: evenderInfo.ffmUserId
  };
};

const getAllPlanDetailsPayload = (
  demographicInfo: DemographicInfo,
  lcontarctCode: string,
  planType: string,
  subsidyresponse: Subsidy = {} as Subsidy,
  applicant: Applicant[]
): PlanSummaryPayload => {
  const planYear = demographicInfo?.coverageEffectiveDate?.split('-')[0];

  let payload: PlanSummaryPayload = {
    brand: demographicInfo.brand ?? '',
    planTypes: [planType],
    metalTierTypes:
      planType === PLAN_TYPES_NAMES.MEDICAL
        ? getApplicableMetalTiers(getFormattedApplicantDetails(applicant ?? []), demographicInfo?.coverageEffectiveDate)
        : PLAN_TIERS_ALL,
    marketSegment: USER_DOMAINS.MARKET_SEGMENT_FOR_PLAN_SUMMARY,
    zipCode: demographicInfo.zipCode ?? '',
    county: demographicInfo.county ?? '',
    state: demographicInfo.state ?? '',
    coverageEffectiveDate: formatDateToYYYYMMDD(demographicInfo?.coverageEffectiveDate ?? ''), //Update the format
    planYear: planYear ?? '',
    applicants: setDomesticPartnerAsSpouse(getFormattedApplicantDetails(applicant ?? [], false)),
    subsidy: {
      subsidyAmt: planType === PLAN_TYPES_NAMES.MEDICAL ? (subsidyresponse?.subsidyAmount ? subsidyresponse?.subsidyAmount.toString() : null) : null,
      costShareReduction: planType === PLAN_TYPES_NAMES.MEDICAL ? (Number(subsidyresponse?.costShareReductionValue) ?? 0) : null
    },
    language: LANGUAGE_CODES.EN,
    countyCode: demographicInfo.countyCode ?? '',
    contractList: {
      contractBundle: [
        {
          contractCode: lcontarctCode
        }
      ]
    }
  };

  return payload;
};

const getSelectedPlansByQuoteDetails = async (proposedQuote: GetAgentQuoteResponse): Promise<SelectedPlansAndTypes> => {
  let selectedPlans: SelectedAllPlans = {
    selectedMedicalPlan: {
      plan: null,
      applicants: [],
      matchedProviders: [],
      matchedMedications: []
    },
    selectedDentalPlan: {
      plan: null,
      applicants: [],
      matchedProviders: [],
      matchedMedications: []
    },
    selectedVisionPlan: {
      plan: null,
      applicants: [],
      matchedProviders: [],
      matchedMedications: []
    }
  };
  let planTypes: string[] = [];
  const quoteLineItem = proposedQuote.quote.quoteLineItem;

  if (quoteLineItem.length > 0) {
    await Promise.all(
      quoteLineItem.map(async (quoteLineItem: QuoteLineItem) => {
        if (proposedQuote.demographicInfo) {
          const payload: PlanSummaryPayload = getAllPlanDetailsPayload(
            proposedQuote.demographicInfo,
            quoteLineItem?.contract?.contractCode,
            quoteLineItem?.contract.planType,
            proposedQuote.demographicInfo?.subsidy,
            quoteLineItem?.applicant
          );

          const plan = await loadPlans(payload);
          let idx = 0;
          if (plan) {
            if (plan.plan[0].planType === PLAN_TYPES_NAMES.MEDICAL) {
              idx = 1;
              selectedPlans.selectedMedicalPlan.plan = plan;
              selectedPlans.selectedMedicalPlan.applicants = quoteLineItem?.applicant;
              planTypes.push(PLAN_TYPES_NAMES.MEDICAL);
            } else if (plan.plan[0].planType === PLAN_TYPES_NAMES.DENTAL) {
              idx = 2;
              selectedPlans.selectedDentalPlan.plan = plan;
              selectedPlans.selectedDentalPlan.applicants = quoteLineItem?.applicant;
              planTypes.push(PLAN_TYPES_NAMES.DENTAL);
            } else if (plan.plan[0].planType === PLAN_TYPES_NAMES.VISION) {
              idx = 3;
              selectedPlans.selectedVisionPlan.plan = plan;
              selectedPlans.selectedVisionPlan.applicants = quoteLineItem?.applicant;
              planTypes.push(PLAN_TYPES_NAMES.DENTAL);
            }
          }
        }
      })
    );
    return { selectedPlans, planTypes };
  } else {
    return { selectedPlans, planTypes };
  }
};

const getPlanTypeAndIdForEvender = (evenderInfo: EvenderInfo): PlanTypeAndId => {
  if (evenderInfo.medicalProductId) {
    return {
      planType: PLAN_TYPES_NAMES.MEDICAL,
      contractCode: evenderInfo.medicalProductId
    };
  } else if (evenderInfo.dentalProductId) {
    return {
      planType: PLAN_TYPES_NAMES.DENTAL,
      contractCode: evenderInfo.dentalProductId
    };
  } else {
    return {
      planType: PLAN_TYPES_NAMES.VISION,
      contractCode: evenderInfo.visionProductId ?? ''
    };
  }
};

const getMatchedApplicants = (demographicInfoApplicants: Applicant[], selectedApplicants: SharedQuoteApplicants): Applicant[] => {
  let matchedApplicants: Applicant[] = [];
  //Fond the matching applicant from demographics and return them
  if (selectedApplicants.length) {
    selectedApplicants.forEach((selectedApplicant) => {
      const matchedApplicant = demographicInfoApplicants.find((applicant) => applicant.applicantId === selectedApplicant.applicantId);
      if (matchedApplicant) {
        matchedApplicants.push(matchedApplicant);
      }
    });
  } else {
    matchedApplicants = demographicInfoApplicants;
  }

  return Array.from(new Set(matchedApplicants));
};

const getMatchedApplicantsByQuoteId = (contractCode: string, demographicInfo: DemographicInfo, quoteLineItem: SharedQuoteLineItem[]): Applicant[] => {
  let matchedApplicants: Applicant[] = [];
  //Fond the matching applicant from demographics and return them
  const matchingQuoteObj = quoteLineItem.find((quoteObj) => quoteObj.contract.contractCode === contractCode);
  if (matchingQuoteObj) {
    matchedApplicants = getMatchedApplicants(demographicInfo.applicant ?? [], matchingQuoteObj.applicant);
  } else {
    matchedApplicants = demographicInfo.applicant ?? [];
  }

  return Array.from(new Set(matchedApplicants));
};

const getContractCodesFromQuote = (quote: SharedQuote[]): { medicalContractCode: string; dentalContractCode: string; visionContractCode: string } => {
  const medicalContractCode = quote[0]?.quoteLineItem.find((quote) => quote?.contract?.planType === PLAN_TYPES_NAMES.MEDICAL)?.contract.contractCode;
  const dentalContractCode = quote[0]?.quoteLineItem.find((quote) => quote?.contract?.planType === PLAN_TYPES_NAMES.DENTAL)?.contract.contractCode;
  const visionContractCode = quote[0]?.quoteLineItem.find((quote) => quote?.contract?.planType === PLAN_TYPES_NAMES.VISION)?.contract.contractCode;

  return {
    medicalContractCode: medicalContractCode ?? '',
    dentalContractCode: dentalContractCode ?? '',
    visionContractCode: visionContractCode ?? ''
  };
};

const getContractCodes = (quotes: SharedQuote[]): { medicalContractCode: string; dentalContractCode: string; visionContractCode: string } => {
  const { deepLinkInfo } = useGlobalStore.getState();
  const urlMedicalContractCode = deepLinkInfo.medicalContractCode;
  const urlDentalContractCode = deepLinkInfo.dentalContractCode;
  const urlVisionContractCode = deepLinkInfo.visionContractCode;
  let matchingMedicalContract: SharedQuoteLineItem | undefined = undefined;
  let matchingDetalContract: SharedQuoteLineItem | undefined = undefined;
  let matchingVisionContract: SharedQuoteLineItem | undefined = undefined;

  if (quotes?.length) {
    matchingMedicalContract = quotes[0]?.quoteLineItem.find((quote) => quote?.contract?.contractCode === urlMedicalContractCode);
    matchingDetalContract = quotes[0]?.quoteLineItem.find((quote) => quote?.contract?.contractCode === urlDentalContractCode);
    matchingVisionContract = quotes[0]?.quoteLineItem.find((quote) => quote?.contract?.contractCode === urlVisionContractCode);
  }

  if (matchingMedicalContract) {
    return getContractCodesFromQuote(quotes);
  } else if (!matchingMedicalContract && matchingDetalContract) {
    return getContractCodesFromQuote(quotes);
  } else if (!matchingMedicalContract && !matchingDetalContract && matchingVisionContract) {
    return getContractCodesFromQuote(quotes);
  } else {
    return {
      medicalContractCode: urlMedicalContractCode,
      dentalContractCode: urlDentalContractCode,
      visionContractCode: urlVisionContractCode
    };
  }
};

const getSelectedPlansBySharedQuote = async (demographicInfo: DemographicInfo, quotes: SharedQuote[]): Promise<SelectedPlansAndTypes> => {
  const { medicalContractCode, dentalContractCode, visionContractCode } = getContractCodes(quotes);
  let selectedPlans: SelectedAllPlans = {
    selectedMedicalPlan: {
      plan: null,
      applicants: [],
      matchedProviders: [],
      matchedMedications: []
    },
    selectedDentalPlan: {
      plan: null,
      applicants: [],
      matchedProviders: [],
      matchedMedications: []
    },
    selectedVisionPlan: {
      plan: null,
      applicants: [],
      matchedProviders: [],
      matchedMedications: []
    }
  };
  let planTypes: string[] = [];
  const [quote] = quotes ? quotes : [{ quoteLineItem: [] }];
  const quoteLineItems = quote.quoteLineItem;

  planTypes.push(PLAN_TYPES_NAMES.MEDICAL);
  planTypes.push(PLAN_TYPES_NAMES.DENTAL);
  planTypes.push(PLAN_TYPES_NAMES.VISION);

  if (medicalContractCode) {
    const applicants = demographicInfo.applicant ?? [];
    const payload: PlanSummaryPayload = getAllPlanDetailsPayload(
      demographicInfo,
      medicalContractCode,
      PLAN_TYPES_NAMES.MEDICAL,
      demographicInfo.subsidy,
      applicants
    );
    const plan = await loadPlans(payload);
    selectedPlans.selectedMedicalPlan.plan = plan;
    selectedPlans.selectedMedicalPlan.applicants = applicants;
  }

  if (dentalContractCode) {
    const applicants = getMatchedApplicantsByQuoteId(dentalContractCode, demographicInfo, quoteLineItems);
    const payload: PlanSummaryPayload = getAllPlanDetailsPayload(
      demographicInfo,
      dentalContractCode,
      PLAN_TYPES_NAMES.DENTAL,
      demographicInfo.subsidy,
      applicants
    );
    const plan = await loadPlans(payload);
    selectedPlans.selectedDentalPlan.plan = plan;
    selectedPlans.selectedDentalPlan.applicants = applicants;
  }

  if (visionContractCode) {
    const applicants = getMatchedApplicantsByQuoteId(visionContractCode, demographicInfo, quoteLineItems);
    const payload: PlanSummaryPayload = getAllPlanDetailsPayload(
      demographicInfo,
      visionContractCode,
      PLAN_TYPES_NAMES.VISION,
      demographicInfo.subsidy,
      applicants
    );
    const plan = await loadPlans(payload);
    selectedPlans.selectedVisionPlan.plan = plan;
    selectedPlans.selectedVisionPlan.applicants = applicants;
  }

  return { selectedPlans, planTypes };
};

const getSelectedPlansByEVenderInfo = async (evenderInfo: EvenderInfo, demographicInfo: DemographicInfo): Promise<SelectedPlansAndTypes> => {
  let selectedPlans: SelectedAllPlans = {
    selectedMedicalPlan: {
      plan: null,
      applicants: [],
      matchedProviders: [],
      matchedMedications: []
    },
    selectedDentalPlan: {
      plan: null,
      applicants: [],
      matchedProviders: [],
      matchedMedications: []
    },
    selectedVisionPlan: {
      plan: null,
      applicants: [],
      matchedProviders: [],
      matchedMedications: []
    }
  };
  let planTypes: string[] = [];
  const { planType, contractCode } = getPlanTypeAndIdForEvender(evenderInfo);
  const applicants = demographicInfo.applicant ?? [];
  const payload: PlanSummaryPayload = getAllPlanDetailsPayload(demographicInfo, contractCode, planType, {} as Subsidy, applicants);
  const plan = await loadPlans(payload);

  let idx = 0;
  if (plan) {
    if (plan.plan[0].planType === PLAN_TYPES_NAMES.MEDICAL) {
      idx = 1;
      selectedPlans.selectedMedicalPlan.plan = plan;
      selectedPlans.selectedMedicalPlan.applicants = applicants;
      planTypes.push(PLAN_TYPES_NAMES.MEDICAL);
    } else if (plan.plan[0].planType === PLAN_TYPES_NAMES.DENTAL) {
      idx = 2;
      selectedPlans.selectedDentalPlan.plan = plan;
      selectedPlans.selectedDentalPlan.applicants = applicants;
      planTypes.push(PLAN_TYPES_NAMES.DENTAL);
    } else if (plan.plan[0].planType === PLAN_TYPES_NAMES.VISION) {
      idx = 3;
      selectedPlans.selectedVisionPlan.plan = plan;
      selectedPlans.selectedVisionPlan.applicants = applicants;
      planTypes.push(PLAN_TYPES_NAMES.DENTAL);
    }
    if (idx === 2) {
      selectedPlans.selectedVisionPlan.applicants = applicants;
    } else if (idx < 2) {
      selectedPlans.selectedDentalPlan.applicants = applicants;
    }

    return { selectedPlans, planTypes };
  } else {
    return { selectedPlans, planTypes };
  }
};

const isOnExchangePlanSelected = (selectedPlans: SelectedAllPlans): boolean => {
  const selectedPrimaryPlan = selectedPlans.selectedMedicalPlan.plan || selectedPlans.selectedDentalPlan.plan || selectedPlans.selectedVisionPlan.plan;

  return selectedPrimaryPlan?.onExchange ?? false;
};

const isColaradoConnectPlanSelected = (selectedPlans: SelectedAllPlans): boolean => {
  const selectedPrimaryPlan = selectedPlans.selectedMedicalPlan.plan || selectedPlans.selectedDentalPlan.plan || selectedPlans.selectedVisionPlan.plan;

  return selectedPrimaryPlan?.poPlan ?? false;
};

export {
  getAgentInfoFroEvenderInfo,
  getDemographicInfoFromEvenderInfo,
  getPinAndDobByQuotePayload,
  getProposedQuoteDemographicInfo,
  getProposedQuoteDetails,
  getSelectedPlansByEVenderInfo,
  getSelectedPlansByQuoteDetails,
  getSelectedPlansBySharedQuote,
  getUniqueApplicants,
  isColaradoConnectPlanSelected,
  isOnExchangePlanSelected
};
