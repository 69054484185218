import React, { useEffect } from 'react';
import { USER_ROLES } from '../../../shared/globalConstants';
import { useGlobalStore } from "../../../store/globalStore";
import { useUserStore } from '../../../store/userStore';
import './CustomRadioGroup.scss';

interface CustomRadio {
  icon: string; // required
  label: string; // required
  id: string; //required
  value: string; //required
  name: string; //required
  disabled?: boolean;
  innerHtml?: TrustedHTML;
  checked?: boolean;
}

interface CustomRadioGroup {
  label: string;
  name: string;
  inline: boolean;
  options: CustomRadio[];
  onCustomRadioSelect: (selectedValue: string) => void;
  register: any;
  errors: any;
  errorMessage: string;
  role?: string;
}

// Custom Radio Group Component
const CustomRadioGroup = ({ label, name, options, field, onCustomRadioSelect, role }) => {
  const { updateProgressSteps, medicalSteps, dentalSteps, visionSteps, medicalDentalVisionSteps, state, zipCodeRes, brand, year, agentSSOInfo, atkSteps } = useGlobalStore(state => state);
  const { isWellpoint } = useUserStore(state => state);

  const onSelectCustomRadio = (e, option) => {
    onCustomRadioSelect(e);
    if (!agentSSOInfo.isAtk) {
      setProgressSteps(option.name);
    }
  };
  const setProgressSteps = (type) => {
    if (type === 'medical-only' || (isWellpoint && type === 'medical')) {
      updateProgressSteps(medicalSteps);
    } else if (type === 'dental') {
      updateProgressSteps(dentalSteps);
    } else if (type === 'vision') {
      updateProgressSteps(visionSteps);
    } else if (type === 'atk') {
      updateProgressSteps(atkSteps);
    } else {
      updateProgressSteps(medicalDentalVisionSteps);
    }
  }

  useEffect(() => {
    if (!agentSSOInfo.isAtk) {
      setProgressSteps(options.find((option) => option.value === field.value)?.name || options[0].name)
    } else {
      setProgressSteps('atk');
    }
  }, [options]);


  return (
    <div className="fwc-row">
      <div className="fwc-col-12">
        <fieldset className="fwc-radio-group-wrapper">
          <legend>{label}</legend>
          <div className={"fwc-radio-group inline " + (role === USER_ROLES.MEMBER ? "member-radio " : "")}>
            {options.map((option, index) => (
              <div className="fwc-radio" key={index}>
                <input
                  type="radio"
                  {...field}
                  value={option.value}
                  id={option.id}
                  data-analytics={option.analyticsId}
                  name={name}
                  checked={option.value === field.value}
                  onChange={(e) => {
                    field.onChange(e);
                    onSelectCustomRadio(e, option);
                  }}
                  onClick={(e) => {
                    field.onChange(e);
                    onSelectCustomRadio(e, option);
                  }}
                />
                <label className="fwc-label" htmlFor={option.id}>
                  <span className={'sae-icon ' + (option.icon ? option.icon : '')}></span>
                  <span className="titlewrapper">
                    <span dangerouslySetInnerHTML={{ __html: option.label }}></span>
                    <i className="sae-icon sae-icon-circled"></i>
                  </span>
                </label>
              </div>
            ))}
          </div>
        </fieldset>
      </div>
    </div>
  );
};

export default CustomRadioGroup;
