import { Modal, Paragraph, Popover } from '@sydney-broker-ui/ios';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { HEALTHSHERPA_BASE_URLS, HEALTHSHERPA_WELLPOINT_BASE_URLS } from '../../../api/api-name-list';
import { getCMSStaticContent } from '../../../api/cms/cmsUtil';
import { generateTokenAPI, getBuildVersion } from '../../../api/services/tokenService';
import { PLAN_MARKET_TYPES } from '../../../components/common/compare-tray/constant';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import { ATK_REDIRECTION_STATES, CATASTROPHIC_PLAN, FFM_STATES, GLOBAL_STATUS, LANGUAGE_CODES, PLAN_TYPES_NAMES, SCREEN_NAMES, SET_APPLICATION_CONSTANTS, WELLPOINT_FFM_STATES, WELLPOINT_MD_STATE } from '../../../shared/globalConstants';
import { getFormattedCurrency, handleSetApplicationSuccessRedirection } from "../../../shared/utils/globalUtils";
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import { getEncryptAcn, setApplicationApi, updateQuoteStatus } from '../../public/plan-selection-summary/planSummaryServices';
import { saveAgentQuote } from '../product-selection/productSelectionServices';
import { getPayloadForAgentQuote } from '../product-selection/productSelectionUtils';
import { default as Content } from './AtkPlanSelectionSummary.json';
import './AtkPlanSelectionSummary.scss';
import { getApplicationListingApi } from './AtkPlanSelectionSummaryServices';
import { getApplicableMarketType, getAppplicationListingPayload, getEncryptacnPayload, getSetApplicationData, getUpdateQuoteStatusPayload } from './AtkPlanSelectionSummaryUtils';
import AtkPlanSummaryCard from './atkPlanSummaryCard/AtkPlanSummaryCard';

const { REACT_APP_ENV } = process.env;

const AtkPlanSelectionSummary: React.FC = () => {
    const [anchor, setAnchor] = useState(null);
    const [applyModalTitle, setApplyModalTitle] = useState<string>('');
    const [applyNowLabel, setApplyNowLabel] = useState<string>('');
    const [open, setOpen] = useState<boolean>(false);
    const [placement, setPlacement] = React.useState();
    const [showApplyModal, setShowApplyModal] = useState(false);
    const [selectedPlanMarketType, setSelectedPlanMarketType] = useState(PLAN_MARKET_TYPES.OFF_MARKET);
    const [loader, setLoader] = useState(false);
    const [applyModalDesc, setApplyModalDesc] = useState<string>('');

    const estimatedTaxCredit = '0';
    const [pageContent, setPageContent] = useState<any>(null);
    const [contentLoaded, setContentLoaded] = useState(false);
    const [planForDetails, setPlanForDetails] = useState<Contract>({} as Contract);
    const divRef = useRef(null);
    const location: { state: AtkSummaryLocationState } = useLocation();

    const { state } = location;

    let { selectedProduct, agentBrand, agentZipcode, agentYear, agentPlanTypes, agentState, agentCoverageType, agentZipCodeRes, selectedDentalProducts, selectedVisionProducts, dentalProducts, visionProducts, agentApplicantFormDetails, agentSSOInfo, aTKsaveProposalResponse, coverageRule, updateATKsaveProposalResponse } = useGlobalStore((store) => store);
    const { loggedIn, isWellpoint, role } = useUserStore((state) => state);

    const getContent = useCallback(async (): Promise<boolean> => {
        let content: any;
        let content2: any;
        try {
            const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.ATKPLAN_SELECTION_SUMMARY);
            content = response.data.data.iospagecontentList.items[0].pageContent?.AtkPlanSelectionSummary;

        } catch (error) {
            content = Content.data.iospagecontentList.items[0].pageContent?.AtkPlanSelectionSummary;

            return false;
        } finally {
            setPageContent(content);

            setContentLoaded(true);
        }
        return true;
    }, [agentZipCodeRes.stateCode]);

    useEffect(() => {
        if (pageContent) {
            const applicableMarketType = getApplicableMarketType(selectedProduct?.medicalProduct, selectedProduct?.dentalProduct, selectedProduct?.visionProduct);

            setSelectedPlanMarketType(applicableMarketType);
            selectApplyNowLabel(applicableMarketType);
        }
    }, [pageContent])

    const initiate = async () => {
        await getContent();
    };

    useEffect(() => {
        initiate();
        return () => {
            // cleanup

            setLoader(false);
        };
    }, []);

    document.body.classList.remove("no-scroll");


    const getSetApplication = async (isOffExchange: boolean) => {
        setLoader(true);
        try {
            let isHavingInPApplication = false;
            let getAppplication = getAppplicationListingPayload();
            let applicationResponce = await getApplicationListingApi(getAppplication);
            if (applicationResponce?.data?.status === SET_APPLICATION_CONSTANTS.SUCCESS) {
                let inProgressApplication = applicationResponce?.data?.response?.applications?.find((application) => GLOBAL_STATUS.INPROGRESS === application.applicationStatus);
                inProgressApplication && (isHavingInPApplication = true);
            }
            if (isHavingInPApplication) {
                setApplyModalTitle(pageContent.INPROGRESS_APPLICATION_TITLE);
                setApplyModalDesc(pageContent.INPROGRESS_APPLICATION_DESC);
                setShowApplyModal(true);
                setLoader(false);
            } else {
                let payload = getSetApplicationData(!isOffExchange);

                let response = await setApplicationApi(payload);
                if (response?.data?.status === SET_APPLICATION_CONSTANTS.SUCCESS) {

                    const updateQuoteStatusPayload = getUpdateQuoteStatusPayload(response?.data?.response?.acn, useGlobalStore.getState().aTKsaveProposalResponse?.quote?.quoteId || '');
                    await updateQuoteStatus(updateQuoteStatusPayload);

                    const encryptedACNPayload: EncryptacnPayload = getEncryptacnPayload(response?.data?.response?.acn)
                    const encryptedacnResponse = await getEncryptAcn(encryptedACNPayload);
                    // setLoader(false);
                    handleSetApplicationSuccessRedirection(isOffExchange, encryptedacnResponse?.data?.encryptedACN, REACT_APP_ENV, isWellpoint)
                } else {
                    setLoader(false);
                }
            }

        } catch (error) {
            setLoader(false);
            console.error(error);
        }
    }

    const redirectToHealthSherpa = () => {
        window.open(`${isWellpoint ? HEALTHSHERPA_WELLPOINT_BASE_URLS[REACT_APP_ENV ?? ''] : HEALTHSHERPA_BASE_URLS[REACT_APP_ENV ?? '']}/dashboard/?_carrier_id=${isWellpoint ? "wellpoint" : "anthem"}&agent[npn]=${agentSSOInfo?.agentInfo?.agent[0]?.NPN}&user_type=agent`, '_self')
    }

    const redirectToStateBasedWebSites = () => {
        window.open(`${pageContent?.ATK_ON_EXCHANGE_URLS[agentState]}`, '_self')
    }


    const isDentalOnlyAddedForOnExchange = () => {
        return ((!selectedProduct?.medicalProduct && selectedProduct?.dentalProduct));
    }

    const selectApplyNowLabel = (applicableMarketType: string) => {
        switch (applicableMarketType) {
            case PLAN_MARKET_TYPES.COLARADO_CONNECT:
                setApplyNowLabel(pageContent.costListing.APPLY_ON_COLORADO);
                break;
            case PLAN_MARKET_TYPES.MARKET_PLACE:
                if (isWellpoint) {
                    setApplyNowLabel(agentState === WELLPOINT_MD_STATE ? pageContent.costListing.APPLY_MARKET_PLACE : pageContent.costListing.APPLY_NOW_BUTTON)
                } else {
                    setApplyNowLabel(isDentalOnlyAddedForOnExchange() ? pageContent.costListing.APPLY_MARKET_PLACE : pageContent.costListing.APPLY_NOW_BUTTON)
                }
                break;
            default:
                setApplyNowLabel(pageContent.costListing.APPLY_NOW_BUTTON)
                break;
        }
    }

    const getToken = async (): Promise<boolean> => {
        let returnVal = false;
        try {
            const response = await getBuildVersion();
            if (response.status === 200) {
                try {
                    const tokenResponse = await generateTokenAPI();
                    if (tokenResponse && tokenResponse.data.token) {
                        useUserStore.getState().updateJWTToken({ jwtToken: tokenResponse.data.token });
                        returnVal = true;
                    } else {
                        returnVal = false;
                        setLoader(false);
                    }
                } catch (error: any) {
                    console.error('getToken error', error);
                    returnVal = false;
                    setLoader(false);
                }
            }
        }
        catch (error: any) {
            console.error('getToken error', error);
            returnVal = false;
            setLoader(false);
        }
        finally {
            return returnVal;
        }
    }

    const handleSaveAgentQuoteAPI = async () => {
        setLoader(true);
        let tokenGenerated = await getToken();
        if (tokenGenerated || REACT_APP_ENV === 'local') {
            const payload = await getPayloadForAgentQuote(useGlobalStore.getState().selectedProduct, state?.quoteId, state?.currentDemographicInfo);
            try {
                await saveAgentQuote(payload).then((response) => {
                    updateATKsaveProposalResponse(response.data);
                    setLoader(false);
                    applyNowOnClick()
                }
                )
            }
            catch (error) {
                setLoader(false);
                console.error('handleSaveAgentQuoteAPI error', error);
            }
        }
        else {
            setLoader(false);
        }
    }

    const applyNowOnClick = () => {
        switch (selectedPlanMarketType) {
            case PLAN_MARKET_TYPES.MARKET_PLACE:
                if (isDentalOnlyAddedForOnExchange() || (isCatastriphicPlanSelected() && WELLPOINT_FFM_STATES.includes(agentState)) || (isCatastriphicPlanSelected() && FFM_STATES.includes(agentState))) {
                    setApplyModalTitle('');
                    setApplyModalDesc((isCatastriphicPlanSelected() && FFM_STATES.includes(agentState)) || (isCatastriphicPlanSelected() && WELLPOINT_FFM_STATES.includes(agentState)) ? pageContent.MODAL_DESC_FFM_CATASTROPHIC : pageContent.MODAL_DESC);
                    setShowApplyModal(true);
                } else if (agentState === SET_APPLICATION_CONSTANTS.KY) {
                    getSetApplication(false);
                } else if (ATK_REDIRECTION_STATES.includes(agentState)) {
                    redirectToStateBasedWebSites();
                } else {
                    redirectToHealthSherpa();
                }
                break;
            case PLAN_MARKET_TYPES.OFF_MARKET:
                getSetApplication(true);
                break;
            case PLAN_MARKET_TYPES.COLARADO_CONNECT:
                // setApplyModalTitle(pageContent.COLORADO_MODAL_TITLE);
                // setShowApplyModal(true);
                window.open(`${pageContent?.ATK_ON_EXCHANGE_URLS[agentState]}`, '_self')
                break;
        }
    }

    const isCatastriphicPlanSelected = () => {
        return selectedProduct?.medicalProduct?.tiersType === CATASTROPHIC_PLAN;
    }

    const closeApplyNowModal = () => {
        document.body.classList.remove("no-scroll");
        setShowApplyModal(false);
        setApplyModalDesc('');
        setApplyModalTitle('');
    }

    const togglePopover = () => {
        setOpen((old) => !old);
    };

    const handleClick = (newPlacement) => (event) => {
        setAnchor(event.currentTarget);
        setOpen((prev) => placement !== newPlacement || !prev);
        setPlacement(newPlacement);
    };

    const navigate = useNavigate();

    const getTotalPremium = () => {
        let totalPremium: number = 0;
        if (selectedProduct?.medicalProduct) {
            totalPremium += selectedProduct?.medicalProduct?.rateData?.rate?.totSubsidizedPrem ?? 0;
        }
        if (selectedProduct?.dentalProduct) {
            totalPremium += selectedProduct?.dentalProduct?.rateData?.rate?.totalPremium ?? 0;
        }
        if (selectedProduct?.visionProduct) {
            totalPremium += selectedProduct?.visionProduct?.rateData?.rate?.totalPremium ?? 0;
        }
        return getFormattedCurrency(totalPremium);
    }


    // hide apply now
    const handleApplyNow = () => {
        let today;
        try {
            today = moment.parseZone(agentZipCodeRes.currentDateTimeInZone).format('YYYY-MM-DDTHH:mm:ss');
        }
        catch (e) {
            today = moment();
        }
        let selectedCoveragePeriod: boolean = true;
        coverageRule.find((period) => {
            const startDate = moment.parseZone(period.startDate).format('YYYY-MM-DDTHH:mm:ss');
            const endDate = moment.parseZone(period.endDate).format('YYYY-MM-DDTHH:mm:ss');
            if (today >= startDate && today <= endDate && period.planYear === agentYear && (period.planType === 'ALL' || (period.planType.indexOf(agentCoverageType) != -1)) && (period.role === 'ALL' || (period.role.indexOf(role) != -1)) && (period.state === 'ALL' || (period.state.indexOf(agentState) != -1))) {
                selectedCoveragePeriod = false;
            }
        });

        return selectedCoveragePeriod;
    };
    return (!contentLoaded ? <FullPageLoader /> :
        <div className="agent-plan-selection-summary">
            <div className={'fwc-row'}>
                <a className='global-back-link back' id={'atk-plan-summary'} href={'javascript:void(0)'} onClick={() => navigate(-1)}>
                    Back
                </a>
            </div>

            {loader && <FullPageLoader />}
            <div className="fwc-row">
                <div className="fwc-col-12">

                    <div>
                        <h2 className={'top-sm'} id="agent-plan-selection-summary-heading">{pageContent.labels.PAGE_TITLE}</h2>
                    </div>
                    {/* <div className="content">
                        <p>
                            See{' '}
                            <a data-analytics="importantNoticePlanSummaryIos" id="important-notice-link" href={'javascript:void(0)'} onClick={() => {
                                importantNotice.current && importantNotice.current.scrollIntoView({ behavior: 'smooth' })
                            }}>
                                {pageContent.pageFooter.IMPORTANT_TEXT}
                            </a>{' '}
                            {pageContent.pageHeader.NOTICE_TEXT}
                        </p>
                        {planSummaryHeaderContent &&
                            <div dangerouslySetInnerHTML={{ __html: planSummaryHeaderContent }}></div>
                        }
                    </div> */}

                    <div>
                        {agentPlanTypes.indexOf(PLAN_TYPES_NAMES.MEDICAL) !== -1 && (
                            <AtkPlanSummaryCard
                                selectedSummaryPlan={selectedProduct?.medicalProduct as Contract}
                                applicants={selectedProduct.medicalApplicants}
                                planType={'Medical'}
                                planTypeIcon={'sae-icon-heart-health'}
                                selectedPlanMarketType={selectedPlanMarketType}
                                labels={pageContent}
                                onPlanNameClick={(plan: Contract) => { setPlanForDetails(plan) }}
                            />
                        )}

                        {agentPlanTypes.indexOf(PLAN_TYPES_NAMES.DENTAL) !== -1 && !isWellpoint && (
                            <AtkPlanSummaryCard
                                selectedSummaryPlan={selectedProduct.dentalProduct as Contract}
                                applicants={selectedProduct.dentalApplicants}
                                planType={'Dental'}
                                planTypeIcon={'sae-icon-tooth'}
                                labels={pageContent}
                                onPlanNameClick={(plan: Contract) => { setPlanForDetails(plan) }}
                            />
                        )}

                        {agentPlanTypes.indexOf(PLAN_TYPES_NAMES.DENTAL) !== -1 && !isWellpoint && (
                            <AtkPlanSummaryCard
                                selectedSummaryPlan={selectedProduct.visionProduct as Contract}
                                applicants={selectedProduct.visionApplicants}
                                planType={'Vision'}
                                planTypeIcon={'sae-icon-eye'}
                                labels={pageContent}
                                onPlanNameClick={(plan: Contract) => { setPlanForDetails(plan) }}
                            />
                        )}

                    </div>
                    <div className={'fwc-row j-end'}>
                        {selectedProduct.medicalProduct !== null && <p>
                            <span className="cost-text">
                                Estimated Savings:
                                <span className="cost bold"> {getFormattedCurrency(parseFloat(estimatedTaxCredit))} </span>a month
                            </span>
                        </p>}
                    </div>
                    <div className="agent-pss-total-pricing-container">
                        <a data-analytics="calculatedLinkPlanSummaryIos" href={'javascript:void(0)'} className="calulated-tool-tip" onClick={handleClick('top-end')}>
                            {pageContent.costListing.HOW_IS_CALCULATED_TEXT}
                            {open && anchor && (
                                <Popover className="css-1niekxx-cssPopover" placement={placement} anchorEl={anchor} onClickOutside={togglePopover}>
                                    <Paragraph>
                                        <div className="agent-pss-total-breakdown-container">
                                            <div className="agent-pss-monthly-costbreakdown">
                                                <h6>{pageContent.costListing.MONTHLY_BREAKDOWN_TITLE}</h6>
                                            </div>
                                            <div className="medical-costbreakdown">
                                                <p>{pageContent.costListing.MEDICAL.MEDICAL_TITLE}</p>
                                                <div className="costbreakdown-container">
                                                    <div>
                                                        <p>{pageContent.costListing.MEDICAL.TOTAL_PLAN_COST}</p>
                                                        <p>{pageContent.costListing.MEDICAL.SUBSIDY_APPLIED}</p>
                                                        <p>{pageContent.costListing.MEDICAL.MEDICAL_PAYMENT}</p>
                                                    </div>
                                                    <div>
                                                        <p>{selectedProduct?.medicalProduct ? getFormattedCurrency(selectedProduct?.medicalProduct?.rateData?.rate.totalPremium) : getFormattedCurrency(0)}</p>
                                                        <p className="cost-breakdown-line">{getFormattedCurrency(parseFloat(estimatedTaxCredit))}</p>
                                                        <p>{selectedProduct?.medicalProduct ? getFormattedCurrency(selectedProduct?.medicalProduct?.rateData?.rate.totSubsidizedPrem) : getFormattedCurrency(0)}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            {!isWellpoint && <div className="dental-costbreakdown">
                                                <p>{pageContent.costListing.DENTAL.DENTAL_TITLE}</p>
                                                <div className="costbreakdown-container">
                                                    <div>
                                                        <p>{pageContent.costListing.DENTAL.TOTAL_PLAN_COST}</p>
                                                        <p>{pageContent.costListing.DENTAL.DENTAL_PAYMENT}</p>
                                                    </div>
                                                    <div>
                                                        <p className="cost-breakdown-line">{selectedProduct?.dentalProduct?.rateData?.rate?.totSubsidizedPrem ? getFormattedCurrency(selectedProduct?.dentalProduct?.rateData?.rate?.totSubsidizedPrem) : getFormattedCurrency(0)}</p>
                                                        <p>{selectedProduct?.dentalProduct?.rateData?.rate?.totSubsidizedPrem ? getFormattedCurrency(selectedProduct?.dentalProduct?.rateData?.rate?.totSubsidizedPrem) : getFormattedCurrency(0)}</p>
                                                    </div>
                                                </div>
                                            </div>}
                                            {!isWellpoint && <div className="vision-costbreakdown">
                                                <p>{pageContent.costListing.VISION.VISION_TITLE}</p>
                                                <div className="costbreakdown-container">
                                                    <div>
                                                        <p>{pageContent.costListing.VISION.TOTAL_PLAN_COST}</p>
                                                        <p>{pageContent.costListing.VISION.VISION_PAYMENT}</p>
                                                    </div>
                                                    <div>
                                                        <p className="cost-breakdown-line">{selectedProduct?.visionProduct?.rateData?.rate?.totSubsidizedPrem ? getFormattedCurrency(selectedProduct?.visionProduct?.rateData?.rate?.totSubsidizedPrem) : getFormattedCurrency(0)}</p>
                                                        <p>{selectedProduct?.visionProduct?.rateData?.rate?.totSubsidizedPrem ? getFormattedCurrency(selectedProduct?.visionProduct?.rateData?.rate?.totSubsidizedPrem) : getFormattedCurrency(0)}</p>
                                                    </div>
                                                </div>
                                            </div>}
                                            <div className="total-costbreakdown">
                                                <div className="costbreakdown-container">
                                                    <div>
                                                        <p>{pageContent.costListing.TOTAL_MEMBER_PAYMENT}</p>
                                                    </div>
                                                    <div>
                                                        <p>{getTotalPremium()}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Paragraph>
                                </Popover>
                            )}
                            <span className="cost-text">
                                {pageContent.costListing.TOTAL_COST_TEXT}
                                <span className="cost">{getTotalPremium()} </span>a month
                            </span>
                        </a>
                    </div>
                    <div className="agent-pss-apply-now-container">
                        {handleApplyNow() ? <button data-analytics="applyNowBtnPlanSummaryIos" className={'fwc-btn fwc-btn-primary'} id="apply-now-btn" onClick={handleSaveAgentQuoteAPI}>{applyNowLabel}</button> : <div><b>{pageContent.APPLY_NOW_DESCRIPTION}</b></div>}
                    </div>
                    {/* <div className="footer-container" ref={importantNotice}>
                        <p>{pageContent.pageFooter.IMPORTANT_TEXT}</p>
                        <p>{pageContent.pageFooter.IMPORTANT_CONTENT}</p>
                        <p>{pageContent.pageFooter.ABOUT_COVERED}</p>
                        <p>{pageContent.pageFooter.BENEFIT_INFORMATION}</p>
                    </div> */}
                </div>
            </div>
            {
                showApplyModal && <Modal
                    onClose={closeApplyNowModal}
                    title={applyModalTitle}
                    open={showApplyModal}
                >
                    <Modal.Body>
                        <div className="apply-modal-padding"><span dangerouslySetInnerHTML={{ __html: applyModalDesc }}></span></div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button id='modal-slide-select' className={'fwc-btn fwc-btn-primary'} onClick={closeApplyNowModal}>
                            {pageContent.CLOSE}
                        </button>
                    </Modal.Footer>
                </Modal>
            }
        </div >
    );
};

export default AtkPlanSelectionSummary;
