import axios, { AxiosInstance } from 'axios';
import { API_NAME_LIST } from '../api-name-list';
const axiosInstanceForTempAPI: AxiosInstance = axios.create({
  baseURL: API_NAME_LIST.BASEURL,
  timeout: 80000
});
export const sendDemographicLead = (payload: sendDemographicsLeadPayload) => {
  return axiosInstanceForTempAPI.post(API_NAME_LIST.DEMOGRAPHIC_LEAD, {
    ...payload
  });
};

interface sendDemographicsLeadPayload {
  firstName?: string;
  zipCode?: string;
  email?: string;
  dob?: string;
  state?: string;
  phone?: string;
  phoneType?: string;
  coverageType?: string;
  brand?: string;
  refId?: string;
}
