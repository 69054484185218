import { PageHeader } from '@sydney-broker-ui/ios';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getCMSStaticContent } from '../../../api/cms/cmsUtil';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import GlobalFooter from '../../../components/common/global-footer/GlobalFooter';
import GlobalHeader from '../../../components/common/global-header/GlobalHeader';
import PublicNavBar from '../../../components/common/public-nav-bar/PublicNavBar';
import { useShopperContext } from '../../../context/shopper-context/ShopperContext';
import { LANGUAGE_CODES, SCREEN_NAMES } from '../../../shared/globalConstants';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import ChangePassword from './ChangePassword/ChangePassword';
import UpdatePersonalInformation from './UpdatePersonalInformation/UpdatePersonalInformation';
import './UpdateProfile.scss';
import UpdateSecurityQA from './UpdateSecurityQA/UpdateSecurityQA';
import { default as Content } from './updateProfileMessages.json';

function UpdateProfile() {
  const {
    loggedIn,
    userName,
    userFirstName,
    userLastName,
    userDateOfBirth,
    userEmailAddress,
    userPhoneNumber,
    userPhoneNumberType,
    userSMSConsent,
    webAccountGUID,
    shopperId,
    role
  } = useUserStore((state) => state);

  const [updateProfile, setUpdateProfile] = useState(true);
  const [updateQA, setUpdateQA] = useState(false);
  const [changePwd, setChangePwd] = useState(false);
  const { userPassword } = useShopperContext()
  const location = useLocation();

  useEffect(() => { }, [loggedIn]);

  const [pageContent, setPageContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const { updateUsername, zipCodeRes } = useGlobalStore((state) => state);


  const getContent = useCallback(async (): Promise<boolean> => {
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.UPDATEPROFILE);
      let cmsResponse = response.data;
      setPageContent(cmsResponse.data.iospagecontentList.items[0].pageContent?.updateProfile);
    } catch (error) {
      setPageContent(Content?.data.iospagecontentList.items[0].pageContent?.updateProfile);

      return false;
    }
    finally {
      setContentLoaded(true);
    }
    return true;
  }, []);

  useEffect(() => {
    initiate();
  }, []);

  const initiate = async () => {
    await getContent();
  };

  function onChangeupdateProfile() {
    setUpdateProfile(true);
    setUpdateQA(false);
    setChangePwd(false);
  }

  function onChangeupdateQA() {
    setUpdateQA(true);
    setUpdateProfile(false);
    setChangePwd(false);
  }

  const onClickChangePassword = () => {
    setChangePwd(true);
    setUpdateProfile(false);
    setUpdateQA(false);
  };
  const createLogo = () => {
    const firstLetter = userFirstName?.charAt(0).toUpperCase();
    const secondLetter = userLastName?.charAt(0).toUpperCase();
    return firstLetter + secondLetter;
  };

  return (!contentLoaded ? <FullPageLoader /> :
    <div className={'profile-container'}>
      <GlobalHeader notClickable={location.state?.inProgress} hideProfile={true} />
      <PageHeader>
        <PublicNavBar isEmbed={true} />
      </PageHeader>
      <div className={'fwc-container'}>
        <div className={'fwc-row j-center'}>
          <div className={'fwc-col-9 md-12 sm-12'}>
            <div className={'fwc-flex j-center'}>
              <div className={'user-logo'}>{createLogo()}</div>
            </div>
            <div className={'username'}>
              {userFirstName} {userLastName}
            </div>
            <div className={'update'}>
              <a className={updateProfile ? 'active' : ''} data-analytics="updateProfileTabProfile" id={'update-profile'} href={'javascript:void(0)'} onClick={onChangeupdateProfile}>
                {pageContent?.UPDATE_PROFILE}
              </a>
              <a className={updateQA ? 'active' : ''} data-analytics="updateSecurityQasTabProfile" id={'update-security'} href={'javascript:void(0)'} onClick={onChangeupdateQA}>
                {pageContent?.UPDATE_SECURITY_QAS}
              </a>
              <a className={changePwd ? 'active' : ''} data-analytics="changePasswordTabProfile" id={'update-password'} href={'javascript:void(0)'} onClick={onClickChangePassword}>
                {pageContent?.CHANGE_PASSWORD}
              </a>
            </div>
            {updateProfile && (
              <UpdatePersonalInformation
                userName={userName}
                userFirstName={userFirstName}
                userLastName={userLastName}
                userDateOfBirth={userDateOfBirth}
                userEmailAddress={userEmailAddress}
                userPhoneNumber={userPhoneNumber}
                userPhoneNumberType={userPhoneNumberType}
                userSMSConsent={userSMSConsent}
                webAccountGUID={webAccountGUID}
                shopperId={shopperId}
                role={role}
              />
            )}
            {updateQA && (
              <UpdateSecurityQA />
            )}

            {changePwd && <ChangePassword userName={userName} password={userPassword} webAccountGUID={webAccountGUID} />}
          </div>
        </div>
      </div>
      <GlobalFooter />
    </div>
  );
}

export default UpdateProfile;

