import { Button, Modal } from '@sydney-broker-ui/ios';
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { getCMSStaticContent } from '../../../api/cms/cmsUtil';
import { MEDICAL_PLANS_TABS } from '../../../pages/public/medical-plans/constants';
import { LANGUAGE_CODES, SCREEN_NAMES } from '../../../shared/globalConstants';
import { useGlobalStore } from '../../../store/globalStore';
import FullPageLoader from '../full-page-loader/FullPageLoader';
import './HelpMeChoosePopup.scss';
import { default as Content } from './helpMeChoosePopup.json';

const HelpMeChoosePopup: React.FC<HelpMeChoosePopupProps> = ({ showPopup, popupClose }) => {
  const { brand, zipCodeRes,
    updateEstimateSavingsResponse, selectedTab, updateHouseholdMemberValues, subsidyresponse, resetHmcFilter, resetMedicalPlanStates } = useGlobalStore((store) => store);
  const [pageContent, setPageContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const getContent = useCallback(async (): Promise<boolean> => {
    let content: any;
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.HELP_ME_CHOOSE_POPUP);
      content = response.data.data.iospagecontentList.items[0].pageContent?.helpmechoosepopup;
    } catch (error) {
      content = Content.data.iospagecontentList.items[0].pageContent?.helpmechoosepopup;
      return false;
    } finally {
      setPageContent(content);
      setContentLoaded(true);
    }
    return true;
  }, []);

  const initiate = async () => {
    await getContent();
  };

  useEffect(() => {
    initiate();
  }, []);

  const navigate = useNavigate();

  const handleYesClick = () => {
    if (selectedTab !== MEDICAL_PLANS_TABS.MARKET_PLANS) {
      if (subsidyresponse.subsidyCalc?.[0]?.subsidyAmount) {
        updateEstimateSavingsResponse({});
        updateHouseholdMemberValues({});
      }
    }
    popupClose();
    setTimeout(() => {
      navigate('/individuals/shop/basics/help-me-choose');
    }, 0)

  }

  return (!contentLoaded ? <FullPageLoader /> :
    <div className='helpchoose-popup-wraper'>
      <Modal open={showPopup} onClose={() => popupClose()} title={pageContent?.HELPME_CHOOSE_POPUP_TITLE}>
        <Modal.Body className="popup-body">
          <div id="popup-model">
            <div className="popup-container" id="popup-container">
              <p id="popup-content">{pageContent?.HELPME_CHOOSE_POPUP_CONTENT}</p>
            </div>
            <Modal.Footer>
              <div className="popup-buttons" id="popup-buttons-wrapper">
                <Button btnType="secondary" data-analytics="noBtnHelpChooseModalIos" id="view-all-plans" onClick={() => {
                  resetHmcFilter();
                  resetMedicalPlanStates();
                  popupClose();
                  //commenting below code for now as it is not required
                  // if (selectedTab !== MEDICAL_PLANS_TABS.MARKET_PLANS) {
                  // if (subsidyresponse.subsidyCalc?.[0]?.subsidyAmount) {
                  //   updateEstimateSavingsResponse({});
                  //   updateHouseholdMemberValues({});
                  // }
                  // }
                  navigate('/individuals/shop/basics/medical-plans')
                }}>
                  {pageContent?.NO_VIEW_ALL_PLANS_BUTTON}
                </Button>
                <Button btnType="primary" data-analytics="yesBtnHelpChooseModalIos" id="yes-view-plans" onClick={handleYesClick}>
                  {pageContent?.YES_HELP_ME_CHOOSE_BUTTON}
                </Button>
              </div>
            </Modal.Footer>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default HelpMeChoosePopup;
