import { ModalSlide, Paragraph, Popover, PreLoader } from "@sydney-broker-ui/ios";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { getCMSStaticContent } from "../../../api/cms/cmsUtil";
import { COMPARE_PLANS, LANGUAGE_CODES, MARKET_TAGS, SCREEN_NAMES, STATE_CODES, USER_ROLES } from "../../../shared/globalConstants";
import { getFormattedCurrency } from "../../../shared/utils/globalUtils";
import { useGlobalStore } from "../../../store/globalStore";
import { useUserStore } from "../../../store/userStore";
import EmailPrint from "../email-print/EmailPrint";
import MatchingListCard from "../matching-list-card/MatchingListCard";
import { getMedicationNames } from "../plan-card/planCardUtils";
import './CompareSlider.scss';
import { default as Content } from "./compareSlider.json";
interface CompareSliderProps {
    plans: any;
    showSlider: boolean;
    planHeaders: any;
    labels?: any;
    onClose?: (showSlider: boolean) => void;
    planType: string;
    matchedProvidersFlag?: boolean;
    matchedMedicationsFlag?: boolean;
}

function CompareSlider(props: CompareSliderProps) {
    const [pageContent, setContent] = useState<any>(null);
    const [contentLoaded, setContentLoaded] = useState(false);
    const [showSlider, setShowSlider] = useState(false)
    const [anchor, setAnchor] = useState<React.SetStateAction<null> | (EventTarget & HTMLSpanElement)>(null);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [emailContracts, setEmailContracts] = useState<Contracts>([]);
    const [activePopover, setActivePopover] = useState({ totalPremium: 0, totSubsidizedPrem: 0, subsidyAmtApplied: 0 });
    const { state, currentCoverageMedicalPlan, currentCoverageDentalPlan, currentCoverageVisionPlan } = useGlobalStore((store) => store);
    const { role } = useUserStore((state) => state);

    const impRef = useRef<any>(null);
    const getContent = useCallback(async (): Promise<boolean> => {
        try {
            const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.COMPARE_SLIDER);
            let cmsResponse = response.data;

            setContent(cmsResponse.data.iospagecontentList.items[0].pageContent.COMPARESLIDER);
        } catch (error) {
            setContent(Content.data.iospagecontentList.items[0].pageContent.COMPARESLIDER);

            return false;
        } finally {
            setContentLoaded(true);
        }
        return true;
    }, []);

    const initiate = async () => {
        await getContent();
    };

    const handleSliderClose = () => {
        setShowSlider(false);
        props.onClose?.(false);
    }
    useEffect(() => {
        let contractArr: Contracts = []
        initiate();
        setShowSlider(props.showSlider);
        props?.plans?.forEach(el => {
            contractArr.push(el.contract);
        });
        if (role === USER_ROLES.MEMBER) {
            switch (props.planType) {
                case "medical":
                    if (Object.keys(currentCoverageMedicalPlan).length > 0) {
                        contractArr = [currentCoverageMedicalPlan, ...contractArr];
                    }
                    break;
                case "dental":
                    if (Object.keys(currentCoverageDentalPlan).length > 0) {
                        contractArr = [currentCoverageDentalPlan, ...contractArr];
                    }
                    break;
                case "vision":
                    if (Object.keys(currentCoverageVisionPlan).length > 0) {
                        contractArr = [currentCoverageVisionPlan, ...contractArr];
                    }
                    break;
            }
        }
        setEmailContracts(contractArr);
    }, [props.showSlider]);


    const formattedPremium = (premium) => {
        const int = Math.floor(premium);
        let dec = (premium - int).toFixed(2).substring(1);
        dec = dec.split('.')[1].substring(0, 2);
        return (
            <span>{int}.<sup>{dec}</sup></span>
        )
    }
    const handleHelpClick = (rateObj: { totalPremium: number; totSubsidizedPrem: number; subsidyAmtApplied: number }, currentTarget: React.SetStateAction<null> | (EventTarget & HTMLAnchorElement)) => {
        setActivePopover(rateObj);
        setAnchor(currentTarget);
        setPopoverOpen(!popoverOpen);
    };
    const placeholders = () => {
        return (
            <>
                {(props.plans.length === 1 || props.plans.length === 2) &&
                    <div className="compare-tray-plan placeholder-plan fwc-col-4 fwc-card-generic"></div>}
                {props.plans.length === 1 &&
                    <div className="compare-tray-plan placeholder-plan fwc-col-4 fwc-card-generic"></div>}
            </>
        )
    }
    const popoverContent = (rateObj: { totalPremium: number; totSubsidizedPrem: number; subsidyAmtApplied: number }) => {
        return (
            <Paragraph>
                <div className="popover-container">
                    <span className="label-amount-container-margin-btm">
                        <b>{props.labels?.monthlyCostBreakdown}</b>
                    </span>
                    <div className="amount-label-container">
                        <p>{props.labels?.totalPlanCost}</p>
                        <span>
                            <b>{getFormattedCurrency(activePopover?.totalPremium)}</b>
                        </span>
                    </div>
                    <div className="amount-label-container label-amount-container-margin-btm">
                        <p>{props.labels?.subsidyApplied}</p>
                        <span>
                            <b>{getFormattedCurrency(activePopover?.subsidyAmtApplied)}</b>
                        </span>
                    </div>
                    <div className="amount-label-container">
                        <span className="label-margin-right">
                            <b>{props.labels?.totalMemberResponsibility}</b>
                        </span>
                        <span className="total-subsidised-amount">
                            <b>{getFormattedCurrency(activePopover?.totSubsidizedPrem)}</b>
                        </span>
                    </div>
                </div>
            </Paragraph>
        );
    };

    const findPlanBenefit = (code, plan) => {
        const benefit = plan.contract.plan[0].planBenefits.find(benefit => benefit.planBenefitCode === code);
        return benefit?.planBenefitValue;
    };

    return (
        <>
            {!contentLoaded ? (<PreLoader id={'compare-slider-loader'} />) : (
                <div className="compare-slider-container wide">
                    <ModalSlide wide={true} direction={'right'} open={showSlider} onClose={handleSliderClose}>
                        <ModalSlide.Body>
                            <div className={'slider-container'}>
                                <div className="fwc-flex fwc-row-wrap">
                                    <div className="fwc-col-12 sm-12 top-xs compare-header-flex">
                                        <h2 className="btm-xxs title">
                                            <span className="sae-icon sae-icon-compare right-xxs"></span>
                                            {pageContent?.HEADER}
                                        </h2>
                                        <div>
                                            <EmailPrint emailPrintContracts={emailContracts} type={COMPARE_PLANS} />
                                        </div>
                                    </div>
                                </div>
                                <div className='fwc-flex info-div-align fwc-row-bm'>
                                    <span className='sae-icon sae-icon-info info-icon-size right-xxs'></span>
                                    <p id='see'>{pageContent?.SEE} <span id={'important-link'} className='important-link' onClick={() => impRef.current.scrollIntoView()}> {pageContent?.IMPORTANT} </span>{pageContent?.NOTICE_ABOUT}</p>
                                </div>
                                <div className={'fwc-flex btm-xs top-sm market-container'}>
                                    <div className={'tag-container'}>
                                        <div className={'market-tag M'}>{MARKET_TAGS.MARKET}</div>
                                        {pageContent?.KEY.MARKET}
                                    </div>
                                    <div className={'tag-container'}>
                                        <div className={'market-tag OM'}>{MARKET_TAGS.OFF_MARKET}</div>
                                        {pageContent?.KEY.OFF_MARKET}
                                    </div>
                                    {state === STATE_CODES.CO && <div className={'tag-container'}>
                                        <div className={'market-tag CC'}>{MARKET_TAGS.COLORADO}</div>
                                        {pageContent?.KEY.COLORADO}
                                    </div>
                                    }
                                </div>
                                <div className="compare-slider-content top-xs btm-sm">
                                    {props.planHeaders}
                                    <div className={'cs-row-header'}>{pageContent?.TOTAL_MONTHLY_COST}</div>
                                    <div className={'cs-row'}>
                                        {props.plans?.map((plan: any) => (
                                            <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat fwc-col-4'}>
                                                <div className={'cs-card-header'}>
                                                    {getFormattedCurrency(plan?.contract?.rateData?.rate?.totSubsidizedPrem)}
                                                    <span className={'cs-desc'}> {pageContent?.A_MONTH}</span>
                                                </div>
                                                <div className={'top-xxs'}>
                                                    <div className={'popover-wrapper'}>
                                                        {popoverOpen && anchor && (
                                                            <Popover id="plan-card-plan-amount-popover"
                                                                placement={'top'}
                                                                anchorEl={anchor}
                                                                onClickOutside={() => setPopoverOpen(!popoverOpen)}>
                                                                {popoverContent(plan?.contract?.rateData?.rate)}
                                                            </Popover>
                                                        )}
                                                    </div>
                                                    <a onClick={(e) => handleHelpClick(plan?.contract?.rateData?.rate, e.currentTarget)}>{pageContent?.HOW_CALCULATED}</a>
                                                </div>
                                            </div>
                                        ))}
                                        {placeholders()}
                                    </div>
                                    {props.matchedProvidersFlag &&
                                        <>
                                            <div className={'cs-row-header'}>{pageContent?.YOUR_DOCTORS}</div>
                                            <div className={'cs-row'}>
                                                {props.plans?.map((plan: any) => (
                                                    <div key={plan.contract.contractCode} className={'cs-card fwc-col-4'}>
                                                        <MatchingListCard
                                                            icon={'sae-icon-doctors-bag'}
                                                            label={props.labels.doctorsListLabel}
                                                            showMoreText={`${props.labels.showMore} ${props.labels.doctors}`}
                                                            showLessText={`${props.labels.showLess} ${props.labels.doctors}`}
                                                            providers={plan.matchedProviders}
                                                            tooltipMessage={props.labels.matchedDoctorsTooltipText}
                                                            isCompare={true}
                                                        />
                                                    </div>
                                                ))}
                                                {placeholders()}
                                            </div>
                                        </>}
                                    {props.matchedMedicationsFlag &&
                                        <>
                                            <div className={'cs-row-header'}>{pageContent?.YOUR_MEDICATIONS}</div>
                                            <div className={'cs-row'}>
                                                {props.plans?.map((plan: any) => (
                                                    <div key={plan.contract.contractCode} className={'cs-card fwc-col-4'}>
                                                        <MatchingListCard
                                                            icon={'sae-icon-prescription-pill-bottle'}
                                                            label={props.labels.medicationsListLabel}
                                                            showMoreText={`${props.labels.showMore} ${props.labels.medications}`}
                                                            showLessText={`${props.labels.showLess} ${props.labels.medications}`}
                                                            data={getMedicationNames(plan.matchedMedications)}
                                                            impNoticeText={props.labels.importantDrugsNotice}
                                                            isCompare={true}
                                                        />
                                                    </div>
                                                ))}
                                                {placeholders()}
                                            </div>
                                        </>
                                    }
                                    {props.plans[0].contract.plan[0].planBenefits.map((benefit: any) => (
                                        <>
                                            <div className={'cs-row-header'}>{benefit.planBenefitDisplayName}</div>
                                            <div className={'cs-row'}>
                                                {props.plans?.map((plan: any) => (
                                                    <div key={plan.contractCode} className={'cs-card fwc-card-generic flat fwc-col-4'}>
                                                        <p className={'cs-card-paragraph'}>
                                                            <span dangerouslySetInnerHTML={{ __html: findPlanBenefit(benefit.planBenefitCode, plan) }}></span>
                                                        </p>
                                                    </div>
                                                ))}
                                                {placeholders()}
                                            </div>
                                        </>
                                    ))}
                                </div>
                                <div id="important-link-div" ref={impRef} className='fwc-row-bm'>
                                    <h6 id='important'>{pageContent?.IMPORTANT}</h6>
                                    <div id='important-1' className='fwc-row fwc-row-bm'>
                                        {pageContent?.IMPORTANT_1}
                                    </div>
                                    <div id='important-2' className='fwc-row fwc-row-bm'>
                                        {pageContent?.IMPORTANT_2}
                                    </div>
                                    <div id='important-3' className='fwc-row fwc-row-bm'>
                                        {pageContent?.IMPORTANT_3}
                                    </div>
                                    <div id='important-4' className='fwc-row fwc-row-bm'>
                                        {pageContent?.IMPORTANT_4}
                                    </div>
                                </div>
                            </div>
                        </ModalSlide.Body>
                        <ModalSlide.Footer>
                            <button className={'fwc-btn fwc-btn-secondary'} onClick={handleSliderClose}>{pageContent?.BUTTONS.CLOSE}</button>
                        </ModalSlide.Footer>
                    </ModalSlide>
                </div>
            )}
        </>
    );

}
export default CompareSlider;
