const { REACT_APP_PUBLIC_URI, REACT_APP_ENV } = process.env;

export const API_NAME_LIST =
  REACT_APP_ENV === 'local'
    ? {
        BASEURL: REACT_APP_ENV === 'local' ? REACT_APP_PUBLIC_URI : '/',
        LOGIN: '/individuals/public/api/userprofile/api/v2/userLogin',
        VALIDATE_ZIPCODE: '/individuals/public/api/coverage/api/utility/v2/validateZipcode',
        CONTACT_US: '/individuals/public/api/enrollment/api/v2/callMe',
        LOGIN_SEND_OTP: '/individuals/public/api/userprofile/api/v2/sendOTP',
        LOGIN_VALIDATE_OTP: '/individuals/public/api/userprofile/api/v2/validateOTP',
        REGISTER_SHOPPER: '/individuals/public/api/userprofile/api/v3/registerShopper',
        REGISTER_SEND_OTP: '/individuals/public/api/userprofile/api/v3/sendOTP',
        VALIDATE_OTP_AND_REGISTER: '/individuals/public/api/userprofile/api/v3/validateOtpAndRegister',
        FORGOT_USERNAME: '/individuals/public/api/userprofile/api/v2/forgotUserName',
        FORGOT_PASSWORD: '/individuals/public/api/userprofile/api/v2/forgotPassword',
        VALIDATERESETPWDID: '/individuals/public/api/userprofile/api/v2/validateResetPwdId',
        GET_USER: '/individuals/public/api/userprofile/api/v2/checkUserExist',
        FORGOT_PWD_SEND_OTP: '/individuals/public/api/userprofile/api/v2/forgotPwd/sendOTP',
        FORGOT_PWD_VALIDATEOTP: '/individuals/public/api/userprofile/api/v2/forgotPwd/validateOTP',
        CHANGE_PASSWORD: '/individuals/public/api/userprofile/api/v2/forgotPwd/changePassword',
        FORGOT_USERNAME_SEND_OTP: '/individuals/public/api/userprofile/api/v2/forgotUsrnm/sendOTP',
        VALIDATE_OTP_EMAIL_USERNAME: '/individuals/public/api/userprofile/api/v2/emailUserName',
        PROVIDER_SEARCH: '/individuals/public/api/provider/api/v1/getProviderSearch',
        GET_DRUGLIST: '/individuals/public/api/formulary/api/v2/getDrugList',
        GET_GENERICDRUGLIST: '/individuals/public/api/formulary/api/v1/getDrugDetails',
        GET_MEDICAL_PLANS: '/individuals/public/api/quote/api/v2/getPlanSummary',
        SUBSIDY_CALCULATOR: '/individuals/public/api/quote/api/v2/subsidy/calc',
        PROVIDER_DETAILS: '/individuals/public/api/provider/api/v1/getProviderDetails',
        GET_DRUGS_PLAN_SUMMARY: '/individuals/public/api/formulary/api/v2/getRxPlanSummary',
        UPDATE_PERSONAL_INFORMATION: '/individuals/public/api/userprofile/api/v2/updateShopper', //secure
        CHANGE_PROFILE_PASSWORD: '/individuals/public/api/userprofile/api/v2/changePassword', //secure
        GENERATE_TOKEN: '/individuals/secure/api/coverage/api/secure/generatetoken', //secure
        REFRESH_TOKEN: '/individuals/secure/api/coverage/api/secure/refreshtoken', //secure
        MEMBER_PHI_LOGIN: '/individuals/public/api/userprofile/api/v3/memberPHILogin',
        SAVE_DEMOGRAPHIC_INFO: '/individuals/public/api/userprofile/api/v1/saveDemographic', //secure
        SAVE_QUOTE: '/individuals/public/api/userprofile/api/v1/saveQuote', //secure
        SAVE_NAVIGATION: '/individuals/public/api/userprofile/api/v1/saveNavigation', //secure
        SAVE_DEMOGRAPHIC_AND_QUOTE: '/individuals/public/api/userprofile/api/v1/saveDemographicsAndQuote', //secure
        GET_QUOTE: '/individuals/public/api/userprofile/api/v1/getQuote', //secure
        GET_NAVIGATION: '/individuals/public/api/userprofile/api/v1/getNavigation', //secure
        GET_DEMOGRAPHIC: '/individuals/public/api/userprofile/api/v1/getDemographic', //secure
        GET_BUILD_VERSION: '/individuals/secure/api/coverage/api/v1/getBuildVersion',
        GET_APPLICATION_LISTINGS: '/individuals/public/api/enrollment/api/v2/getApplicationListings', //secure
        MEMBER_INFO: '/individuals/public/api/userprofile/api/v1/getMemberInfo', //secure
        GET_PLAN_DETAILS: '/individuals/public/api/quote/api/v2/planDetails',
        EMAIL_PLANS: '/individuals/public/api/coverage/api/utility/v2/emailPlan',
        PRINT_PLANS: '/individuals/public/api/coverage/api/utility/v2/printPlan',
        ALPHA_PREFIX: '/individuals/public/api/provider/api/v1/getFindcareParams',
        CANCEL_APPLICATION: '/individuals/public/api/enrollment/api/v2/cancelApplication', //secure
        VISION_RECOMMENDATION: '/individuals/public/api/quote/api/v2/visionRecommendation',
        GET_AGENT_QUOTE: '/individuals/public/api/userprofile/api/v1/getAgentQuote/', //secure
        VALIDATE_PIN_AND_DOB_BY_QUOTEID: '/individuals/public/api/userprofile/api/v1/validatePinAndDobByQuoteId', //secure
        GUEST_USER_LOGIN: '/individuals/public/api/userprofile/api/v3/guestUserLogin',
        SET_APPLICATION: '/individuals/public/api/enrollment/api/v2/setApplication', //secure
        ENCRYPT_ACN: '/individuals/public/api/ede/api/v1/encryptACN',
        VALIDATE_PIN_AND_DOB_BY_ACN: '/individuals/public/api/enrollment/api/v2/validatePinAndDateOfBirthByAcn', //secure
        TRANSFER_APPLICATION_BETWEEN_AGENT_AND_CONSUMER: '/individuals/public/api/enrollment/api/v2/transferApplicationBetweenAgentAndConsumer', //secure
        MEMBER_SSO: '/individuals/public/api/userprofile/api/v3/memberSSOLogin', //secure
        UPDATE_QUOTE_STATUS: '/individuals/public/api/userprofile/api/v1/updateQuoteStatus', //secure
        GET_PROVIDER_LIST_BY_ID: '/individuals/public/api/provider/api/v1/getProviderListById',
        GET_EVENDOR_INFO: '/individuals/public/api/transactionlog/api/v1/getEVendorInfo/',
        AGENT_SSO_INFO: '/individuals/public/api/transactionlog/api/v1/agent/getAgentSSOInfo/',
        GENERATE_PDF: '/individuals/public/api/enrollment/api/v2/generatePDF',
        GET_PROPOSAL_ID: '/individuals/public/api/userprofile/api/v1/getProposal',
        GET_APPLICATION: '/individuals/public/api/enrollment/api/v2/getApplication', //secure
        SUBMIT_APPLICATION: '/individuals/public/api/enrollment/api/v2/submitApplication', //secure
        VOICE_VAULT_LETTER: '/individuals/public/api/enrollment/api/v2/generateVoiceVaultLetter', //secure
        SAVE_AGENT_QUOTE: '/individuals/public/api/userprofile/api/v1/saveAgentQuote',
        ATK_SEND_PROPOSAL: '/individuals/public/api/userprofile/api/v1/sendProposal',
        AGENT_AUTHENTICATION: '/individuals/public/api/userprofile/api/v2/authenticateAgent', //secure
        ATK_SAVE_PROPOSAL: '/individuals/public/api/userprofile/api/v1/saveProposal',
        ATK_PRINT: '/individuals/public/api/coverage/api/utility/v2/atkPrint',
        PUBLIC_ATK_PRINT: '/individuals/public/api/coverage/api/utility/v2/atkPrint',
        ATK_PROPOSAL_PLAN_INFO: '/individuals/public/api/userprofile/api/v1/getProposalPlanInfo',
        SAVE_SHOPPER_AGENT_CONNECT: '/individuals/public/api/userprofile/api/v1/saveShopperAgentConnect', //secure,
        DELETE_SHOPPER_AGENT_CONNECT: '/individuals/public/api/userprofile/api/v1/deleteShopperAgentConnect/', //secure
        DELETE_ATK_PROPOSAL: '/individuals/public/api/userprofile/api/v1/deleteProposal/', //secure
        GET_QUOTE_BY_DEMOGRAPHICS: '/individuals/public/api/userprofile/api/v1/getQuoteByDemographic?demographicId=',
        VALIDATE_MARKETINGID: '/individuals/public/api/userprofile/api/v2/validateMarketingRefId',
        CHECK_APPLICATION_STATUS: '/individuals/public/api/enrollment/api/v2/getApplicationStatus',
        SITE_METRICS: '/individuals/public/api/transactionlog/api/v1/setSitemetrics',
        DEMOGRAPHIC_LEAD: '/individuals/public/api/enrollment/api/v2/sendDemographicLead'
      }
    : {
        BASEURL: REACT_APP_ENV === 'local' ? REACT_APP_PUBLIC_URI : '/',
        LOGIN: '/individuals/public/api/userprofile/api/v2/userLogin',
        VALIDATE_ZIPCODE: '/individuals/public/api/coverage/api/utility/v2/validateZipcode',
        CONTACT_US: '/individuals/public/api/enrollment/api/v2/callMe',
        LOGIN_SEND_OTP: '/individuals/public/api/userprofile/api/v2/sendOTP',
        LOGIN_VALIDATE_OTP: '/individuals/public/api/userprofile/api/v2/validateOTP',
        REGISTER_SHOPPER: '/individuals/public/api/userprofile/api/v3/registerShopper',
        REGISTER_SEND_OTP: '/individuals/public/api/userprofile/api/v3/sendOTP',
        VALIDATE_OTP_AND_REGISTER: '/individuals/public/api/userprofile/api/v3/validateOtpAndRegister',
        FORGOT_USERNAME: '/individuals/public/api/userprofile/api/v2/forgotUserName',
        FORGOT_PASSWORD: '/individuals/public/api/userprofile/api/v2/forgotPassword',
        VALIDATERESETPWDID: '/individuals/public/api/userprofile/api/v2/validateResetPwdId',
        GET_USER: '/individuals/public/api/userprofile/api/v2/checkUserExist',
        FORGOT_PWD_SEND_OTP: '/individuals/public/api/userprofile/api/v2/forgotPwd/sendOTP',
        FORGOT_PWD_VALIDATEOTP: '/individuals/public/api/userprofile/api/v2/forgotPwd/validateOTP',
        CHANGE_PASSWORD: '/individuals/public/api/userprofile/api/v2/forgotPwd/changePassword',
        FORGOT_USERNAME_SEND_OTP: '/individuals/public/api/userprofile/api/v2/forgotUsrnm/sendOTP',
        VALIDATE_OTP_EMAIL_USERNAME: '/individuals/public/api/userprofile/api/v2/emailUserName',
        PROVIDER_SEARCH: '/individuals/public/api/provider/api/v1/getProviderSearch',
        GET_DRUGLIST: '/individuals/public/api/formulary/api/v2/getDrugList',
        GET_GENERICDRUGLIST: '/individuals/public/api/formulary/api/v2/getDrugDetails',
        GET_MEDICAL_PLANS: '/individuals/public/api/quote/api/v2/getPlanSummary',
        SUBSIDY_CALCULATOR: '/individuals/public/api/quote/api/v2/subsidy/calc',
        PROVIDER_DETAILS: '/individuals/public/api/provider/api/v1/getProviderDetails',
        GET_DRUGS_PLAN_SUMMARY: '/individuals/public/api/formulary/api/v2/getRxPlanSummary',
        UPDATE_PERSONAL_INFORMATION: '/individuals/secure/api/userprofile/api/v2/updateShopper', //secure
        CHANGE_PROFILE_PASSWORD: '/individuals/secure/api/userprofile/api/v2/changePassword', //secure
        GENERATE_TOKEN: '/individuals/secure/api/coverage/api/secure/generatetoken', //secure
        REFRESH_TOKEN: '/individuals/secure/api/coverage/api/secure/refreshtoken', //secure
        MEMBER_PHI_LOGIN: '/individuals/public/api/userprofile/api/v3/memberPHILogin',
        SAVE_DEMOGRAPHIC_INFO: '/individuals/secure/api/userprofile/api/v1/saveDemographic', //secure
        SAVE_QUOTE: '/individuals/secure/api/userprofile/api/v1/saveQuote', //secure
        SAVE_NAVIGATION: '/individuals/secure/api/userprofile/api/v1/saveNavigation', //secure
        SAVE_DEMOGRAPHIC_AND_QUOTE: '/individuals/secure/api/userprofile/api/v1/saveDemographicsAndQuote', //secure
        GET_QUOTE: '/individuals/secure/api/userprofile/api/v1/getQuote', //secure
        GET_NAVIGATION: '/individuals/secure/api/userprofile/api/v1/getNavigation', //secure
        GET_DEMOGRAPHIC: '/individuals/secure/api/userprofile/api/v1/getDemographic', //secure
        GET_BUILD_VERSION: '/individuals/secure/api/coverage/api/v1/getBuildVersion',
        GET_APPLICATION_LISTINGS: '/individuals/secure/api/enrollment/api/v2/getApplicationListings', //secure
        MEMBER_INFO: '/individuals/secure/api/userprofile/api/v1/getMemberInfo', //secure
        GET_PLAN_DETAILS: '/individuals/public/api/quote/api/v2/planDetails',
        EMAIL_PLANS: '/individuals/public/api/coverage/api/utility/v2/emailPlan',
        PRINT_PLANS: '/individuals/public/api/coverage/api/utility/v2/printPlan',
        ALPHA_PREFIX: '/individuals/public/api/provider/api/v1/getFindcareParams',
        CANCEL_APPLICATION: '/individuals/secure/api/enrollment/api/v2/cancelApplication', //secure
        VISION_RECOMMENDATION: '/individuals/public/api/quote/api/v2/visionRecommendation',
        GET_AGENT_QUOTE: '/individuals/secure/api/userprofile/api/v1/getAgentQuote/', //secure
        VALIDATE_PIN_AND_DOB_BY_QUOTEID: '/individuals/secure/api/userprofile/api/v1/validatePinAndDobByQuoteId', //secure
        GUEST_USER_LOGIN: '/individuals/public/api/userprofile/api/v3/guestUserLogin',
        SET_APPLICATION: '/individuals/secure/api/enrollment/api/v2/setApplication', //secure
        ENCRYPT_ACN: '/individuals/public/api/ede/api/v1/encryptACN',
        VALIDATE_PIN_AND_DOB_BY_ACN: '/individuals/secure/api/enrollment/api/v2/validatePinAndDateOfBirthByAcn', //secure
        TRANSFER_APPLICATION_BETWEEN_AGENT_AND_CONSUMER: '/individuals/secure/api/enrollment/api/v2/transferApplicationBetweenAgentAndConsumer', //secure
        MEMBER_SSO: '/individuals/secure/api/userprofile/api/v3/memberSSOLogin', //secure
        UPDATE_QUOTE_STATUS: '/individuals/secure/api/userprofile/api/v1/updateQuoteStatus', //secure
        GET_PROVIDER_LIST_BY_ID: '/individuals/public/api/provider/api/v1/getProviderListById',
        GET_EVENDOR_INFO: '/individuals/public/api/transactionlog/api/v1/getEVendorInfo/',
        AGENT_SSO_INFO: '/individuals/secure/api/transactionlog/api/v1/agent/getAgentSSOInfo/',
        GENERATE_PDF: '/individuals/secure/api/enrollment/api/v2/generatePDF',
        GET_PROPOSAL_ID: '/individuals/secure/api/userprofile/api/v1/getProposal',
        GET_APPLICATION: '/individuals/secure/api/enrollment/api/v2/getApplication', //secure
        SUBMIT_APPLICATION: '/individuals/secure/api/enrollment/api/v2/submitApplication', //secure
        VOICE_VAULT_LETTER: '/individuals/secure/api/enrollment/api/v2/generateVoiceVaultLetter', //secure
        SAVE_AGENT_QUOTE: '/individuals/secure/api/userprofile/api/v1/saveAgentQuote',
        ATK_SEND_PROPOSAL: '/individuals/secure/api/userprofile/api/v1/sendProposal', // secure
        AGENT_AUTHENTICATION: '/individuals/secure/api/userprofile/api/v2/authenticateAgent', //secure
        ATK_SAVE_PROPOSAL: '/individuals/secure/api/userprofile/api/v1/saveProposal', // secure
        ATK_PRINT: '/individuals/secure/api/coverage/api/utility/v2/atkPrint',
        PUBLIC_ATK_PRINT: '/individuals/public/api/coverage/api/utility/v2/atkPrint',
        ATK_PROPOSAL_PLAN_INFO: '/individuals/public/api/userprofile/api/v1/getProposalPlanInfo',
        SAVE_SHOPPER_AGENT_CONNECT: '/individuals/secure/api/userprofile/api/v1/saveShopperAgentConnect', //secure,
        DELETE_SHOPPER_AGENT_CONNECT: '/individuals/secure/api/userprofile/api/v1/deleteShopperAgentConnect/', //secure
        DELETE_ATK_PROPOSAL: '/individuals/secure/api/userprofile/api/v1/deleteProposal/', //secure
        GET_QUOTE_BY_DEMOGRAPHICS: '/individuals/secure/api/userprofile/api/v1/getQuoteByDemographic?demographicId=',
        VALIDATE_MARKETINGID: '/individuals/secure/api/userprofile/api/v2/validateMarketingRefId',
        CHECK_APPLICATION_STATUS: '/individuals/public/api/enrollment/api/v2/getApplicationStatus',
        SITE_METRICS: '/individuals/public/api/transactionlog/api/v1/setSitemetrics',
        DEMOGRAPHIC_LEAD: '/individuals/public/api/enrollment/api/v2/sendDemographicLead'
      };

export const OFF_EXCHANGE_BASE_URLS = {
  dev1: 'https://shop.dev1.va.anthem.com',
  development: 'https://shop.dev1.va.anthem.com',
  local: 'https://shop.dev1.va.anthem.com',
  perf1: 'https://shop.perf1.va.anthem.com',
  prod1: 'https://shop.anthem.com',
  prod2: 'https://shop.anthem.com',
  sit1: 'https://shop.sit1.va.anthem.com',
  sit2: 'https://shop.sit1.va.anthem.com',
  uat1: 'https://shop.uat1.va.anthem.com'
};

export const WELLPOINT_OFF_EXCHANGE_BASE_URLS = {
  dev1: 'https://shop.dev1.va.wellpoint.com',
  development: 'https://shop.dev1.va.wellpoint.com',
  local: 'https://shop.dev1.va.wellpoint.com',
  perf1: 'https://shop.perf1.va.wellpoint.com',
  prod1: 'https://shop.wellpoint.com',
  prod2: 'https://shop.wellpoint.com',
  sit1: 'https://shop.sit1.va.wellpoint.com',
  sit2: 'https://shop.sit1.va.wellpoint.com',
  uat1: 'https://shop.uat1.va.wellpoint.com'
};

export const KY_EXCHANGE_BASE_URLS = {
  dev1: 'https://shop-ios.dev1.awse2.anthem.com',
  development: 'https://shop-ios.dev1.awse2.anthem.com',
  local: 'https://shop-ios.dev1.awse2.anthem.com',
  perf1: 'https://shop-ios.perf1.awse2.anthem.com',
  prod1: 'https://shop.anthem.com',
  prod2: 'https://shop.anthem.com',
  sit1: 'https://shop-ios.sit1.awse2.anthem.com',
  sit2: 'https://shop-ios.sit2.awse2.anthem.com',
  uat1: 'https://shop-ios.uat1.awse2.anthem.com'
};

export const HEALTHSHERPA_BASE_URLS = {
  dev1: 'https://staging.healthsherpa.com',
  development: 'https://staging.healthsherpa.com',
  local: 'https://staging.healthsherpa.com',
  perf1: 'https://staging.healthsherpa.com',
  prod1: 'https://enroll.anthem.com',
  prod2: 'https://enroll.anthem.com',
  sit1: 'https://staging.healthsherpa.com',
  sit2: 'https://staging.healthsherpa.com',
  uat1: 'https://staging.healthsherpa.com'
};

export const HEALTHSHERPA_WELLPOINT_BASE_URLS = {
  dev1: 'https://staging.healthsherpa.com',
  development: 'https://staging.healthsherpa.com',
  local: 'https://staging.healthsherpa.com',
  perf1: 'https://staging.healthsherpa.com',
  prod1: 'https://enroll.wellpoint.com',
  prod2: 'https://enroll.wellpoint.com',
  sit1: 'https://staging.healthsherpa.com',
  sit2: 'https://staging.healthsherpa.com',
  uat1: 'https://staging.healthsherpa.com'
};

export const MAC_APPOINTMENT_TOOL_URL = {
  TEST_ENVIRONMENT: 'https://shop.sit1.va.anthem.com/macappt/index.html',
  PROD: 'https://shop.anthem.com/macappt/index.html'
};

export const smDetails = {
  SM_LOGOUT_URL: '/individuals/siteminderagent/forms/logout.fcc',
  OIDC_SECURE_REDIRECT_URL: '/individuals/secure/redirect', //logout url
  LOGOUT_REDIRECT_URL: '/individuals' //target url
};
