import axios, { AxiosInstance } from 'axios';
import { API_NAME_LIST } from '../api-name-list';
const axiosInstanceForTempAPI: AxiosInstance = axios.create({
  baseURL: API_NAME_LIST.BASEURL,
  timeout: 80000
});
export const postSiteMetrics = (payload: SiteMetricsPayload) => {
  return axiosInstanceForTempAPI.post(API_NAME_LIST.SITE_METRICS, {
    ...payload
  });
};

interface SiteMetricsPayload {
  sessionId: string;
  eventType: string;
  userInfo?: {
    webGUID?: string;
    role: string;
  };
  pageURL: string;
  userAgent?: {
    deviceType?: string;
    browserName?: string;
    browserVersion?: string;
    platform?: string;
  };
  sessionObject: {
    firstname: string;
    email?: string;
    dob?: string;
    phone?: string;
    state?: string;
    coveragetype?: string;
    acn?: string;
    zipcode?: string;
    brand?: string;
    quoteid?: string;
    refid?: string;
  };
}
