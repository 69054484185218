import { Col, Modal, PageHeader, Row, Title } from "@sydney-broker-ui/ios";
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { HEALTHSHERPA_BASE_URLS, HEALTHSHERPA_WELLPOINT_BASE_URLS, KY_EXCHANGE_BASE_URLS, OFF_EXCHANGE_BASE_URLS, WELLPOINT_OFF_EXCHANGE_BASE_URLS } from "../../../api/api-name-list";
import { getCMSStaticContent, getCMSdynamicContent } from "../../../api/cms/cmsUtil";
import { HTTP_STATUS_CODES } from "../../../api/constants";
import { getZipcodeValidation } from "../../../api/services/utilService";
import { PLAN_MARKET_TYPES } from "../../../components/common/compare-tray/constant";
import EmptyPlans from "../../../components/common/empty-plans/EmptyPlans";
import { EMPTY_PLANS_USE_CASES } from "../../../components/common/empty-plans/constants";
import FullPageLoader from "../../../components/common/full-page-loader/FullPageLoader";
import GlobalHeader from '../../../components/common/global-header/GlobalHeader';
import InfoBar from "../../../components/common/info-bar/InfoBar";
import MatchingListCard from '../../../components/common/matching-list-card/MatchingListCard';
import { getApplicableGrade, getGradeLabel, getMedicationNames } from '../../../components/common/plan-card/planCardUtils';
import { APPLICATION_TYPE, BOOLEAN_VALUES, CATASTROPHIC_PLAN, ENTITY_TYPES, ERROR_ALERT_CONFIG, FFM_STATES, GEORGIA_CODE, LANGUAGE_CODES, MARKET_TAGS, ME_REDIRECTION_URL, NAV_PATHS, NON_FFM_REC_STATES, PAGE_BLOCKS, PAGE_IDS, PLAN_TYPES_NAMES, PROPOSAL_REDIRECTION_MARKET_PLACE_STATES, SCREEN_NAMES, SET_APPLICATION_CONSTANTS, STATE_CODES, USER_DOMAINS, VA_REDIRECTION_URL, WELLPOINT_MD_STATE, YES_NO_VALUES, ZIP_CODE_VALIDATION_SUCCESS_CODE } from '../../../shared/globalConstants';
import { formatDateToMMDDYYY, getFormattedCurrency, isBeforeDate } from "../../../shared/utils/globalUtils";
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from "../../../store/userStore";
import { shouldShowExceptionMessageForApplyNow } from "../../secure/proposal-quote-overview/proposalQuoteOverviewUtils";
import DentalPlanDetails from "../dental-plan-details/DentalPlanDetails";
import { PROVIDER_TYPE_IDS } from "../find-your-doctor/constants";
import MedicalPlanDetails from '../medical-plan-details/MedicalPlanDetails';
import { GRADE_LEVELS } from '../medical-plans/constants';
import { fetchPlanList, getProviderListById } from "../medical-plans/medicalPlansServices";
import { getMatchedDoctorsForPlan, getMatchedMedicationsForPlan } from "../medical-plans/medicalPlansUtils";
import { getEncryptacnPayload, getUpdateQuoteStatusPayload } from "../plan-selection-summary/planSelectionSummaryUtils";
import { getEncryptAcn, setApplicationApi, updateQuoteStatus } from "../plan-selection-summary/planSummaryServices";
import ProviderDetails from '../provider-details/ProviderDetails';
import { getDrugsPlanSummary } from "../show-my-medications/medicationSearchServices";
import VisionPlanDetails from "../vision-plan-details/VisionPlanDetails";
import './PlanRecommendations.scss';
import { DENTAL_PLAN_BENEFITS_ARR, MEDICAL_PLAN_BENEFITS_ARR, VISION_PLAN_BENEFITS_ARR } from './constants';
import { default as PlanRecommendationContent } from './planRecommendations.json';
import { getProposalID } from "./planRecommendationsServices";
import { getCoverageTypeForDynamicAEM, getDrugSummaryPayload, getProviderListByIdPayload, getRecommededPlanCardLabels, getRecommendedPlansPayload, getRecommendedSetApplicationData, getTotalPremiums, openEnrollmentStartedForPlanRec } from "./planRecommendationsUtils";
const { REACT_APP_ENV } = process.env;
export default function PlanRecommendations() {
    const [getProposalRes, setGetProposalRes] = useState<GetPropsalResponse>({} as GetPropsalResponse);
    const [proposalLoading, setProposalLoading] = useState<boolean>(false);
    const [medicalContract, setMedicalContract] = useState<Contract | null>(null);
    const [dentalContract, setDentalContract] = useState<Contract | null>(null);
    const [visionContract, setVisionContract] = useState<Contract | null>(null);
    const [useCase, setUseCase] = useState<string>('');
    const [planRecCoverages, setPlanRecCoverages] = useState<PlanRecCoverages>([]);
    const [quoteRecContracts, setQuoteRecContracts] = useState<QuoteRecContracts>([]);
    const [quoteOrderings, setQuoteOrderings] = useState<QuoteOrderings>([]);
    const [medicalRecPlansContracts, setMedicalRecPlansContracts] = useState<Contracts>([]);
    const [dentalRecPlansContracts, setDentalRecPlansContracts] = useState<Contracts>([]);
    const [visionRecPlansContracts, setVisionRecPlansContracts] = useState<Contracts>([]);
    const [meidcalSavedUserDrugEntities, setMedicalSavedUserDrugEntities] = useState<SavedUserEntities>([]);
    const [meidcalSavedUserDoctorEntities, setMedicalSavedUserDoctorEntities] = useState<SavedUserEntities>([]);
    const [dentalSavedUserDoctorEntities, setDentalSavedUserDoctorEntities] = useState<SavedUserEntities>([]);
    const [visionSavedUserDoctorEntities, setVisionSavedUserDoctorEntities] = useState<SavedUserEntities>([]);
    const [medicalMatchedProviders, setMedicalmatchedProviders] = useState<Providers>([]);
    const [dentalMatchedProviders, setDentalmatchedProviders] = useState<Providers>([]);
    const [visionMatchedProviders, setVisionmatchedProviders] = useState<Providers>([]);
    const [medicalRecommendedPlans, setMedicalRecommendedPlans] = useState<RecComparedPlans>([]);
    const [dentalRecommendedPlans, setDentalRecommendedPlans] = useState<RecComparedPlans>([]);
    const [visionRecommendedPlans, setVisionRecommendedPlans] = useState<RecComparedPlans>([]);
    const [drugLists, setDrugLists] = useState<DrugLists>([]);
    const [recCoveredMedicationsList, setRecCoveredMedicationsList] = useState<MedicationsPlanSummary[]>([]);
    const [agentName, setAgentName] = useState<string>('');
    const [medicalPdfUrl, setMedicalPdfUrl] = useState('');
    const [dentalPdfUrl, setDentalPdfUrl] = useState('');
    const [visionPdfUrl, setVisionPdfUrl] = useState('');
    const [aemPlanTypePdf, setAemPlanTypePdf] = useState('');
    const [isBeforeCoverage, setIsBeforeCoverage] = useState<boolean>(false);
    const [isWindowShopping, setIsWindowShopping] = useState<boolean>(false);
    const [totalPremiumArray, setTotalPremiumArray] = useState<TotalPremiums>([]);
    const [zipCodeObj, setZipCodeObj] = useState<ZipcodeLatitudeLongitude>({} as ZipcodeLatitudeLongitude);
    const [applyNowError, setApplyNowError] = useState({
        hasError: false,
        responseCode: '',
        message: ''
    });
    const [pageContent, setContent] = useState<any>(null);
    const [contentLoaded, setContentLoaded] = useState(false);
    const [serverError, setServerError] = useState(false);
    const { memberInfo, agentSSOInfo, currentCoverageMedicalPlan, currentCoverageDentalPlan, currentCoverageVisionPlan, providerMetaData, deepLinkInfo, zipCodeRes, agentState, enrollmentYear, updateDeepLinkDetailsScreenShowButtons } = useGlobalStore((state) => state);
    const { currentCoverages } = memberInfo
    const { proposalID } = deepLinkInfo
    const { webAccountGUID, isWellpoint, role } = useUserStore((state) => state);
    const [applyModalTitle, setApplyModalTitle] = useState<string>('');
    const [showApplyModal, setShowApplyModal] = useState(false);
    const [applyModalDesc, setApplyModalDesc] = useState<string>('');
    const navigate = useNavigate();

    const getContent = useCallback(async (): Promise<boolean> => {
        try {
            const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.PLAN_RECOMMENDATIONS);
            let cmsResponse = response.data;

            setContent(cmsResponse.data.iospagecontentList.items[0].pageContent?.PlanRecommendations);
        } catch (error) {
            setContent(PlanRecommendationContent.data.iospagecontentList.items[0].pageContent?.PlanRecommendations);

            return false;
        } finally {
            setContentLoaded(true);
        }
        return true;
    }, [zipCodeRes.stateCode]);

    const initiate = async () => {
        await getContent();
    };

    useEffect(() => {
        initiate();
        callGetProposalRes(proposalID, webAccountGUID);
    }, [proposalID])

    useEffect(() => {
        calculateTotalPremium();
    }, [medicalRecommendedPlans, dentalRecommendedPlans, visionRecommendedPlans])

    useEffect(() => {
        if (getProposalRes && Object.keys(getProposalRes)?.length > 0) {
            if (currentCoverages?.length) {
                const filteredCurrentCoverage = currentCoverages.filter((coverage) => coverage.planType !== PLAN_TYPES_NAMES.LIFE);
                const arr: PlanRecCoverage[] = filteredCurrentCoverage?.map(el => ({
                    createdDate: formatDateToMMDDYYY(getProposalRes.createdTS),
                    effectiveDate: formatDateToMMDDYYY(getProposalRes?.demographicInfo?.coverageEffectiveDate ?? ''),
                    currentPlan: el.planType,
                    planName: el.planName,
                    premium: el.totalPremium
                }));
                setPlanRecCoverages(arr);
            }
            setIsBeforeCoverage(isBeforeDate(getProposalRes?.demographicInfo?.coverageEffectiveDate ?? ''))
            getAgentName(getProposalRes.hpa?.agentFirstName, getProposalRes.hpa?.agentLastName);
            formatQuoteRecContracts(getProposalRes.quotes.quote, getProposalRes?.demographicInfo ?? {} as DemographicInfo);
            formatSavedUserDoctorEntities(getProposalRes?.demographicInfo?.savedUserEntity ?? []);
            validateZipCode(getProposalRes.demographicInfo?.zipCode ?? '');
        }
    }, [getProposalRes]);

    const validateZipCode = useCallback(async (zipCode: string): Promise<boolean> => {

        if (getProposalRes.demographicInfo?.zipCode !== '') {
            try {
                setProposalLoading(true);
                const payload: ZipCodeValidationPayload = {
                    zipCode: zipCode,
                    marketSegment: USER_DOMAINS.MARKET_SEGMENT
                };

                const response = await getZipcodeValidation(payload);
                const data: ZipCodeValidationResponse = response.data;

                if (data.zipCodeResponse.responseMessage.responseCode === ZIP_CODE_VALIDATION_SUCCESS_CODE) {
                    const { zipCodeResponse } = data;
                    setZipCodeObj({
                        latitude: zipCodeResponse.zipCode.latitude,
                        longitude: zipCodeResponse.zipCode.longitude,
                        currentDateTimeInZone: zipCodeResponse.zipCode.currentDateTimeInZone
                    });

                    setProposalLoading(false);
                    return true;
                } else {
                    setProposalLoading(false);
                    return false;
                }
            } catch (error) {
                setProposalLoading(false);
                return false;
            }
        }
        return true
    }, []);

    const getContractCodes = (type: string) => {
        const contractCodes: string[] = [];
        quoteRecContracts.map((quote) => {
            if (quote.planType === type) {
                contractCodes.push(quote.contractCode);
            }
        })
        return contractCodes;

    }

    useEffect(() => {
        if (meidcalSavedUserDoctorEntities?.length > 0 && zipCodeObj?.latitude && zipCodeObj?.longitude) {
            const payload: RecProviderListByIdPayload =
                getProviderListByIdPayload(meidcalSavedUserDoctorEntities,
                    getProposalRes?.demographicInfo ?
                        getProposalRes.demographicInfo : {}, zipCodeObj.latitude, zipCodeObj.longitude, getContractCodes(PLAN_TYPES_NAMES.MEDICAL));
            if (payload && Object.keys(payload).length > 0) {
                callGetProviderListById(payload, PLAN_TYPES_NAMES.MEDICAL);
            }
        }

        if (dentalSavedUserDoctorEntities && zipCodeObj?.latitude && zipCodeObj?.longitude) {
            const payload: RecProviderListByIdPayload =
                getProviderListByIdPayload(dentalSavedUserDoctorEntities,
                    getProposalRes?.demographicInfo ?
                        getProposalRes.demographicInfo : {}, zipCodeObj.latitude, zipCodeObj.longitude, getContractCodes(PLAN_TYPES_NAMES.DENTAL));
            if (payload && Object.keys(payload).length > 0) {
                callGetProviderListById(payload, PLAN_TYPES_NAMES.DENTAL);
            }
        }

        if (visionSavedUserDoctorEntities && zipCodeObj?.latitude && zipCodeObj?.longitude) {
            const payload: RecProviderListByIdPayload =
                getProviderListByIdPayload(visionSavedUserDoctorEntities,
                    getProposalRes?.demographicInfo ?
                        getProposalRes.demographicInfo : {}, zipCodeObj.latitude, zipCodeObj.longitude, getContractCodes(PLAN_TYPES_NAMES.VISION));
            if (payload && Object.keys(payload).length > 0) {
                callGetProviderListById(payload, PLAN_TYPES_NAMES.VISION);
            }
        }

        if (meidcalSavedUserDrugEntities?.length > 0) {
            let drugsList: DrugLists = [];
            meidcalSavedUserDrugEntities?.map((entity) => {
                if (entity.entityType === ENTITY_TYPES.DRUG) {
                    drugsList.push({
                        formularyId: Number(entity.entityId),
                        drugLabel: entity.entityLabel ?? '',
                        genericProductId: entity.compositeEntityId,
                        nationalDrugCode: null,
                        drugType: entity.entitySubType ?? '',
                        isGenericOptionsAvailable: entity.isGenericOptAvail === YES_NO_VALUES.YES ? BOOLEAN_VALUES.TRUE.toUpperCase() : BOOLEAN_VALUES.FALSE.toUpperCase()
                    })
                }
            });
            setDrugLists(drugsList);
        }
    }, [zipCodeObj])

    useEffect(() => {
        if (drugLists?.length && quoteRecContracts?.length) {
            const payload = getDrugSummaryPayload(quoteRecContracts, drugLists);
            getDrugsPlanSummary(payload)
                .then((response) => {
                    setProposalLoading(false);
                    const data: DrugsSummaryResponse = response.data;
                    const planSummaryList: MedicationsPlanSummary[] = data.response.planSummaryList.planSummary;
                    setRecCoveredMedicationsList(planSummaryList);
                })
                .catch((error) => {
                    setProposalLoading(false);
                });
        }

    }, [drugLists, quoteRecContracts])

    const callGetProviderListById = (payload: RecProviderListByIdPayload, type: string) => {
        setProposalLoading(true);
        getProviderListById(payload)
            .then((response) => {
                setProposalLoading(false);
                const data = response.data;
                const providerDetails = data.providerList;

                switch (type) {
                    case PLAN_TYPES_NAMES.MEDICAL:
                        setMedicalmatchedProviders(providerDetails);
                        break;
                    case PLAN_TYPES_NAMES.DENTAL:
                        setDentalmatchedProviders(providerDetails);
                        break;
                    case PLAN_TYPES_NAMES.VISION:
                        setVisionmatchedProviders(providerDetails);
                        break;
                    default:
                        setMedicalmatchedProviders([]);
                        setDentalmatchedProviders([]);
                        setVisionmatchedProviders([]);

                }
            })
            .catch((error) => {
                setProposalLoading(false);
            });
    }

    const productNotAvailableForMedical = (lcontractCode: string) => {
        const contract: Contract = medicalRecPlansContracts?.find((contract: Contract) => contract.contractCode === lcontractCode) as Contract;
        const state = getProposalRes?.demographicInfo?.state ?? '';
        if (contract.onExchange) {
            return NON_FFM_REC_STATES.includes(state)
        } else {
            return false;
        }
    }

    const productNotAvailableForDental = (lcontractCode: string) => {
        const contract: Contract = dentalRecPlansContracts?.find((contract: Contract) => contract.contractCode === lcontractCode) as Contract;
        const medicalContracts: QuoteRecContract[] = quoteRecContracts?.filter((el: QuoteRecContract) => el.planType === PLAN_TYPES_NAMES.MEDICAL)
        const dentalContracts: QuoteRecContract[] = quoteRecContracts?.filter((el: QuoteRecContract) => el.planType === PLAN_TYPES_NAMES.DENTAL)
        const state = getProposalRes?.demographicInfo?.state ?? '';
        if (contract.onExchange) {
            return NON_FFM_REC_STATES.includes(state) || (medicalContracts?.length === 0 && dentalContracts?.length > 0)
        } else {
            return false;
        }
    }



    useEffect(() => {
        let count: number = 0;
        let medicalRecommendedPlans: RecComparedPlans = [];
        let dentalRecommendedPlans: RecComparedPlans = [];
        let visionRecommendedPlans: RecComparedPlans = [];
        quoteOrderings?.map((quote) => {
            count++;
            quoteRecContracts?.map((quoteRecContract) => {
                setProposalLoading(true);
                if (quote.quoteId === quoteRecContract.quoteId && quoteRecContract.planType === PLAN_TYPES_NAMES.MEDICAL && medicalRecPlansContracts?.length > 0) {
                    const medicalContract: RecComparedContract = {
                        quoteId: quoteRecContract.quoteId,
                        contract: medicalRecPlansContracts?.find((contract: Contract) => contract.contractCode === quoteRecContract.contractCode) as Contract,
                        matchedProviders: medicalMatchedProviders?.length > 0 ? getMatchedDoctorsForPlan(true, medicalMatchedProviders, quoteRecContract.contractCode) : [],
                        matchedMedications: drugLists?.length > 0 ? getMatchedMedicationsForPlan(true, drugLists, quoteRecContract.contractCode, recCoveredMedicationsList) : [],
                        pdfBrochureUrl: medicalPdfUrl,
                        productNotAvailable: productNotAvailableForMedical(quoteRecContract.contractCode)
                    }
                    medicalRecommendedPlans.push(medicalContract);
                } else if (quote.quoteId === quoteRecContract.quoteId && quoteRecContract.planType === PLAN_TYPES_NAMES.DENTAL && dentalRecPlansContracts?.length > 0) {

                    const dentalContract: RecComparedContract = {
                        quoteId: quoteRecContract.quoteId,
                        contract: dentalRecPlansContracts?.find((contract: Contract) => contract.contractCode === quoteRecContract.contractCode) as Contract,
                        matchedProviders: dentalMatchedProviders?.length > 0 ? getMatchedDoctorsForPlan(true, dentalMatchedProviders, quoteRecContract.contractCode) : [],
                        matchedMedications: [],
                        pdfBrochureUrl: dentalPdfUrl,
                        productNotAvailable: productNotAvailableForDental(quoteRecContract.contractCode)
                    }
                    dentalRecommendedPlans.push(dentalContract);
                } else if (quote.quoteId === quoteRecContract.quoteId && quoteRecContract.planType === PLAN_TYPES_NAMES.VISION && visionRecPlansContracts?.length > 0) {
                    const visionContract: RecComparedContract = {
                        quoteId: quoteRecContract.quoteId,
                        contract: visionRecPlansContracts?.find((contract: Contract) => contract.contractCode === quoteRecContract.contractCode) as Contract,
                        matchedProviders: visionMatchedProviders?.length > 0 ? getMatchedDoctorsForPlan(true, visionMatchedProviders, quoteRecContract.contractCode) : [],
                        matchedMedications: [],
                        pdfBrochureUrl: visionPdfUrl,
                        productNotAvailable: false
                    }
                    visionRecommendedPlans.push(visionContract);
                }
            })
        })

        if (medicalRecommendedPlans?.length > 0) {
            setMedicalRecommendedPlans(medicalRecommendedPlans);
        }
        if (dentalRecommendedPlans?.length > 0) {
            setDentalRecommendedPlans(dentalRecommendedPlans);
        }
        if (visionRecommendedPlans?.length > 0) {
            setVisionRecommendedPlans(visionRecommendedPlans);
        }

        if (count === quoteOrderings?.length) {
            calculateTotalPremium();
            setProposalLoading(false);
        }
    }, [medicalMatchedProviders, dentalMatchedProviders, visionMatchedProviders, medicalRecPlansContracts, dentalRecPlansContracts, visionRecPlansContracts])



    const formatSavedUserDoctorEntities = (savedUserEntities: SavedUserEntities) => {
        const medicalSavedUserEntities = savedUserEntities.filter((entity) => entity.entityType === ENTITY_TYPES.PROVIDER && entity.entitySubType !== PROVIDER_TYPE_IDS.DENTIST && entity.entitySubType !== PROVIDER_TYPE_IDS.DENTAL_PROVIDERS && entity.entitySubType !== PROVIDER_TYPE_IDS.VISION);
        const dentalSavedUserEntities = savedUserEntities.filter((entity) => entity.entityType === ENTITY_TYPES.PROVIDER && (entity.entitySubType === PROVIDER_TYPE_IDS.DENTIST || entity.entitySubType === PROVIDER_TYPE_IDS.DENTAL_PROVIDERS));
        const visionSavedUserEntities = savedUserEntities.filter((entity) => entity.entityType === ENTITY_TYPES.PROVIDER && entity.entitySubType !== PROVIDER_TYPE_IDS.DENTIST && entity.entitySubType === PROVIDER_TYPE_IDS.VISION);
        const meidcalSavedUserDrugEntities = savedUserEntities.filter((entity) => entity.entityType === ENTITY_TYPES.DRUG && entity.entitySubType !== PROVIDER_TYPE_IDS.DENTIST && entity.entitySubType !== PROVIDER_TYPE_IDS.DENTAL_PROVIDERS && entity.entitySubType !== PROVIDER_TYPE_IDS.VISION);
        setMedicalSavedUserDoctorEntities(medicalSavedUserEntities);
        setDentalSavedUserDoctorEntities(dentalSavedUserEntities);
        setVisionSavedUserDoctorEntities(visionSavedUserEntities);
        setMedicalSavedUserDrugEntities(meidcalSavedUserDrugEntities);
    }

    const formatQuoteRecContracts = (quotes: Quotes[], demographicInfo: DemographicInfo) => {
        let quoteRecContracts: QuoteRecContracts = [];
        let quoteOrderings: QuoteOrderings = []
        quotes.map((quote) => {
            const quoteOrdering: QuoteOrdering = {
                quoteId: quote.quoteId,
                quoteLineItem: quote?.quoteLineItem?.length
            }
            quoteOrderings.push(quoteOrdering);
            quote?.quoteLineItem.map((lineItem) => {
                const contract: QuoteRecContract = {
                    quoteId: quote.quoteId,
                    contractCode: lineItem.contract.contractCode,
                    planType: lineItem.contract.planType,
                    applicant: lineItem.applicant
                }

                quoteRecContracts.push(contract);
            })
        })
        setQuoteRecContracts(quoteRecContracts);
        setQuoteOrderings(quoteOrderings.sort(function (a, b) {
            return b.quoteLineItem - a.quoteLineItem;
        }))
        const medicalQuoteRecContracts = quoteRecContracts.filter((contract) => contract.planType === PLAN_TYPES_NAMES.MEDICAL);
        const dentalQuoteRecContracts = quoteRecContracts.filter((contract) => contract.planType === PLAN_TYPES_NAMES.DENTAL);
        const visionQuoteRecContracts = quoteRecContracts.filter((contract) => contract.planType === PLAN_TYPES_NAMES.VISION);
        if (medicalQuoteRecContracts && medicalQuoteRecContracts?.length) {
            fromatRecommendedPlansPayload(medicalQuoteRecContracts, PLAN_TYPES_NAMES.MEDICAL);
        }
        if (dentalQuoteRecContracts && dentalQuoteRecContracts?.length) {
            fromatRecommendedPlansPayload(dentalQuoteRecContracts, PLAN_TYPES_NAMES.DENTAL);
        }
        if (visionQuoteRecContracts && visionQuoteRecContracts?.length) {
            fromatRecommendedPlansPayload(visionQuoteRecContracts, PLAN_TYPES_NAMES.VISION);
        }

        if (quoteRecContracts?.length > 0) {
            const isMedicalContract = quoteRecContracts?.some(el => el.planType === PLAN_TYPES_NAMES.MEDICAL);
            const isDentalContract = quoteRecContracts?.some(el => el.planType === PLAN_TYPES_NAMES.DENTAL);
            const isVisionContract = quoteRecContracts?.some(el => el.planType === PLAN_TYPES_NAMES.VISION);
            const state = demographicInfo?.state ?? '';
            const brand = demographicInfo?.brand ?? '';
            const year = demographicInfo?.coverageEffectiveDate ? demographicInfo.coverageEffectiveDate.split('-')[0] : '';
            const aemPlanType = quotes[0].quoteLineItem[0].contract.portFolio == BOOLEAN_VALUES.TRUE ? APPLICATION_TYPE.ON_EXCHANGE.toLowerCase() : APPLICATION_TYPE.OFF_EXCHANGE.toLowerCase();
            setAemPlanTypePdf(aemPlanType);
            setIsWindowShopping(year === enrollmentYear);
            setMedicalPdfUrl('');
            setDentalPdfUrl('');
            setVisionPdfUrl('');
            if (isMedicalContract) {
                getPdfURL(SCREEN_NAMES.MEDICAL_PLAN_DETAILS, state, brand, year, aemPlanType);
            }
            if (isDentalContract) {
                getPdfURL(SCREEN_NAMES.DENTAL_DETAILS_SCREEN_NAME, state, brand, year, aemPlanType);
            }
            if (isVisionContract) {
                getPdfURL(SCREEN_NAMES.VISION_PLAN_DETAILS, state, brand, year, aemPlanType);
            }
        }
    }

    const getPDFQueryParm = (screenName: String) => {
        const state = getProposalRes?.demographicInfo?.state ?? '';
        const brand = getProposalRes?.demographicInfo?.brand ?? '';
        const year = getProposalRes?.demographicInfo?.coverageEffectiveDate ? getProposalRes?.demographicInfo.coverageEffectiveDate.split('-')[0] : '';
        return ';state=' + state + ';brand=' + brand + ';role=' + role.toLowerCase() + ';plantype=' + aemPlanTypePdf + ';coveragetype=' + getCoverageTypeForDynamicAEM(screenName) + ';year=' + year;
    }

    const getPdfURL = useCallback(async (screenName: string, state: string, brand: string, year: string, aemPlanType: string): Promise<boolean> => {
        if (screenName !== '') {
            try {
                let queryParam = ';state=' + state + ';brand=' + brand + ';role=' + role.toLowerCase() + ';plantype=' + aemPlanType + ';coveragetype=' + getCoverageTypeForDynamicAEM(screenName) + ';year=' + year;
                const response = await getCMSdynamicContent(PAGE_IDS.PRODUCT_BROUCHER_LINK_ALL, PAGE_BLOCKS.PRODUCT_BROUCHER_LINK, queryParam, zipCodeObj.currentDateTimeInZone)
                const cmsResponse = response?.data;

                switch (screenName) {
                    case SCREEN_NAMES.MEDICAL_PLAN_DETAILS:
                        setMedicalPdfUrl(cmsResponse.data?.iosdynamiccontentList?.items[0]?.textsummary);
                        break;
                    case SCREEN_NAMES.DENTAL_DETAILS_SCREEN_NAME:
                        setDentalPdfUrl(cmsResponse.data?.iosdynamiccontentList?.items[0]?.textsummary);
                        break;
                    case SCREEN_NAMES.VISION_PLAN_DETAILS:
                        setVisionPdfUrl(cmsResponse.data?.iosdynamiccontentList?.items[0]?.textsummary);
                        break;
                    default:
                        break;
                }

            } catch (error) {
                return false;
            }
        }
        return true;

    }, [quoteRecContracts]);

    const fromatRecommendedPlansPayload = (quoteRecContracts: QuoteRecContracts, type: string) => {
        const contractBundle: contractBundle[] = quoteRecContracts.map((contract) => {
            return {
                contractCode: contract.contractCode
            }
        });

        const uniqueContractBundle: contractBundle[] = contractBundle.filter((obj, index) => {
            return index === contractBundle.findIndex(el => obj.contractCode === el.contractCode);
        });

        const payload = getRecommendedPlansPayload(quoteRecContracts[0].planType, quoteRecContracts[0].applicant,
            getProposalRes?.demographicInfo ?
                getProposalRes.demographicInfo : {}, uniqueContractBundle);

        loadRecommendedPlans(payload, type)
    }

    const loadRecommendedPlans = (payload: PlanSummaryPayload, type: string) => {
        setProposalLoading(true)
        setServerError(false)
        fetchPlanList(payload)
            .then((response) => {
                setProposalLoading(false)
                const data: PlanSummaryResponse = response.data;
                const contractPlan: Contracts = data.planSummaryResponse.plans.contract;

                switch (type) {
                    case PLAN_TYPES_NAMES.MEDICAL:
                        setMedicalRecPlansContracts(contractPlan);
                        break;
                    case PLAN_TYPES_NAMES.DENTAL:
                        setDentalRecPlansContracts(contractPlan);
                        break;
                    case PLAN_TYPES_NAMES.VISION:
                        setVisionRecPlansContracts(contractPlan);
                        break;
                    default:
                        setMedicalRecPlansContracts([]);
                        setDentalRecPlansContracts([]);
                        setVisionRecPlansContracts([]);

                }
            })
            .catch((error) => {
                setServerError(true);
                setProposalLoading(false)
            });
    }

    const callGetProposalRes = (proposalID: string, webAccountGUID: string) => {
        setProposalLoading(true)
        setServerError(false);
        getProposalID(webAccountGUID, proposalID).then((response) => {
            const data: GetPropsalResponse = response.data;
            setGetProposalRes(data);
            setProposalLoading(false);
        })
            .catch((error) => {
                setServerError(true)
                setProposalLoading(false);
            });
    }


    const onClickDetails = (contract: Contract, type: string) => {
        switch (type) {
            case PLAN_TYPES_NAMES.MEDICAL:
                setMedicalContract(contract);
                break;
            case PLAN_TYPES_NAMES.DENTAL:
                setDentalContract(contract);
                break;
            case PLAN_TYPES_NAMES.VISION:
                setVisionContract(contract);
                break;
            default:
                setMedicalContract(null);
                setDentalContract(null);
                setVisionContract(null);
        }
    }

    const renderGradetag = (gradeLevels: GradeLevel[], isCurrentCoveragePlan: boolean = false) => {
        if (isCurrentCoveragePlan) {
            return (
                <div className={'grade-wrapper current-coverage-grade-container'}>
                    <span className="grade-label">{pageContent?.CURRENT_COVERAGE}</span>
                </div>
            );
        } else {
            const applicableGrade = getApplicableGrade(gradeLevels);

            switch (applicableGrade) {
                case GRADE_LEVELS.RECOMMENDED:
                case GRADE_LEVELS.POPULAR:
                    return (
                        <div className={applicableGrade === GRADE_LEVELS.RECOMMENDED ? 'grade-wrapper recommended-grade-container' : 'grade-wrapper popular-grade-level'}>
                            <span className="grade-label">{getGradeLabel(applicableGrade, getRecommededPlanCardLabels(pageContent?.PLAN_CARD_LABELS))}</span>
                        </div>
                    );
                default:
                    return null;
            }
        }
    };

    const marketTagClass = (market: boolean, colorado: boolean) => {
        if (colorado) {
            return MARKET_TAGS.COLORADO;
        } else if (market) {
            return MARKET_TAGS.MARKET;
        } else {
            return MARKET_TAGS.OFF_MARKET;
        }
    };

    const getiIsCurrentCoveragePlan = (contractCode: string, planType: string) => {
        switch (planType) {
            case PLAN_TYPES_NAMES.MEDICAL:
                return currentCoverageMedicalPlan?.contractCode === contractCode;
            case PLAN_TYPES_NAMES.DENTAL:
                return currentCoverageDentalPlan?.contractCode === contractCode;
            case PLAN_TYPES_NAMES.VISION:
                return currentCoverageVisionPlan?.contractCode === contractCode;
            default:
                return false;
        }
    }

    const placeholders = () => {
        return (
            <>
                {(quoteOrderings?.length > 0 && quoteOrderings?.length <= 3) &&
                    <div className="planRec-tray-plan placeholder-plan fwc-col-3 fwc-card-generic"></div>}
                {quoteOrderings.length === 1 &&
                    <div className="planRec-tray-plan placeholder-plan fwc-col-3 fwc-card-generic"></div>}
                {quoteOrderings.length === 1 &&
                    <div className="planRec-tray-plan placeholder-plan fwc-col-3 fwc-card-generic"></div>}
            </>
        )
    }

    const findPlanBenefit = (code, plan) => {
        const benefit = plan.contract.plan[0].planBenefits.find(benefit => benefit.planBenefitCode === code);
        return benefit?.planBenefitValue;
    };

    const getAgentName = (fName, lName) => {
        const name = fName + ' ' + lName ? lName : '';
        setAgentName(name);
    }

    const displayEmptyCard = () => {
        return (
            <div key={'empty'} className={'cs-card fwc-card-generic flat fwc-col-3'}> </div>
        )
    }

    const productNotAvailable = () => {
        return (
            <p>{pageContent?.PRODUCT_NOT_AVAILABLE}</p>
        )
    }
    const effectiveDateError = () => {
        return (
            <p>{pageContent?.EFFECTIVE_DATE_ERROR}</p>
        )
    }

    const windowShoppingError = () => {
        return (
            <p>{pageContent?.EXCEPTION_MESSAGE_DURING_WS}</p>
        )
    }

    const calculateTotalPremium = () => {
        const recommendedPlans: RecComparedPlans = [...medicalRecommendedPlans, ...dentalRecommendedPlans, ...visionRecommendedPlans]
        recommendedPlans?.length > 0 && quoteOrderings?.length > 0 ? setTotalPremiumArray(getTotalPremiums(recommendedPlans, quoteOrderings)) : setTotalPremiumArray([])
    }

    const checkPortFolio = (quoteId: string) => {
        let portFolio = false;
        const quotes = getProposalRes.quotes.quote?.find((quote: Quotes) => quote.quoteId === quoteId);
        if (quotes?.quoteLineItem[0].contract.portFolio === BOOLEAN_VALUES.FALSE) {
            portFolio = false
        } else if (quotes?.quoteLineItem[0].contract.portFolio === BOOLEAN_VALUES.TRUE) {
            portFolio = true
        }
        return portFolio;
    }

    const handleProposalRedirection = (state: string) => {
        switch (state) {
            case STATE_CODES.ME:
                window.open(`${ME_REDIRECTION_URL}`, '_self');
                break;
            case STATE_CODES.VA:
                window.open(VA_REDIRECTION_URL, '_self');
                break;
            default:
                break
        }
    }

    const applyNow = (quoteId: string, onExchange: boolean) => {
        let applicants: Applicant[] = [];
        quoteRecContracts.filter(el => el.quoteId === quoteId)
            .map(el => {
                applicants = [...applicants, ...el.applicant]
            });

        const uniqueApplicants: Applicant[] = applicants.filter((obj, index) => {
            return index === applicants.findIndex(el => obj.applicantId === el.applicantId);
        });

        const selectedPlan: SelectedPlan = {
            plan: medicalRecommendedPlans.find((item) => item.quoteId === quoteId)?.contract ?? null,
            applicants: quoteRecContracts.find((item) => item.quoteId === quoteId && item.planType === PLAN_TYPES_NAMES.MEDICAL)?.applicant ?? [],
            matchedProviders: medicalRecommendedPlans.find((item) => item.quoteId === quoteId)?.matchedProviders ?? [],
            matchedMedications: medicalRecommendedPlans.find((item) => item.quoteId === quoteId)?.matchedMedications ?? [],
        }

        const selectedDentalPlan: SelectedPlan = {
            plan: dentalRecommendedPlans.find((item) => item.quoteId === quoteId)?.contract ?? null,
            applicants: quoteRecContracts.find((item) => item.quoteId === quoteId && item.planType === PLAN_TYPES_NAMES.DENTAL)?.applicant ?? [],
            matchedProviders: dentalRecommendedPlans.find((item) => item.quoteId === quoteId)?.matchedProviders ?? [],
            matchedMedications: dentalRecommendedPlans.find((item) => item.quoteId === quoteId)?.matchedMedications ?? [],
        }

        const selectedVisionPlan: SelectedPlan = {
            plan: visionRecommendedPlans.find((item) => item.quoteId === quoteId)?.contract ?? null,
            applicants: quoteRecContracts.find((item) => item.quoteId === quoteId && item.planType === PLAN_TYPES_NAMES.VISION)?.applicant ?? [],
            matchedProviders: visionRecommendedPlans.find((item) => item.quoteId === quoteId)?.matchedProviders ?? [],
            matchedMedications: visionRecommendedPlans.find((item) => item.quoteId === quoteId)?.matchedMedications ?? [],
        }

        const payload = getRecommendedSetApplicationData(
            onExchange, getProposalRes?.demographicInfo ?? {}, getProposalRes?.agent ?? {} as AgentOfRecord, uniqueApplicants, selectedPlan, selectedDentalPlan, selectedVisionPlan);
        if (payload?.application?.applicationType === PLAN_MARKET_TYPES.COLARADO_CONNECT) {
            setApplyModalTitle(pageContent.COLORADO_MODAL_TITLE)
            setShowApplyModal(true);
        } else if (onExchange) {
            const state = getProposalRes?.demographicInfo?.state ?? '';
            if ((isWellpoint && state === WELLPOINT_MD_STATE) || (isCatastriphicPlanSelected(selectedPlan?.plan?.tiersType) && FFM_STATES.includes(state))) {
                setApplyModalTitle('');
                setApplyModalDesc((isCatastriphicPlanSelected(selectedPlan?.plan?.tiersType) && FFM_STATES.includes(state)) ? pageContent.MODAL_DESC_FFM_CATASTROPHIC : pageContent.MODAL_DESC);
                setShowApplyModal(true);
            } else if (PROPOSAL_REDIRECTION_MARKET_PLACE_STATES.includes(state)) {
                handleProposalRedirection(state)
            } else if (FFM_STATES.includes(state) || state === GEORGIA_CODE) {
                redirectToHealthSherpa(quoteId);
            } else if (state === SET_APPLICATION_CONSTANTS.KY) {
                getSetApplication(payload, onExchange, quoteId)
            }
        } else {
            getSetApplication(payload, onExchange, quoteId)
        }
    }

    const navigateToShopBasics = () => {
        updateDeepLinkDetailsScreenShowButtons(false);
        navigate(NAV_PATHS.BASICS_LANDING);
    }

    const isCatastriphicPlanSelected = (tiersType) => {
        return tiersType === CATASTROPHIC_PLAN;
    }

    const getSelectedRecommendedPlanId = (quoteId: string) => {
        let selectedPlanIds = '';
        const selectedPlan: Contract = medicalRecommendedPlans.find((item) => item.quoteId === quoteId)?.contract ?? {} as Contract;
        const selectedDentalPlan: Contract = dentalRecommendedPlans.find((item) => item.quoteId === quoteId)?.contract ?? {} as Contract;
        if (selectedPlan?.plan) {
            selectedPlanIds = selectedPlanIds + `&plan_hios_id=${selectedPlan?.qHPId}`;
        }
        if (selectedDentalPlan?.plan) {
            selectedPlanIds = selectedPlanIds + `&dental_plan_hios_id=${selectedDentalPlan?.qHPId}`;
        }
        return selectedPlanIds;
    }
    const redirectToHealthSherpa = (quoteId: string) => {
        if (getProposalRes?.agent && getProposalRes?.agent?.npn) {
            window.open(`${isWellpoint ? HEALTHSHERPA_WELLPOINT_BASE_URLS[REACT_APP_ENV ?? ''] : HEALTHSHERPA_BASE_URLS[REACT_APP_ENV ?? '']}/apply/?_carrier_id=${isWellpoint ? "wellpoint" : "anthem"}&agent[npn]=${getProposalRes?.agent?.npn}${getSelectedRecommendedPlanId(quoteId)}&state=${getProposalRes?.demographicInfo?.state}&zip_code=${getProposalRes?.demographicInfo?.zipCode}`, '_self')
        } else {
            window.open(`${isWellpoint ? HEALTHSHERPA_WELLPOINT_BASE_URLS[REACT_APP_ENV ?? ''] : HEALTHSHERPA_BASE_URLS[REACT_APP_ENV ?? '']}/apply/?_agent_id=${isWellpoint ? "wellpoint" : "anthem"}${getSelectedRecommendedPlanId(quoteId)}&state=${getProposalRes?.demographicInfo?.state}&zip_code=${getProposalRes?.demographicInfo?.zipCode}`, '_self')
        }
    }

    const getSetApplication = async (payload, onExchange: boolean, quoteId: string) => {
        setProposalLoading(true);
        try {
            const { isAtk, eventHeader } = useGlobalStore.getState().agentSSOInfo;
            const { brokerUserID } = eventHeader;
            let response = await setApplicationApi(payload);
            if (response?.data?.status === SET_APPLICATION_CONSTANTS.SUCCESS) {
                const updateQuoteStatusPayload = getUpdateQuoteStatusPayload(response?.data?.response?.acn, quoteId)
                await updateQuoteStatus(updateQuoteStatusPayload);
                const encryptedACNPayload: EncryptacnPayload = getEncryptacnPayload(response?.data?.response?.acn)
                const encryptedacnResponse = await getEncryptAcn(encryptedACNPayload);
                setProposalLoading(false);
                const redirectionUrl = isAtk ? `${isWellpoint ? WELLPOINT_OFF_EXCHANGE_BASE_URLS[REACT_APP_ENV ?? ''] : OFF_EXCHANGE_BASE_URLS[REACT_APP_ENV ?? '']}/sales/eox/secure/apply?acn=${encryptedacnResponse?.data?.encryptedACN}&atkEncryptedUser=${brokerUserID}&source=acaRefreshPTB` : `${isWellpoint ? WELLPOINT_OFF_EXCHANGE_BASE_URLS[REACT_APP_ENV ?? ''] : OFF_EXCHANGE_BASE_URLS[REACT_APP_ENV ?? '']}/sales/eox/secure/apply?acn=${encryptedacnResponse?.data?.encryptedACN}&source=acaRefresh`;
                if (onExchange) {
                    window.open(`${KY_EXCHANGE_BASE_URLS[REACT_APP_ENV ?? '']}/individuals/secure/api/ede/mvc/redirectToKyNect?acn=${encryptedacnResponse?.data?.encryptedACN}`, '_self');
                } else {
                    window.open(
                        redirectionUrl,
                        '_self'
                    );
                }
            } else {
                setProposalLoading(false);
                showQuoteError();
            }
        } catch (error) {
            setProposalLoading(false);
            console.warn('PlanRecommendations.tsx getSetApplication error :', error)
            showQuoteError();
        }
    }

    const showQuoteError = () => {
        setProposalLoading(false)
        setApplyNowError({
            hasError: true,
            responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
            message: `<b>${pageContent?.SYSTEM_EXCEPTION_HEADING}</b> <br> ${pageContent?.SYSTEM_EXCEPTION_MESSAGE}`
        });
    }

    const resetApplyNowError = () => {
        setApplyNowError({
            hasError: false,
            message: '',
            responseCode: ''
        });
    };

    const onErrorPopupClose = () => {
        resetApplyNowError();
    };

    const errorInfoBar = () => {
        return (
            <InfoBar
                area={ERROR_ALERT_CONFIG.AREA}
                backgroundColor={'error'}
                labelAriaCloseBtn={ERROR_ALERT_CONFIG.TYPE}
                type={ERROR_ALERT_CONFIG.ERROR}
                handleClose={() => {
                    onErrorPopupClose();
                }}
            >
                <p className="message" dangerouslySetInnerHTML={{ __html: applyNowError.message }} />
            </InfoBar>
        )
    }

    const closeApplyNowModal = () => {
        document.body.classList.remove("no-scroll");
        setShowApplyModal(false);
        setApplyModalDesc('');
        setApplyModalTitle('');
    }

    return (
        <div>
            <GlobalHeader />
            <div className={'header-container'}>
                <PageHeader>
                    <Row alignItems="center">
                        <Col
                            bottomMargin
                            lg="5"
                            sm="12"
                        >
                            <Title>
                                {pageContent?.PLAN_RECOMMENDATIONS}
                            </Title>
                        </Col>
                    </Row>
                </PageHeader>
            </div>
            {(!contentLoaded || proposalLoading) && <FullPageLoader />}
            {
                contentLoaded && !proposalLoading && serverError && <EmptyPlans useCase={EMPTY_PLANS_USE_CASES.SERVER_ERROR} labels={pageContent?.EMPTY_PLANS} />
            }
            {contentLoaded && !proposalLoading && !serverError && <div className='planRecommendations-container'>
                <div className='fwc-row fwc-row-bm'>
                    <div className='fwc-col-10'>
                        <h2>{agentName} {agentName ? pageContent?.RECOMMENDS : ''}</h2>
                    </div>
                    <div className='fwc-col-2'>
                        <button className={'fwc-btn fwc-btn-primary'} id='shop' onClick={() => navigateToShopBasics()}>{pageContent?.WANT_TO_SHOP}</button>
                    </div>
                </div>
                {
                    planRecCoverages && planRecCoverages?.map((coverage, index) => (
                        <div className='fwc-row fwc-row-bm' key={index}>
                            <div className="coverages-container">
                                <div className="coverage-column date-created">
                                    <span id="date-created-label" className="coverage-bold-label">
                                        {pageContent?.DATE_CREATED}
                                    </span>
                                    <div
                                        id='date-created-value'
                                        className="coverage-label"
                                    > {coverage.createdDate}</div>
                                </div>
                                <div className="coverage-column eff-date">
                                    <span id="eff-date-label" className="coverage-bold-label">
                                        {pageContent?.EFFECTIVE_DATE}
                                    </span>
                                    <div
                                        id={"eff-date-value"}
                                        className="coverage-label"
                                    >{coverage.effectiveDate}</div>
                                </div>
                                <div className="coverage-column current-plan">
                                    <span id="current-plan-label" className="coverage-bold-label">
                                        {pageContent?.CURRENT_PLAN}
                                    </span>
                                    <div
                                        id={"current-plan-value"}
                                        className="coverage-label"
                                    > {coverage.currentPlan}</div>
                                </div>
                                <div className="coverage-column plan-name">
                                    <span id="plan-name-label" className="coverage-bold-label">
                                        {pageContent?.PLAN_NAME}
                                    </span>
                                    <div
                                        id={"plan-name-value"}
                                        className="coverage-label"
                                    > {coverage.planName}</div>
                                </div>
                                <div className="coverage-column premium">
                                    <span id="premium-label" className="coverage-bold-label">
                                        {pageContent?.PREMIUM}
                                    </span>
                                    <div
                                        id={"premium-value"}
                                        className="coverage-label"
                                    > {getFormattedCurrency(Number(coverage.premium))}</div>
                                </div>
                            </div>
                        </div>
                    ))
                }

                {medicalRecommendedPlans?.length > 0 && <div id="medical-plans">
                    <div className='fwc-row fwc-row-bm'>
                        <h2>{pageContent?.MEDICAL_PLANS}</h2>
                    </div>
                    <div className='fwc-row fwc-row-bm'>
                        <div className={'planRec-tray-content'}>
                            {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                const filterArr = medicalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                return (
                                    filterArr?.length > 0 ?
                                        filterArr?.map((plan: RecComparedContract) => (
                                            plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className="planRec-tray-plan fwc-col-3 fwc-card-generic">
                                                <div className="planRec-tray-plan-header">
                                                    <div id="plan-card-grade" className="grade-container">
                                                        {renderGradetag(plan?.contract.gradeLevels, getiIsCurrentCoveragePlan(plan?.contract?.contractCode, PLAN_TYPES_NAMES.MEDICAL))}
                                                    </div>
                                                    <div className={'market-tag ' + marketTagClass(plan.contract.onExchange, plan.contract.poPlan)}>{marketTagClass(plan.contract.onExchange, plan.contract.poPlan)}</div>
                                                </div>
                                                <div className={"planRec-tray-plan-title slider"}>
                                                    {plan?.contract.planMarketingName}
                                                </div>
                                                {!openEnrollmentStartedForPlanRec(zipCodeObj.currentDateTimeInZone ?? '') && isWindowShopping && windowShoppingError()}
                                                {
                                                    shouldShowExceptionMessageForApplyNow(getProposalRes?.demographicInfo?.coverageEffectiveDate ?? '', pageContent?.CUTOFF_DATE_FOR_APPLY_NOW ?? '') ? <p>{pageContent?.EXCEPTION_MESSAGE_FOR_APPLY_NOW}</p> :

                                                        (openEnrollmentStartedForPlanRec(zipCodeObj.currentDateTimeInZone ?? '') || !isWindowShopping) && !isBeforeCoverage && !plan.productNotAvailable
                                                        &&
                                                        <button className={'fwc-btn fwc-btn-primary fwc-col-12'}
                                                            onClick={() => applyNow(plan.quoteId ?? '', checkPortFolio(plan.quoteId ?? ''))}>
                                                            {pageContent?.APPLY_NOW}
                                                        </button>
                                                }
                                                {!isWindowShopping && isBeforeCoverage && effectiveDateError()}
                                                {!isWindowShopping && !isBeforeCoverage && plan.productNotAvailable && productNotAvailable()}
                                            </div>
                                        ))
                                        : <div key={'empty'} className="planRec-tray-plan fwc-col-3 fwc-card-generic">

                                        </div>
                                )
                            })}
                            {placeholders()}
                        </div>
                    </div>
                    {medicalRecommendedPlans?.length > 0 && applyNowError.hasError && !proposalLoading && (
                        <div className='fwc-row fwc-row-bm'>
                            <div className='denta-plans-width'>
                                {errorInfoBar()}
                            </div>
                        </div>
                    )}
                    <div className='fwc-row fwc-row-bm'>
                        <div className='denta-plans-width'>
                            <div className={'cs-row'}>
                                {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                    const filterArr = medicalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                    return (
                                        filterArr?.length > 0 ?
                                            filterArr?.map((plan: RecComparedContract) => (
                                                plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat fwc-col-3'}>
                                                    <div className='pdf-wrapper'>
                                                        <p className={'pdf-card-paragraph'}>
                                                            <span className='sae-icon sae-icon-pdf icon-style' onClick={() => window.open(plan.pdfBrochureUrl, "_blank")}
                                                            ></span>
                                                            <p className='highlight-text' id='pdf-brocher' onClick={() => window.open(plan.pdfBrochureUrl, "_blank")}>{pageContent?.PDF_BROCHURE}</p>
                                                        </p>
                                                        <p>
                                                            <p className='highlight-text' id='pdf-brocher' onClick={() => onClickDetails(plan.contract, PLAN_TYPES_NAMES.MEDICAL)}>{pageContent?.DETAILS}</p>
                                                        </p>
                                                    </div>

                                                </div>
                                            ))
                                            : displayEmptyCard()
                                    )
                                })}

                                {placeholders()}
                            </div>

                            {
                                (medicalRecommendedPlans?.length) && medicalRecommendedPlans[0].contract && (medicalRecommendedPlans[0].contract as Contract).plan[0].planBenefits?.map((benefit: PlanBenefit) => (
                                    <>
                                        {
                                            MEDICAL_PLAN_BENEFITS_ARR.includes(benefit.planBenefitCode) && (
                                                <>
                                                    <div className={'cs-row-header'}>{pageContent?.MEDICAL_PLAN_BENEFIT_NAMES[benefit.planBenefitCode]}</div>
                                                    <div className={'cs-row'}>

                                                        {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                                            const filterArr = medicalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                                            return (
                                                                filterArr?.length > 0 ?
                                                                    filterArr?.map((plan: RecComparedContract) => (
                                                                        plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat fwc-col-3'}>
                                                                            <p className={'cs-card-paragraph'}>
                                                                                <span dangerouslySetInnerHTML={{ __html: findPlanBenefit(benefit.planBenefitCode, plan) }}></span>
                                                                            </p>
                                                                        </div>
                                                                    ))
                                                                    : displayEmptyCard()
                                                            )
                                                        })}
                                                        {placeholders()}
                                                    </div>
                                                </>
                                            )
                                        }
                                    </>
                                ))}

                            <div className={'cs-row-header'}>{pageContent?.DRUG_LIST}</div>
                            <div className={'cs-row'}>

                                {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                    const filterArr: RecComparedPlans = medicalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                    return (
                                        filterArr?.length > 0 ?
                                            filterArr?.map((plan: RecComparedContract) => (
                                                plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-col-3  your-medication'}>
                                                    <MatchingListCard
                                                        icon={'sae-icon-prescription-pill-bottle'}
                                                        label={pageContent?.PROPS_LABELS.MEDICATIONS_LIST_LABEL}
                                                        showMoreText={`${pageContent?.PLAN_CARD_LABELS.SHOW_MORE} ${pageContent?.PROPS_LABELS.MEDICATIONS}`}
                                                        showLessText={`${pageContent?.PLAN_CARD_LABELS.SHOW_LESS} ${pageContent?.PROPS_LABELS.MEDICATIONS}`}
                                                        data={getMedicationNames(plan?.matchedMedications ?? [])}
                                                        impNoticeText={''}
                                                        isCompare={true}
                                                    />
                                                </div>
                                            ))
                                            : <div key={'empty'} className={'cs-card fwc-col-3  your-medication'}>

                                            </div>
                                    )
                                })}
                                {placeholders()}
                            </div>
                            <div className={'cs-row-header'}>{pageContent?.TOTAL_MONTHLY_PREMIUM}</div>
                            <div className={'cs-row'}>
                                {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                    const filterArr = medicalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                    return (
                                        filterArr?.length > 0 ?
                                            filterArr?.map((plan: RecComparedContract) => (
                                                plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat fwc-col-3'}>
                                                    <div className={'cs-card-header'}>
                                                        {getFormattedCurrency(plan?.contract?.rateData?.rate?.totalPremium)}
                                                    </div>

                                                </div>
                                            ))
                                            : displayEmptyCard()
                                    )
                                })}
                                {placeholders()}
                            </div>

                            <div className={'cs-row-header'}>{pageContent?.TAX_CREDIT_APPLIED}</div>
                            <div className={'cs-row'}>
                                {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                    const filterArr = medicalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                    return (
                                        filterArr?.length > 0 ?
                                            filterArr?.map((plan: RecComparedContract) => (
                                                plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat fwc-col-3'}>
                                                    <div className={'cs-card-header'}>
                                                        {getFormattedCurrency(plan?.contract?.rateData?.rate?.subsidyAmtApplied)}

                                                    </div>

                                                </div>
                                            ))
                                            : displayEmptyCard()
                                    )
                                })}
                                {placeholders()}
                            </div>


                            <div className={'cs-row-header'}>{pageContent?.TOTAL_MEM_RESPONSIBILITY}</div>
                            <div className={'cs-row'}>
                                {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                    const filterArr = medicalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                    return (
                                        filterArr?.length > 0 ?
                                            filterArr?.map((plan: RecComparedContract) => (
                                                plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat fwc-col-3'}>
                                                    <div className={'cs-card-header'}>
                                                        {getFormattedCurrency(plan?.contract?.rateData?.rate?.totSubsidizedPrem)}

                                                    </div>

                                                </div>
                                            ))
                                            : displayEmptyCard()
                                    )
                                })}
                                {placeholders()}
                            </div>

                            <div className={'cs-row-header'}>{pageContent?.DOCTOR_LIST}</div>
                            <div className={'cs-row fwc-row-bm'}>
                                {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                    const filterArr = medicalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                    return (
                                        filterArr?.length > 0 ?
                                            filterArr?.map((plan: RecComparedContract) => (
                                                plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-col-3 your-doctor'} onClick={() => setUseCase(PLAN_TYPES_NAMES.MEDICAL)}>
                                                    <MatchingListCard
                                                        icon={'sae-icon-doctors-bag'}
                                                        label={pageContent?.PROPS_LABELS.DOCTORS_LIST_LABEL}
                                                        showMoreText={`${pageContent?.PLAN_CARD_LABELS.SHOW_MORE} ${pageContent?.PROPS_LABELS.DOCTORS}`}
                                                        showLessText={`${pageContent?.PLAN_CARD_LABELS.SHOW_LESS} ${pageContent?.PROPS_LABELS.DOCTORS}`}
                                                        providers={plan.matchedProviders}
                                                        tooltipMessage={''}
                                                        isCompare={true}
                                                    />
                                                </div>
                                            ))
                                            : <div key={'empty'} className={'cs-card fwc-col-3 your-doctor'}>

                                            </div>
                                    )
                                })}
                                {placeholders()}
                            </div>

                        </div>
                    </div>

                </div>}

                {
                    dentalRecommendedPlans?.length > 0 && <div id="dental-plans">
                        <div className='fwc-row fwc-row-bm'>
                            <h2>{pageContent?.DENTAL_PLANS}</h2>
                        </div>
                        <div className='fwc-row fwc-row-bm'>
                            <div className={'planRec-tray-content'}>

                                {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                    const filterArr = dentalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                    return (
                                        filterArr?.length > 0 ?
                                            filterArr?.map((plan: RecComparedContract) => (
                                                plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className="planRec-tray-plan fwc-col-3 fwc-card-generic">
                                                    <div className="planRec-tray-plan-header">
                                                        <div id="plan-card-grade" className="grade-container">
                                                            {renderGradetag(plan?.contract.gradeLevels, getiIsCurrentCoveragePlan(plan?.contract?.contractCode, PLAN_TYPES_NAMES.DENTAL))}
                                                        </div>
                                                        <div className={'market-tag ' + marketTagClass(plan.contract.onExchange, plan.contract.poPlan)}>{marketTagClass(plan.contract.onExchange, plan.contract.poPlan)}</div>
                                                    </div>
                                                    <div className={"planRec-tray-plan-title slider"}>
                                                        {plan?.contract.planMarketingName}
                                                    </div>
                                                    {!medicalRecommendedPlans?.length && !openEnrollmentStartedForPlanRec(zipCodeObj.currentDateTimeInZone ?? '') && isWindowShopping && windowShoppingError()}
                                                    {
                                                        shouldShowExceptionMessageForApplyNow(getProposalRes?.demographicInfo?.coverageEffectiveDate ?? '', pageContent?.CUTOFF_DATE_FOR_APPLY_NOW ?? '') ? <p>{pageContent?.EXCEPTION_MESSAGE_FOR_APPLY_NOW}</p> :

                                                            (openEnrollmentStartedForPlanRec(zipCodeObj.currentDateTimeInZone ?? '') || !isWindowShopping) && !medicalRecommendedPlans?.length && !isBeforeCoverage && !plan.productNotAvailable && <button className={'fwc-btn fwc-btn-primary fwc-col-12'} onClick={() => applyNow(plan.quoteId ?? '', checkPortFolio(plan.quoteId ?? ''))}>{pageContent?.APPLY_NOW}</button>
                                                    }
                                                    {!isWindowShopping && !medicalRecommendedPlans?.length && isBeforeCoverage && effectiveDateError()}
                                                    {!isWindowShopping && !medicalRecommendedPlans?.length && !isBeforeCoverage && plan.productNotAvailable && productNotAvailable()}
                                                </div>
                                            ))
                                            : <div key={'empty'} className="planRec-tray-plan fwc-col-3 fwc-card-generic">

                                            </div>
                                    )
                                })}
                                {placeholders()}
                            </div>
                        </div>
                        {!medicalRecommendedPlans?.length && applyNowError.hasError && !proposalLoading && (
                            <div className='fwc-row fwc-row-bm'>
                                <div className='denta-plans-width'>
                                    {errorInfoBar()}
                                </div>
                            </div>
                        )}
                        <div className='fwc-row fwc-row-bm'>
                            <div className='denta-plans-width'>
                                <div className={'cs-row'}>
                                    {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                        const filterArr = dentalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                        return (
                                            filterArr?.length > 0 ?
                                                filterArr?.map((plan: RecComparedContract) => (
                                                    plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat fwc-col-3'}>
                                                        <div className='pdf-wrapper'>
                                                            <p className={'pdf-card-paragraph'}>
                                                                <span className='sae-icon sae-icon-pdf icon-style' onClick={() => window.open(plan.pdfBrochureUrl, "_blank")}
                                                                ></span>
                                                                <p className='highlight-text' id='pdf-brocher' onClick={() => window.open(plan.pdfBrochureUrl, "_blank")}>{pageContent?.PDF_BROCHURE}</p>
                                                            </p>
                                                            <p>
                                                                <p className='highlight-text' id='pdf-brocher' onClick={() => onClickDetails(plan.contract, PLAN_TYPES_NAMES.DENTAL)}>{pageContent?.DETAILS}</p>
                                                            </p>
                                                        </div>

                                                    </div>
                                                ))
                                                : displayEmptyCard()
                                        )
                                    })}
                                    {placeholders()}
                                </div>
                                <div>

                                    {
                                        (!medicalRecommendedPlans?.length) && (dentalRecommendedPlans?.length) && dentalRecommendedPlans[0].contract && (dentalRecommendedPlans[0].contract as Contract).plan[0].planBenefits?.map((benefit: PlanBenefit) => (
                                            <>
                                                {
                                                    DENTAL_PLAN_BENEFITS_ARR.includes(benefit.planBenefitCode) && (
                                                        <>
                                                            <div className={'cs-row-header'}>{pageContent?.DENTAL_PLAN_BENEFIT_NAMES[benefit.planBenefitCode]}</div>
                                                            <div className={'cs-row'}>

                                                                {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                                                    const filterArr = dentalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                                                    return (
                                                                        filterArr?.length > 0 ?
                                                                            filterArr?.map((plan: RecComparedContract) => (
                                                                                plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat fwc-col-3'}>
                                                                                    <p className={'cs-card-paragraph'}>
                                                                                        <span dangerouslySetInnerHTML={{ __html: findPlanBenefit(benefit.planBenefitCode, plan) }}></span>
                                                                                    </p>
                                                                                </div>
                                                                            ))
                                                                            : displayEmptyCard()
                                                                    )
                                                                })}
                                                                {placeholders()}
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </>
                                        ))}
                                </div>
                                <div className={'cs-row-header'}>{pageContent?.TOTAL_MONTHLY_PREMIUM}</div>
                                <div className={'cs-row'}>
                                    {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                        const filterArr = dentalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                        return (
                                            filterArr?.length > 0 ?
                                                filterArr?.map((plan: RecComparedContract) => (
                                                    plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat fwc-col-3'}>
                                                        <div className={'cs-card-header'}>
                                                            {getFormattedCurrency(plan?.contract?.rateData?.rate?.totalPremium)}
                                                        </div>

                                                    </div>
                                                ))
                                                : displayEmptyCard()
                                        )
                                    })}

                                    {placeholders()}
                                </div>
                                {
                                    !medicalRecommendedPlans?.length && <div className={'cs-row-header'}>{pageContent?.TAX_CREDIT_APPLIED}</div>
                                }

                                {!medicalRecommendedPlans?.length && <div className={'cs-row'}>
                                    {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                        const filterArr = dentalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                        return (
                                            filterArr?.length > 0 ?
                                                filterArr?.map((plan: RecComparedContract) => (
                                                    plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat fwc-col-3'}>
                                                        <div className={'cs-card-header'}>
                                                            {getFormattedCurrency(plan?.contract?.rateData?.rate?.subsidyAmtApplied)}

                                                        </div>

                                                    </div>
                                                ))
                                                : displayEmptyCard()
                                        )
                                    })}

                                    {placeholders()}
                                </div>
                                }


                                {!medicalRecommendedPlans?.length && <div className={'cs-row-header'}>{pageContent?.TOTAL_MEM_RESPONSIBILITY}</div>}
                                {!medicalRecommendedPlans?.length && <div className={'cs-row'}>
                                    {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                        const filterArr = dentalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                        return (
                                            filterArr?.length > 0 ?
                                                filterArr?.map((plan: RecComparedContract) => (
                                                    plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat fwc-col-3'}>
                                                        <div className={'cs-card-header'}>
                                                            {getFormattedCurrency(plan?.contract?.rateData?.rate?.totSubsidizedPrem)}

                                                        </div>

                                                    </div>
                                                ))
                                                : displayEmptyCard()
                                        )
                                    })}
                                    {placeholders()}
                                </div>
                                }
                                <div className={'cs-row-header'}>{pageContent?.DENTIST_LIST}</div>
                                <div className={'cs-row fwc-row-bm'}>
                                    {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                        const filterArr = dentalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                        return (
                                            filterArr?.length > 0 ?
                                                filterArr?.map((plan: RecComparedContract) => (
                                                    plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-col-3 your-doctor'} onClick={() => setUseCase(PLAN_TYPES_NAMES.DENTAL)}>
                                                        <MatchingListCard
                                                            icon={'sae-icon-doctors-bag'}
                                                            label={pageContent?.PROPS_LABELS.DOCTORS_LIST_LABEL}
                                                            showMoreText={`${pageContent?.PLAN_CARD_LABELS.SHOW_MORE} ${pageContent?.PROPS_LABELS.DOCTORS}`}
                                                            showLessText={`${pageContent?.PLAN_CARD_LABELS.SHOW_LESS} ${pageContent?.PROPS_LABELS.DOCTORS}`}
                                                            providers={plan.matchedProviders}
                                                            tooltipMessage={''}
                                                            isCompare={true}
                                                        />
                                                    </div>
                                                ))
                                                : <div key={'empty'} className={'cs-card fwc-col-3 your-doctor'}>

                                                </div>
                                        )
                                    })}

                                    {placeholders()}
                                </div>

                            </div>
                        </div>

                    </div>
                }

                {
                    visionRecommendedPlans?.length > 0 && <div id="vision-plans">
                        <div className='fwc-row fwc-row-bm'>
                            <h2>{pageContent?.VISION_PLANS}</h2>
                        </div>
                        <div className='fwc-row fwc-row-bm'>
                            <div className={'planRec-tray-content'}>

                                {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                    const filterArr = visionRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                    return (
                                        filterArr?.length > 0 ?
                                            filterArr?.map((plan: RecComparedContract) => (
                                                plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className="planRec-tray-plan fwc-col-3 fwc-card-generic">
                                                    <div className="planRec-tray-plan-header">
                                                        <div id="plan-card-grade" className="grade-container">
                                                            {renderGradetag(plan?.contract.gradeLevels, getiIsCurrentCoveragePlan(plan?.contract?.contractCode, PLAN_TYPES_NAMES.VISION))}
                                                        </div>
                                                        <div className={'market-tag ' + marketTagClass(plan.contract.onExchange, plan.contract.poPlan)}>{marketTagClass(plan.contract.onExchange, plan.contract.poPlan)}</div>
                                                    </div>
                                                    <div className={"planRec-tray-plan-title slider"}>
                                                        {plan?.contract.planMarketingName}
                                                    </div>
                                                    {!medicalRecommendedPlans?.length && !dentalRecommendedPlans?.length && !openEnrollmentStartedForPlanRec(zipCodeObj.currentDateTimeInZone ?? '') && isWindowShopping && windowShoppingError()}
                                                    {
                                                        shouldShowExceptionMessageForApplyNow(getProposalRes?.demographicInfo?.coverageEffectiveDate ?? '', pageContent?.CUTOFF_DATE_FOR_APPLY_NOW ?? '') ? <p>{pageContent?.EXCEPTION_MESSAGE_FOR_APPLY_NOW}</p> :

                                                            (openEnrollmentStartedForPlanRec(zipCodeObj.currentDateTimeInZone ?? '') || !isWindowShopping) && !medicalRecommendedPlans?.length && !dentalRecommendedPlans?.length && !isBeforeCoverage && <button className={'fwc-btn fwc-btn-primary fwc-col-12'} onClick={() => applyNow(plan.quoteId ?? '', checkPortFolio(plan.quoteId ?? ''))}>{pageContent?.APPLY_NOW}</button>
                                                    }
                                                    {
                                                        !medicalRecommendedPlans?.length && !dentalRecommendedPlans?.length && !isWindowShopping && isBeforeCoverage && effectiveDateError()
                                                    }
                                                </div>
                                            ))
                                            : <div key={'empty'} className="planRec-tray-plan fwc-col-3 fwc-card-generic">

                                            </div>
                                    )
                                })}

                                {placeholders()}
                            </div>
                        </div>
                        {!medicalRecommendedPlans?.length && !dentalRecommendedPlans?.length && applyNowError.hasError && !proposalLoading && (
                            <div className='fwc-row fwc-row-bm'>
                                <div className='denta-plans-width'>
                                    {errorInfoBar()}
                                </div>
                            </div>
                        )}
                        <div className='fwc-row fwc-row-bm'>
                            <div className='denta-plans-width'>
                                <div className={'cs-row'}>
                                    {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                        const filterArr = visionRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                        return (
                                            filterArr?.length > 0 ?
                                                filterArr?.map((plan: RecComparedContract) => (
                                                    plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat fwc-col-3'}>
                                                        <div className='pdf-wrapper'>
                                                            <p className={'pdf-card-paragraph'}>
                                                                <span className='sae-icon sae-icon-pdf icon-style' onClick={() => window.open(plan.pdfBrochureUrl, "_blank")}
                                                                ></span>
                                                                <p className='highlight-text' id='pdf-brocher' onClick={() => window.open(plan.pdfBrochureUrl, "_blank")}>{pageContent?.PDF_BROCHURE}</p>
                                                            </p>
                                                            <p>
                                                                <p className='highlight-text' id='pdf-brocher' onClick={() => onClickDetails(plan.contract, PLAN_TYPES_NAMES.VISION)}>{pageContent?.DETAILS}</p>
                                                            </p>
                                                        </div>

                                                    </div>
                                                ))
                                                : displayEmptyCard()
                                        )
                                    })}

                                    {placeholders()}
                                </div>


                                {
                                    (!medicalRecommendedPlans?.length && !dentalRecommendedPlans?.length) && (visionRecommendedPlans?.length) && visionRecommendedPlans[0].contract && (visionRecommendedPlans[0].contract as Contract).plan[0].planBenefits?.map((benefit: PlanBenefit) => (
                                        <>
                                            {
                                                VISION_PLAN_BENEFITS_ARR.includes(benefit.planBenefitCode) && (
                                                    <>
                                                        <div className={'cs-row-header'}>{pageContent?.VISION_PLAN_BENEFIT_NAMES[benefit.planBenefitCode]}</div>
                                                        <div className={'cs-row'}>

                                                            {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                                                const filterArr = visionRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                                                return (
                                                                    filterArr?.length > 0 ?
                                                                        filterArr?.map((plan: RecComparedContract) => (
                                                                            plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat fwc-col-3'}>
                                                                                <p className={'cs-card-paragraph'}>
                                                                                    <span dangerouslySetInnerHTML={{ __html: findPlanBenefit(benefit.planBenefitCode, plan) }}></span>
                                                                                </p>
                                                                            </div>
                                                                        ))
                                                                        : displayEmptyCard()
                                                                )
                                                            })}
                                                            {placeholders()}
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </>
                                    ))}




                                <div className={'cs-row-header'}>{pageContent?.TOTAL_MONTHLY_PREMIUM}</div>
                                <div className={'cs-row'}>
                                    {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                        const filterArr = visionRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                        return (
                                            filterArr?.length > 0 ?
                                                filterArr?.map((plan: RecComparedContract) => (
                                                    plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat fwc-col-3'}>
                                                        <div className={'cs-card-header'}>
                                                            {getFormattedCurrency(plan?.contract?.rateData?.rate?.totalPremium)}
                                                        </div>

                                                    </div>
                                                ))
                                                : displayEmptyCard()
                                        )
                                    })}

                                    {placeholders()}
                                </div>

                                {!medicalRecommendedPlans?.length && !dentalRecommendedPlans?.length && <div className={'cs-row-header'}>{pageContent?.TAX_CREDIT_APPLIED}</div>}
                                {!medicalRecommendedPlans?.length && !dentalRecommendedPlans?.length && <div className={'cs-row'}>
                                    {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                        const filterArr = visionRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                        return (
                                            filterArr?.length > 0 ?
                                                filterArr?.map((plan: RecComparedContract) => (
                                                    plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat fwc-col-3'}>
                                                        <div className={'cs-card-header'}>
                                                            {getFormattedCurrency(plan?.contract?.rateData?.rate?.subsidyAmtApplied)}

                                                        </div>

                                                    </div>
                                                ))
                                                : displayEmptyCard()
                                        )
                                    })}

                                    {placeholders()}
                                </div>}


                                {!medicalRecommendedPlans?.length && !dentalRecommendedPlans?.length && <div className={'cs-row-header'}>{pageContent?.TOTAL_MEM_RESPONSIBILITY}</div>}
                                {!medicalRecommendedPlans?.length && !dentalRecommendedPlans?.length && <div className={'cs-row'}>
                                    {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                        const filterArr = visionRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                        return (
                                            filterArr?.length > 0 ?
                                                filterArr?.map((plan: RecComparedContract) => (
                                                    plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat fwc-col-3'}>
                                                        <div className={'cs-card-header'}>
                                                            {getFormattedCurrency(plan?.contract?.rateData?.rate?.totSubsidizedPrem)}

                                                        </div>

                                                    </div>
                                                ))
                                                : displayEmptyCard()
                                        )
                                    })}

                                    {placeholders()}
                                </div>
                                }

                                <div className={'cs-row-header'}>{pageContent?.EYE_DOCTOR_LIST}</div>
                                <div className={'cs-row fwc-row-bm'}>

                                    {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                        const filterArr = visionRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                        return (
                                            filterArr?.length > 0 ?
                                                filterArr?.map((plan: RecComparedContract) => (
                                                    plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-col-3 your-doctor'} onClick={() => setUseCase(PLAN_TYPES_NAMES.VISION)}>
                                                        <MatchingListCard
                                                            icon={'sae-icon-doctors-bag'}
                                                            label={pageContent?.PROPS_LABELS.DOCTORS_LIST_LABEL}
                                                            showMoreText={`${pageContent?.PLAN_CARD_LABELS.SHOW_MORE} ${pageContent?.PROPS_LABELS.DOCTORS}`}
                                                            showLessText={`${pageContent?.PLAN_CARD_LABELS.SHOW_LESS} ${pageContent?.PROPS_LABELS.DOCTORS}`}
                                                            providers={plan.matchedProviders}
                                                            tooltipMessage={''}
                                                            isCompare={true}
                                                        />
                                                    </div>
                                                ))
                                                : <div key={'empty'} className={'cs-card fwc-col-3 your-doctor'}>

                                                </div>
                                        )
                                    })}
                                    {placeholders()}
                                </div>

                            </div>
                        </div>

                    </div>
                }
                {
                    totalPremiumArray?.length > 0 && <div className='fwc-row fwc-row-bm'>
                        <div className='denta-plans-width'>
                            <div className={'cs-row-header'}>{pageContent?.TOTAL}</div>
                            <div className={'cs-row'}>
                                {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                    const filterArr = totalPremiumArray?.filter((plan: any) => plan.quoteId === ordering.quoteId);
                                    return (
                                        filterArr?.length > 0 ?
                                            filterArr?.map((plan: any) => (
                                                plan.quoteId === ordering.quoteId && <div key={plan.quoteId} className={'cs-card fwc-card-generic flat fwc-col-3'}>
                                                    <div className={'cs-card-header'}>
                                                        {getFormattedCurrency(plan.totalPremium)}
                                                    </div>

                                                </div>
                                            ))
                                            : displayEmptyCard()
                                    )
                                })}

                                {placeholders()}
                            </div>

                        </div>
                    </div>
                }
                {
                    quoteOrderings?.length > 0 && <div className='fwc-row fwc-row-bm'>
                        <div className='denta-plans-width'>
                            <div className={'cs-row-header'}>{pageContent?.DISCLAIMERS}</div>
                            <div className={'cs-row'}>
                                {quoteOrderings?.map((ordering: QuoteOrdering) => {

                                    return (
                                        <div key={ordering.quoteId} className={'cs-card fwc-card-generic flat fwc-col-3'}>
                                            <p className={'cs-card-paragraph'}>
                                                {pageContent?.DISCLAIMER_REC}
                                            </p>
                                        </div>
                                    )
                                })}

                                {placeholders()}
                            </div>

                        </div>
                    </div>
                }
                {
                    quoteOrderings?.length > 0 && <div className='fwc-row fwc-row-bm' id='disclaimer'>
                        <div className='denta-plans-width'>
                            <div className="fwc-row-bm">
                                <p>*{pageContent?.DISCLAIMER_SEC.DISCLAIMER}:</p>
                                <p>{pageContent?.DISCLAIMER_SEC.DISCLAIMER_1}</p>
                            </div>
                            <div className="fwc-row-bm">
                                <h3>{pageContent?.DISCLAIMER_SEC.IMPORTANT}</h3>
                                <p>{pageContent?.DISCLAIMER_SEC.DISCLAIMER_2}</p>
                            </div>
                            <div className="fwc-row-bm">
                                <span><b>{pageContent?.DISCLAIMER_SEC.DISCLAIMER_3}</b></span><span>{pageContent?.DISCLAIMER_SEC.DISCLAIMER_4}</span>
                                <span><b>{pageContent?.DISCLAIMER_SEC.DISCLAIMER_5}</b></span><span>{pageContent?.DISCLAIMER_SEC.DISCLAIMER_6}</span>
                                <span>{pageContent?.DISCLAIMER_SEC.DISCLAIMER_7}</span>
                            </div>
                        </div>
                    </div>
                }

                {useCase && providerMetaData && (
                    <ProviderDetails
                        useCase={useCase}
                    />
                )}

                {medicalContract && (
                    <MedicalPlanDetails
                        isCurrentCoveragePlan={false}
                        closeMedicalPlanDetails={() => setMedicalContract(null)}
                        contract={medicalContract}
                        showCloseButton={true}
                        currentDateTimeInZone={zipCodeObj.currentDateTimeInZone}
                        pdfQueryParmRec={getPDFQueryParm(SCREEN_NAMES.MEDICAL_PLAN_DETAILS)}
                    />
                )}
                {dentalContract && (
                    <DentalPlanDetails
                        isCurrentCoveragePlan={false}
                        closeDentalPlanDetails={() => setDentalContract(null)}
                        contract={dentalContract}
                        showCloseButton={true}
                        currentDateTimeInZone={zipCodeObj.currentDateTimeInZone}
                        pdfQueryParmRec={getPDFQueryParm(SCREEN_NAMES.DENTAL_DETAILS_SCREEN_NAME)}
                    />
                )}
                {visionContract && (
                    <VisionPlanDetails
                        isCurrentCoveragePlan={false}
                        closeVisionPlanDetails={() => setVisionContract(null)}
                        contract={visionContract}
                        showCloseButton={true}
                        currentDateTimeInZone={zipCodeObj.currentDateTimeInZone}
                        pdfQueryParmRec={getPDFQueryParm(SCREEN_NAMES.VISION_PLAN_DETAILS)}
                    />
                )}
            </div>}
            {
                showApplyModal && <Modal
                    onClose={closeApplyNowModal}
                    title={applyModalTitle}
                    open={showApplyModal}
                >
                    <Modal.Body>
                        <div className="apply-modal-padding"><span dangerouslySetInnerHTML={{ __html: applyModalDesc }}></span></div>
                    </Modal.Body>
                    <Modal.Footer>
                        <button id='modal-slide-select' className={'fwc-btn fwc-btn-primary'} onClick={closeApplyNowModal}>
                            {pageContent.CLOSE}
                        </button>
                    </Modal.Footer>
                </Modal>
            }
        </div>
    )
}
