import { Tooltip } from '@sydney-broker-ui/ios';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { getCMSdynamicContent } from '../../../api/cms/cmsUtil';
import { getMarketingIdValidated } from '../../../api/services/utilService';
import CustomRadioGroup from '../../../components/common/custom-radio/CustomRadio';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import { ALPHA_NUMERIC_REGX, DATE_FORMATS, LQE_NY_STATE, LQE_STATES, PAGE_BLOCKS, PAGE_IDS, PLAN_TYPES, PLAN_TYPES_CONTENT, PLAN_TYPES_NAMES, USER_ROLES } from '../../../shared/globalConstants';
import { arraysEqual, checkDateRangesInEnrollmentPeriods, getMemberPlanTypes, isNullOrUndefined } from '../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';

interface AgentHCIDCoveragesProps {
    pageContent: any;
    memberInfo: any;
    dependentsDisable: any;
}

const AgentHCIDCoverages: React.FC<AgentHCIDCoveragesProps> = ({ pageContent, memberInfo, dependentsDisable }) => {


    const { register, watch, setValue, getValues, setError, control, formState: { errors } } = useFormContext();

    const [coverageTypeOptions, setCoverageTypeOptions] = useState<any>([]);
    const [coverageTypeOptionsDisabled, setCoverageTypeOptionsDisabled] = useState<any>(pageContent.customRadiosQuestioner2.options);
    const [macTitle, setMacTitle] = useState('');
    const [coverageTypeContent, setCoverageTypeContent] = useState<any>('');
    const [marketingError, setMarketingError] = useState<boolean>(false);
    const [loading, setLoading] = useState(false);
    const [showCoveragesBloack, setShowCoveragesBloack] = useState<any>(false);



    /* --------- stores ------------*/

    const stores = {
        globalStore: useGlobalStore(),
        userStore: useUserStore(),
    };

    /* --------- stores ------------*/

    /*-------- Use Effects -----*/
    useEffect(() => {
        if (stores.globalStore.agentHCIDBlocks.coveragesBlock) {
            setShowCoveragesBloack(true);
        }
    }, [stores.globalStore.agentHCIDBlocks.coveragesBlock]);

    useEffect(() => {
        setLoading(true);
        if (memberInfo && memberInfo.currentCoverages) {
            const plantypes = getMemberPlanTypes(memberInfo.currentCoverages);
            let options = stores.userStore.isWellpoint ? pageContent?.ATK_WELLPOINT_OPTIONS?.options : pageContent?.customRadiosQuestioner2.options;
            updateMacCoverageTypes(options, pageContent?.memeberCoveragesOptions, plantypes, pageContent?.memeberCoveragesOptionsHeader);
        } else if (stores.globalStore.memberInfo && stores.globalStore.memberInfo.currentCoverages) {
            const plantypes = getMemberPlanTypes(stores.globalStore.memberInfo.currentCoverages);
            let options = stores.userStore.isWellpoint ? pageContent?.ATK_WELLPOINT_OPTIONS?.options : pageContent?.customRadiosQuestioner2.options
            updateMacCoverageTypes(options, pageContent?.memeberCoveragesOptions, plantypes, pageContent?.memeberCoveragesOptionsHeader);
        }
        setLoading(false);
    }, [memberInfo])

    useEffect(() => {
        if (stores.globalStore.agentCoverageType || stores.globalStore.agentYear) {
            setValue('coverageType', stores.globalStore.agentCoverageType);
            showLQESection();
        }
    }, [stores.globalStore.agentCoverageType, stores.globalStore.agentYear]);

    useEffect(() => {
        if (coverageTypeOptions.length > 0) {
            setValue('coverageType', stores.globalStore.agentCoverageType ? stores.globalStore.agentCoverageType : coverageTypeOptions[0].value);
            stores.globalStore.agentUpdateCoverageType(stores.globalStore.agentCoverageType ? stores.globalStore.agentCoverageType : coverageTypeOptions[0].value);
            stores.globalStore.agentUpdateHCIDBlocks('applicantBlock', true);
        }
    }, [coverageTypeOptions, stores.globalStore.agentZipcode]);

    /*--------- get Dynamic Content ------------*/
    const getdynamicContent = async (coverageType: string) => {
        let selectedCoverageType = PLAN_TYPES_CONTENT[coverageType]?.toLocaleLowerCase();
        let queryParam = ';state=' + stores.globalStore.agentZipCodeRes.stateCode + ';brand=' + stores.globalStore.agentBrand + ';coveragetype=' + selectedCoverageType + ';role=' + stores.userStore.role?.toLowerCase() + ';year=' + stores.globalStore.agentYear;
        if (stores.userStore.role === USER_ROLES.MEMBER) {
            queryParam = queryParam + ';existingplantype=' + stores.globalStore.agentMacPlanTypes.join('').toLowerCase();
        }
        await getCMSdynamicContent(PAGE_IDS.BASIC_LANDING, PAGE_BLOCKS.COVERAGE_TYPE, queryParam)
            .then((response) => {
                setCoverageTypeContent(response.data?.data.iosdynamiccontentList?.items[0]?.textsummary);
            })
            .catch((error) => {
                setCoverageTypeContent('');
            });
    };

    useEffect(() => {
        if (!isNullOrUndefined(stores.globalStore.agentCoverageType)) {
            getdynamicContent(stores.globalStore.agentCoverageType);
        }
    }, [stores.globalStore.agentCoverageType, stores.globalStore.agentZipcode, stores.globalStore.agentYear])

    /*--------- get Dynamic Content ------------*/


    /*-------- subscribe with slectors -----------*/
    let planTypesdestroyed = false;

    const unsub = useGlobalStore.subscribe(
        (currState) => currState.agentMacPlanTypes,
        (agentMacPlanTypes, prevState) => {
            if (agentMacPlanTypes.length > 0 && !arraysEqual(prevState, agentMacPlanTypes)) {
                setCoverageTypeOptions([]);
                updateMacCoverageTypes(pageContent?.customRadiosQuestioner2.options, pageContent?.memeberCoveragesOptions, agentMacPlanTypes, pageContent?.memeberCoveragesOptionsHeader);
                planTypesdestroyed = true;
            }
        },
        { fireImmediately: true }
    );

    if (planTypesdestroyed) {
        unsub();
    }
    /*-------- subscribe with slectors -----------*/


    /*------ show LQE based on Date config ----------*/
    const showLQESection = () => {
        if (stores.globalStore.agentYear === stores.globalStore.enrollmentYear && stores.globalStore.agentZipCodeRes.currentDateTimeInZone && stores.globalStore.agentState) {
            return checkDateRangesInEnrollmentPeriods(stores.globalStore.agentZipCodeRes.currentDateTimeInZone.toString(), ['WS', 'OE'], stores.globalStore.globalEnrollmentPeriods, stores.globalStore.agentState)
        }
        else if ((LQE_STATES.includes(stores.globalStore.agentState) && (stores.globalStore.agentCoverageType === PLAN_TYPES.MDV || stores.globalStore.agentCoverageType === PLAN_TYPES.MEDICAL)) || (LQE_NY_STATE === stores.globalStore.agentState && stores.globalStore.agentMacPlanTypes.some((element) => stores.globalStore.agentCoverageType === PLAN_TYPES[element])) || (LQE_NY_STATE === stores.globalStore.agentState && stores.globalStore.agentMacPlanTypes.length === 1 && stores.globalStore.agentMacPlanTypes[0] === PLAN_TYPES_CONTENT.V)) {
            return false;
        } else {
            return true
        }
    }

    /*------ show LQE based on Date config ----------*/

    /*---- mac Coverage types Method ----*/
    const updateMacCoverageTypes = (options, coveragesData, memplanTypes, headerOptions) => {
        let tempOptions = Object.assign([], options);
        if (coveragesData && memplanTypes.length > 0) {
            const selectedPlanOptions = coveragesData[memplanTypes.join("&").toLowerCase()];
            const index = tempOptions.findIndex((val) => val.id === (PLAN_TYPES_NAMES.MEDICAL).toLowerCase());
            if (!memplanTypes.includes(PLAN_TYPES_NAMES.MEDICAL) && index !== -1) {
                tempOptions.splice(index, 1);
            }
            tempOptions.forEach((option: any) => {
                option.label = selectedPlanOptions[(option.id).toLowerCase()];
            })
            setCoverageTypeOptions(tempOptions);
            setMacTitle(headerOptions[memplanTypes.join("&").toLowerCase()]);
        }
        setLoading(false);
    }
    /*---- mac Coverage types Method ----*/

    const handelChange = (event) => {
        stores.globalStore.agentUpdateCoverageType(event.target.value);
    }

    /*--------market id  -----------*/

    const martketIDWatch = watch('marketingRefId');

    useEffect(() => {
        if (martketIDWatch && (ALPHA_NUMERIC_REGX.test(martketIDWatch) && martketIDWatch.length === 4)) {
            validateMarketIDApi(martketIDWatch);
        } else {
            setMarketingError(false);
        }
    }, [martketIDWatch]);


    const validateMarketIDApi = async (value) => {
        const response = await getMarketingIdValidated(value);
        if (response?.data?.valid !== 'Y') {
            setMarketingError(true);
        } else {
            setMarketingError(false);
        }
    }

    const validateMarketingId = async (value) => {
        if (!(ALPHA_NUMERIC_REGX.test(value) && value.length === 4)) {
            return pageContent?.FORM_CONTENT.ERROR_MESSAGES.MARKETING_ID;
        }
    }

    if (loading) {
        return <FullPageLoader />
    } else {
        return (
            <div className='row-relative'>
                {!showCoveragesBloack && <div className='overlayCustom'></div>}
                <div className='fwc-row'>
                    <div className='fwc-col-12'>
                        <div className='form-group'>
                            {coverageTypeOptions.length > 0 && (
                                <>
                                    <Controller
                                        name="coverageType"
                                        control={control} // Set your desired default value
                                        rules={{ required: { value: true, message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD } }}
                                        render={({ field }) => (
                                            <CustomRadioGroup
                                                field={field}
                                                label={macTitle}
                                                name={pageContent.customRadiosQuestioner2.name}
                                                options={coverageTypeOptions}
                                                onCustomRadioSelect={handelChange} // Set your desired default value
                                                role={''}
                                            />
                                        )}
                                    />
                                    {coverageTypeContent && <div className="fwc-row content-coverage-selected-type">
                                        <div className="fwc-col-12">
                                            <div dangerouslySetInnerHTML={{ __html: coverageTypeContent }}></div>
                                        </div>
                                    </div>}
                                    <div className="fwc-row">
                                        <div className="fwc-col-12">
                                            {errors.coverageType?.message && (
                                                <span role="alert" className="fwc-inline-icon fwc-icon-delete" id="zip_error">
                                                    {String(errors.coverageType?.message)}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                            {coverageTypeOptions.length === 0 && (
                                <>
                                    <Controller
                                        name="coverageType"
                                        control={control} // Set your desired default value
                                        rules={{ required: { value: true, message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD } }}
                                        render={({ field }) => (
                                            <CustomRadioGroup
                                                field={field}
                                                label={macTitle}
                                                name={pageContent.customRadiosQuestioner2.name}
                                                options={coverageTypeOptionsDisabled}
                                                onCustomRadioSelect={handelChange} // Set your desired default value
                                                role={''}
                                            />
                                        )}
                                    />
                                    {coverageTypeContent && <div className="fwc-row content-coverage-selected-type">
                                        <div className="fwc-col-12">
                                            <div dangerouslySetInnerHTML={{ __html: coverageTypeContent }}></div>
                                        </div>
                                    </div>}
                                    <div className="fwc-row">
                                        <div className="fwc-col-12">
                                            {errors.coverageType?.message && (
                                                <span role="alert" className="fwc-inline-icon fwc-icon-delete" id="zip_error">
                                                    {String(errors.coverageType?.message)}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                            {!showLQESection() && <><div className="fwc-row">
                                <div className="fwc-col-12 lqe-section">
                                    {errors && errors.lqe && errors.lqe.type === "validate" && (
                                        <div className="member-banner form-group">
                                            <p role="alert" className="lqe"><span className="sae-icon sae-icon-high-importance"></span>
                                                {pageContent.FORM_CONTENT.ERROR_MESSAGES.VALIDATE_LQE}
                                            </p>
                                        </div>
                                    )}
                                    <p className="content">{pageContent.LQEText}</p>
                                    {pageContent && pageContent.LQE && pageContent.LQE[stores.globalStore.agentState] && pageContent.LQE[stores.globalStore.agentState][(stores.globalStore.agentCoverageType === PLAN_TYPES.MEDICAL || stores.globalStore.agentCoverageType === PLAN_TYPES.MDV) ? PLAN_TYPES_CONTENT.M : stores.globalStore.agentCoverageType] && pageContent.LQE[stores.globalStore.agentState][(stores.globalStore.agentCoverageType === PLAN_TYPES.MEDICAL || stores.globalStore.agentCoverageType === PLAN_TYPES.MDV) ? PLAN_TYPES_CONTENT.M : stores.globalStore.agentCoverageType].map((radio) => {
                                        return (
                                            <div className="fwc-radio">
                                                <input
                                                    {...register(`lqe`, {
                                                        required: true,
                                                        validate: (value) => value === 'CLQE',
                                                    })}
                                                    type="radio"
                                                    value={radio.key}
                                                    id={stores.globalStore.agentState + radio.key}
                                                />
                                                <label htmlFor={stores.globalStore.agentState + radio.key} dangerouslySetInnerHTML={{ __html: radio.value }}></label>
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                                <div className="fwc-row">
                                    <div className="fwc-col-12 fwc-input">
                                        {errors && errors.lqe && errors.lqe.type === "required" && (
                                            <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                {pageContent.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            </>}
                        </div>
                    </div>
                </div>
                {!stores.globalStore.isHPAUser && <div className='fwc-row'>
                    <div className='fwc-col-6'>
                        <div className='form-group more'>
                            <div className="fwc-input">
                                <label className="fwc-label" htmlFor="agentTin">
                                    {pageContent?.FORM_CONTENT.LABELS.AGENT_TIN}
                                </label>
                                <input
                                    id="agentTin"
                                    readOnly
                                    placeholder={pageContent?.FORM_CONTENT.PLACE_HOLDERS.AGENT_TIN}
                                    type="text"
                                    {...register('agentTin', {
                                        required: {
                                            value: true,
                                            message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD
                                        }
                                    })}
                                />
                            </div>
                        </div>
                    </div>
                    <div className='fwc-col-6'>
                        <div className='form-group more'>
                            <div className="fwc-input">
                                <div className='fwc-flex'>
                                    <label className="fwc-label" htmlFor="marketingRefId">
                                        {pageContent?.FORM_CONTENT.LABELS.MARKETING_REFERENCE}
                                    </label>
                                    <Tooltip icon message={pageContent?.FORM_CONTENT.TOOL_TIPS.MARKETING_ID} />
                                </div>
                                <input
                                    id="marketingRefId"
                                    readOnly={!showCoveragesBloack}
                                    placeholder={pageContent?.FORM_CONTENT.PLACE_HOLDERS.MARKETING_REFERENCE}
                                    type="text"
                                    {...register('marketingRefId', {
                                        validate: (value) => value === '' || validateMarketingId(value)
                                    })}
                                />
                                {errors && errors.marketingRefId && errors.marketingRefId?.type === "validate" && (
                                    <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                        {String(errors.marketingRefId?.message)}
                                    </span>
                                )}
                                {marketingError && (
                                    <div className="banner">
                                        <div className="fwc-row">
                                            <div className="fwc-col-12">
                                                <p><span className="sae-icon sae-icon-high-importance"></span>
                                                    {pageContent?.FORM_CONTENT.ERROR_MESSAGES.MARKETING_ID_NOT_Valid}</p>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>}
                {stores.globalStore.agentCoverageType && stores.globalStore.agentCoverageDate && <div className='fwc-row'>
                    <div className='fwc-col-12'>
                        <div className="update-zipcode-block">
                            <div className="autopopulatefiedls">
                                <p id="coverage-date"><span className="sae-icon sae-icon-checked"></span>{pageContent?.FORM_CONTENT.LABELS.COVERAGE_DATE}<span>{dayjs(stores.globalStore.agentCoverageDate).format(DATE_FORMATS.MMDDYYYY)}</span></p>
                            </div>
                        </div>
                    </div>
                </div>}
            </div>
        );
    }
};

export default AgentHCIDCoverages;