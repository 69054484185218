import { Button, Modal, PageHeader, PreLoader, Row, Title } from '@sydney-broker-ui/ios';
import { AxiosError, isAxiosError } from 'axios';
import React, { useCallback, useEffect, useState } from 'react';
import { getCMSStaticContent } from '../../../api/cms/cmsUtil';
import { HTTP_STATUS_CODES } from '../../../api/constants';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import GlobalFooter from '../../../components/common/global-footer/GlobalFooter';
import GlobalHeader from '../../../components/common/global-header/GlobalHeader';
import InfoBar from '../../../components/common/info-bar/InfoBar';
import SuccessModalBody from '../../../components/common/success-modal-body/SuccessModalBody';
import { ERROR_ALERT_CONFIG, LANGUAGE_CODES, SCREEN_NAMES } from '../../../shared/globalConstants';
import { useUserStore } from '../../../store/userStore';
import { PASSWORD_VALIDATION_CHECK } from '../registration/password-creation/constants';
import { hasPasswordContainsUsername } from '../registration/password-creation/passwordCreationUtils';
import { getChangePasswordPayload } from '../two-fa-modal/TwoFAModalUtils';
import { CHANGE_PASSWORD_RESPONSE_CODE } from '../two-fa-modal/constants';
import { changePassword } from '../two-fa-modal/twoFAServices';
import './AccountRecovery.scss';
import { default as Content } from './accountRecovery.json';

function HardResetPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [passwordRules, setPasswordRules] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [showTwoFAModal, setShowTwoFAModal] = useState(false);
  const [serviceError, setServiceError] = useState({
    hasError: false,
    responseCode: '',
    message: ''
  });
  const [pageContent, setPageContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const getContent = useCallback(async (): Promise<boolean> => {
    let content: any;
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.ACCOUNT_RECOVERY);
      let cmsResponse = response.data;
      content = cmsResponse.data.iospagecontentList.items[0].pageContent?.AccountRecovery;
    } catch (error) {
      content = Content?.data.iospagecontentList.items[0].pageContent?.AccountRecovery;

      return false;
    }
    finally {
      setPasswordRules(PASSWORD_VALIDATION_CHECK(content));
      setPageContent(content);
      setContentLoaded(true);
    }
    return true;
  }, []);

  useEffect(() => {
    initiate();
  }, []);

  const initiate = async () => {
    await getContent();
  };

  const { resetId, userName } = useUserStore((state) => state);

  const getNewPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setPasswordError('');
    setNewPassword(e.target.value);

    const updatedPasswordRules = passwordRules.map((item) => {
      if (item.id !== 'rule4') {
        const updatedItem = Object.assign({}, item);
        updatedItem.regex.test(e.target.value.toString()) ? (updatedItem.isPassed = true) : (updatedItem.isPassed = false);
        return updatedItem;
      } else if (item.id === 'rule4') {
        const updatedItem = Object.assign({}, item);
        if (hasPasswordContainsUsername(userName, e.target.value.toString())) {
          updatedItem.isPassed = false;
        } else {
          updatedItem.isPassed = true;
        }
        return updatedItem;
      } else {
        return item;
      }
    });

    e.target.value.length > 0 ? setPasswordRules(updatedPasswordRules) : setPasswordRules(PASSWORD_VALIDATION_CHECK(pageContent));
  };
  const getConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setConfirmPasswordError('');
    setConfirmNewPassword(e.target.value);
  };
  const isValidPassword = () => {
    if (!newPassword) {
      setPasswordError(pageContent.REQUIRED_FIELD);
    } else {
      setPasswordError('');
    }
  };

  const isValidConfirmPassword = () => {
    if (confirmNewPassword === newPassword) {
      setConfirmPasswordError('');
      return true;
    } else {
      setConfirmPasswordError(pageContent.CONFIRM_PASSWORD_MATCH);
      return false;
    }
  };

  const initiateSavePassword = () => {
    if (!newPassword) {
      setPasswordError(pageContent.REQUIRED_FIELD);
    }
    if (!confirmNewPassword) {
      setConfirmPasswordError(pageContent.REQUIRED_FIELD);
    }
    const isPassedResultsPwd = passwordRules.map((item) => item.isPassed);
    if (!isPassedResultsPwd.includes(false) && isValidConfirmPassword()) {
      initiateChangePassword();
    }
  };

  const initiateChangePassword = () => {
    setLoading(true);
    setServiceError({
      hasError: false,
      responseCode: '',
      message: ''
    });
    const payload: ChangePasswordPayload = getChangePasswordPayload(resetId, newPassword);

    changePassword(payload)
      .then((response) => {
        const data: ChangePasswordResponse = response.data;
        if (data.responseMessage.responseCode === CHANGE_PASSWORD_RESPONSE_CODE.PASSWORD_CHANGED_SUCCESSFULLY) {
          window._satellite.track("successUpdatePassForgotPassIos");
          setLoading(false);
          setShowTwoFAModal(true);
        }
      })
      .catch((error: AxiosError<ChangePasswordError>) => {
        if (isAxiosError(error)) {
          const errorObj = error.response?.data?.error;

          setServiceError({
            hasError: true,
            responseCode: errorObj?.errorKey ? errorObj?.errorKey : '',
            message: pageContent.PASSWORD_CHANGE_FAILED
          });
        } else {
          setServiceError({
            hasError: true,
            responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
            message: pageContent.SORRY_SOMETHING_WENT_WRONG
          });
        }
        setLoading(false);
      });
  };


  const handleTwoFAModalClose = () => {
    setShowTwoFAModal(false);
  };

  return (!contentLoaded ? <FullPageLoader /> :
    <div>
      <GlobalHeader />
      <div className={'header-container'}>
        <PageHeader>
          <Row alignItems="center">
            <div>
              <div className="fwc-row">
                <div className="fwc-col-12">
                  <Title>RESET PASSWORD</Title>
                </div>
              </div>

            </div>
          </Row>
        </PageHeader>
      </div>
      <div className="newpassword-form-wrapper">
        <div className="confirmation-heading-wrapper">
          <p>{pageContent.PROVIDE_NEW_PASSWORD}</p>
        </div>
        <div className="newpassword-form">
          <label className="fwc-label user-name-label">
            {pageContent.USERNAME_LABEL} <span className="user-name">{userName}</span>
          </label>
          {loading ? (
            <PreLoader id={'two-fa-modal-loader'} />
          ) : (
            <div>
              <div className="fwc-row-bm newpassword-field">
                <div className="fwc-input">
                  <label className="fwc-label" htmlFor='newpassword-input'>{pageContent.NEW_PASSWORD}</label>
                  <div className="profile-password-field-wrapper">
                    <input
                      id={'newpassword-input'}
                      name={'newpassword'}
                      type={passwordVisible ? 'text' : 'password'}
                      value={newPassword}
                      onChange={(e) => getNewPassword(e)}
                      onBlur={() => isValidPassword()}
                    />
                    <span
                      id="show-update-password-icon"
                      onClick={() => setPasswordVisible(!passwordVisible)}
                      className={passwordVisible ? 'sae-icon sae-icon-visualy-impaired password-icon' : 'sae-icon sae-icon-eye password-icon'}
                    />
                  </div>
                  {passwordError && (
                    <span id="login-modal-username-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                      {passwordError}
                    </span>
                  )}
                </div>
              </div>

              <div className="fwc-col-12 fwc-row-bm">
                {passwordRules.map((item) => {
                  return (
                    <div className="fwc-col-6 fwc-radio rule-checkbox-container">
                      <span
                        className={
                          item.isPassed ? 'sae-icon sae-icon-circle-checked-inverse right-xxs rule-checkbox' : 'sae-icon sae-icon-circle right-xxs rule-checkbox'
                        }
                      ></span>
                      <label className="rule-validation-text">{item.label}</label>
                    </div>
                  );
                })}
              </div>

              <div className="fwc-row-bm newpassword-field">
                <div className="fwc-input">
                  <label className="fwc-label" htmlFor='confirm-newpassword-input'>{pageContent.CONFIRM_NEW_PASSWORD}</label>
                  <input
                    id={'confirm-newpassword-input'}
                    name={'confirmpassword'}
                    type="password"
                    value={confirmNewPassword}
                    onChange={(e) => getConfirmPassword(e)}
                    onBlur={() => isValidConfirmPassword()}
                  />
                  {confirmPasswordError && (
                    <span id="login-modal-username-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                      {confirmPasswordError}
                    </span>
                  )}
                </div>
              </div>

              {serviceError.hasError && !loading && (
                <InfoBar
                  area={ERROR_ALERT_CONFIG.AREA}
                  labelAriaCloseBtn={ERROR_ALERT_CONFIG.TYPE}
                  type={ERROR_ALERT_CONFIG.ERROR}
                  handleClose={() => {
                    setServiceError({
                      hasError: false,
                      responseCode: '',
                      message: ''
                    });
                  }}
                >
                  <p className="message">{serviceError.message}</p>
                </InfoBar>
              )}

              <div className="save-password-button-wrapper">
                <Button data-analytics="saveBtnUpdatePassForgotPassIos" id="save-password" onClick={() => initiateSavePassword()}>
                  {pageContent.SAVE_PASSWORD}
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal open={showTwoFAModal} onClose={handleTwoFAModalClose} title="Password Reset">
        <Modal.Body>
          <SuccessModalBody
            heading="Password Reset"
            description="Your password has been successfully reset. You can close the window now and login again with new password"
            closeButtonText={pageContent.SUCCESS_MODAL_CLOSE_BUTTON}
            closeModal={handleTwoFAModalClose}
            analyticsSuffix={'ResetPassIos'}
          />
        </Modal.Body>
      </Modal>
      <GlobalFooter />
    </div>
  );
};

export default HardResetPassword;
